import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import WriteAReview from '../../Common/Layout/ProductDetailPage/WriteAReview'
import { ForLanding } from '../../Containers/LandingPage/Hooks'
import { useParams } from 'react-router'
import { ForAdmin } from '../../Containers/Admin/Hooks'

const OfferDetailsAndReview = () => {

  const { getNewLandingOffers, getPostedReviews,
  } = ForLanding()

  const {
    getServiceByBusinessInAdmin,
    handleServices
  } = ForAdmin();
  const BusinessDetails = useSelector((state) => state.landingData.businessDetails)
  const userId = useParams()

  useEffect(() => {
    window.scrollTo(0, 0);
    getNewLandingOffers(userId?.id)
  }, [])

  useEffect(() => {
    getServiceByBusinessInAdmin(BusinessDetails?.id);
  }, [BusinessDetails?.id])

  const serviceData = useSelector((state) => state?.totalVendorsInAdmin?.services)

  useEffect(() => {
    getPostedReviews(BusinessDetails?.id)
  }, [BusinessDetails?.id])

  return (
    <div>
      <WriteAReview BusinessDetails={BusinessDetails} goToALlOffers={true} serviceData={serviceData} handleServices={handleServices}
      reviewDate={true} />
      </div>
  )
}

export default OfferDetailsAndReview