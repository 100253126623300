import { useState } from 'react';
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import BannerSection from './BannerSection'
import BrowsNearbyPlace from './BrowsNearbyPlace'
import RechargeandPaybills from './RechargeandPaybills'
import NewOffer from './NewOffer'
import VendorNearYou from './VendorNearYou'
// import { ForAdmin } from '../../Containers/Admin/Hooks'
// import { useSelector } from 'react-redux'
// import { useEffect } from 'react'
// import WriteAReview from '../../Common/Layout/ProductDetailPage/WriteAReview'
// import { useNavigate } from 'react-router-dom'
// import { ForNotification } from '../../Containers/Notification/Hooks'
// import NotificationShow from '../Notification/NotificationShow'
import ServiceOffer from './ServiceOffer'
import { useLoadScript } from '@react-google-maps/api'
import ExpirationModal from '../../Common/UiComponents/ExpirationModal/ExpirationModal'

const Home = (props) => {
  const [enable_expiration, setEnableExpiration] = useState(false)
  const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  if (!isLoaded) return <div></div>;

  const handleCloseExpirationModel = () => {
    setEnableExpiration(false)
  }

  // const allLinks = useSelector((state) => state.setting.hyperlinks)
  return (
    <div>
      <div className='sticky top-0 z-50'>
        <MainHeader handleContactUs={props?.handleContactUs} />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          handleclickNotification={props.handleshow}
          border={true}
          profile={true}
          showNotification={props.showNotification}
          allnotification={props.allnotification}
          detailsPage={props.detailsPage}
          readNotificationApi={props.readNotificationApi}
        />
      </div>
      {/* <div className={`h-screen`}>
        <CommonCard
          cardmappingname={carddetails}
        />
      </div> */}
      <BannerSection scrollClickHandler={props.scrollClickHandler} getVendorsUnderSubCategory={props.getVendorsUnderSubCategory} />
      <RechargeandPaybills handleClickForAllHyperlink={props.handleClickForAllHyperlink} />
      <NewOffer handleClickForOfferSeeAll={props.handleClickForOfferSeeAll} onHandelOpeModal={props.onHandelOpeModal} />
      <ServiceOffer handleAllServiceOffer={props.handleAllServiceOffer} onHandelOpeModal={props.onHandelOpeModal} />
      <VendorNearYou isLandingPage={true} handleClickForBusinessSeeAll={props.handleClickForBusinessSeeAll} onHandelOpeModal={props.onHandelOpeModal} />
      <BrowsNearbyPlace
        handleSearchResult={props.handleSearchResult}
        openMap={props.openMap}
        lat={props.lat}
        lng={props.lng}
        handleSearchData={props.handleSearchData}
        searchData={props.searchData}
        searchResult={props.searchResult}
        markers={props.markers}
        handleSetMarkers={props.handleSetMarkers}
        selected={props.selected}
        handleSetSelected={props.handleSetSelected}
      />
      {/* {
        props.openMap &&
        <RenderGoogleMap
          lat={props.lat}
          lng={props.lng}
        />} */}
      <Footer />
      {enable_expiration && <ExpirationModal closeModal={handleCloseExpirationModel} />}

    </div >
  )
}

export default Home