import React, { useEffect, useState } from 'react'
import DigidialsLandingLogo from '../../../Images/CommonImages/exploreFooterLogo.svg'
import message from '../../../Images/CommonImages/message.svg'
import notifiCationBellIcon from '../../../Images/CommonImages/notifiCationBellIcon.svg'
import accountDefault from '../../../Images/CommonImages/accountDefult.svg'
import dropArrow from '../../../Images/CommonImages/dropArrow.svg'
import ButtonField from '../../FormFields/ButtonField'
import { ClickAwayListener } from '@mui/base'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import SearchField from '../../FormFields/SearchField'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import ProfileIcon from '../../../Images/profileUserCardImages/vendorProfileIcon.svg'
import Logout from '../../../Images/profileUserCardImages/Logout.svg'
import Subscription from '../../../Images/CommonImages/Subscription.svg'
import Requirements from '../../../Images/CommonImages/Requirement.svg'
import Favourite from '../../../Images/CommonImages/Favourite.svg'
import SmallSettings from '../../../Images/CommonImages/SmallSettings.svg'
import NotificationShow from '../../../Components/Notification/NotificationShow'
import { useDispatch, useSelector } from 'react-redux'
import headerStyles from './LandingHeader.module.css'
import { Search } from '@mui/icons-material'
import hamburgerIcon from '../../../Images/CommonImages/hamburger.png'
import messagesIcon from '../../../Images/profileDashboardIcons/message.svg'
import loginIcon from '../../../Images/CommonImages/login.svg'
import signUpIcon from '../../../Images/CommonImages/signUp.svg'
import HamList from '../../UiComponents/HamList'
import { getAllNotification } from '../../../Containers/Notification/NotificationReducer'
import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks'
import { setUploadMultipleImg, setUploadedData, setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import faqLogo from '../../../Images/CommonImages/faqLogo.svg'
import about from '../../../Images/CommonImages/about.svg'
import privPolLogo from '../../../Images/CommonImages/privacyPolicy.svg'
import termsAndConditionLogo from '../../../Images/CommonImages/terms&conditions.svg'
import { getSubscriptionDetails } from "../../../Containers/Authentication/Login/reducer/subscriptionReducer"
import ConfirmationModal from '../../Popups/ConfirmationModal'


const LandingHeader = (props) => {

    const { t, i18n } = useTranslation()

    const { singleVendorDetailsData } = ForVendor()

    const navigate = useNavigate()
    const routeLocation = useLocation()
    const dispatch = useDispatch()

    const [openDetails, setOpenDetails] = useState(false)
    const [showSearch, setShowSearch] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
   
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')
    const businessId = localStorage.getItem('businessId')

    const openProfileDetails = () => {
        setOpenDetails(!openDetails)
    }

    //function to handleSearch Icon in responsiveness
    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    }

    const handleLogout = () => {
        setModalOpen(true)
    }

    // Header when Vendors login 
    const logout = () => {
        if (token) {
            localStorage.clear()
            // localStorage.removeItem('token')
            // localStorage.removeItem('name')
            // localStorage.removeItem('email')
            // localStorage.removeItem('userId')
            // localStorage.removeItem('role')
            // localStorage.removeItem('businessId')
            // localStorage.removeItem('businessType')
            // localStorage.removeItem('sections')
            dispatch(setUploadedData({}))
            dispatch(setUploadedLogo({}))
            dispatch(setUploadMultipleImg([]))
            dispatch(getSubscriptionDetails({}))
            setModalOpen(false)
            navigate('/')

        }
    }


    const allNotifications = useSelector((state) => state.notificationData.allNotification)
    const activeNotifications = allNotifications?.filter(notification => notification.readStatus === false)

    const vendorProfileArr = ((singleVendorDetailsData && Object?.keys(singleVendorDetailsData)?.length > 0) || businessId) ? [
        { name: 'yourAccount', logo: ProfileIcon, link: '/vendor', },
        { name: 'subscription', logo: Subscription, link: '/vendor/subscription', },
        { name: 'Favourite', logo: Favourite, link: `/favouritevendors` },
        { name: 'yourSettings', logo: SmallSettings, link: '/vendorsettings' },
        { name: 'Faq', logo: faqLogo, link: '/faq' },
        { name: 'About Us', logo: about, link: '/aboutus' },
        { name: 'Privacy Policy', logo: privPolLogo, link: '/privacypolicy' },
        { name: 'Terms & Conditions', logo: termsAndConditionLogo, link: '/termsandconditions' },
        { name: 'Post Requirements', logo: Requirements, link: '/vendor/myrequirements' },
    ] : [
        { name: 'yourAccount', logo: ProfileIcon, link: '/vendor', },
    ]
    const userProfileArr = [
        { name: 'yourAccount', logo: ProfileIcon, link: '/user', },
        { name: 'message', logo: messagesIcon, link: '/user/message', },
        { name: 'Faq', logo: faqLogo, link: '/faq' },
        { name: 'About Us', logo: about, link: '/aboutus' },
        { name: 'Privacy Policy', logo: privPolLogo, link: '/privacypolicy' },
        { name: 'Terms & Conditions', logo: termsAndConditionLogo, link: '/termsandconditions' },
        // { name: 'yourSettings', logo: SmallSettings, link: '/vendorsettings' },
        // { name: 'yourBids', logo: Requirements, link: '/', },
        // { name: 'DashBoard', logo: Requirements, openPostReq: props.handleRenderReqForm, link: '/vendor' },
    ]
    const activeStyle = {
        backgroundColor: "#E8EFF5",
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
        margin: '0px',
        padding: '0px',
        paddingRight: '0px',
        color: '#585F71',
        borderRadius: '5px 5px 0px 0px',
    };
    const style = {
        color: '#585F71',
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
    }

    const hamListArr = ((token && role === 'vendor') && ((singleVendorDetailsData && Object?.keys(singleVendorDetailsData)?.length > 0) || businessId)) ? [
        { name: 'Your Account', imgSrc: ProfileIcon, clickHandler: () => { navigate('/vendor') } },
        { name: 'Subscription', imgSrc: Subscription, clickHandler: () => { navigate('/') } },
        { name: 'Favourite', imgSrc: Favourite, clickHandler: () => { navigate('/favouritevendors') } },
        { name: 'Your Settings', imgSrc: SmallSettings, clickHandler: () => { navigate('/vendorsettings') } },
        // { name: 'DashBoard', imgSrc: Requirements, link: '/vendor', clickHandler: () => { navigate('/vendor') } },
        // { name: 'Messages', imgSrc: messagesIcon, clickHandler: () => { navigate('/vendor/message') } },
        { name: 'Log Out', imgSrc: Logout, clickHandler: () => logout() }
    ] : (token && role === 'user') ? [
        {
            name: 'Your Account', imgSrc: ProfileIcon, clickHandler: () => { navigate('/user') },
        },
        {
            name: 'message', imgSrc: messagesIcon, clickHandler: () => { navigate('/user/message') }
        },
        { name: 'Log Out', imgSrc: Logout, clickHandler: () => logout() }
    ]
        : (!token && !role) ?
            [
                {
                    name: 'Sign Up', imgSrc: signUpIcon, clickHandler: () => {
                        localStorage.setItem('userLogin', 'userLogin');
                        navigate('/usersignup')
                    }
                },
                {
                    name: 'Log In', imgSrc: loginIcon, clickHandler: () => {
                        navigate('/login');
                    }
                }
            ]
            : [
                { name: 'Log Out', imgSrc: Logout, clickHandler: () => logout() }
            ]
    return (
        <div className={`relative`}>
            <div className={`bg-primeryColour-4 h-[5.625rem] w-full flex justify-between items-center px-[5%] ${props.landingRoot}`}>
                <div onClick={() => navigate('/')} className={`${headerStyles.headerSpace} cursor-pointer`}>
                    <img src={DigidialsLandingLogo} alt={t("explore")} className={`w-[8em]`} />
                </div>
                <div className={`${headerStyles.searchField}`}>
                    {
                        props.searchBar && (role !== 'admin') &&
                        <SearchField
                            placeholderVendor="Search Services & Vendors"
                            placeholderLocation="Location"
                            dropDown={true}
                            location={false}
                        />
                    }
                </div>
                <div className={`flex items-center`}>
                    <div className={`flex w-full gap-[1.8rem] justify-end relative`}>

                        <div className={`${headerStyles.notificationResponse}`}>
                            {/* this notification section is for smaller screen size */}
                            {props.goToNotification && token && role && <img src={notifiCationBellIcon} alt='notifiCationBellInActive' onClick={props.handleclickNotification} className={`cursor-pointer relative`} />}
                            {activeNotifications?.length > 0 && token && role &&
                                <div className='bg-red-5 rounded absolute bottom-[2rem] left-[3.75rem] z-10'>
                                    <p className='bg-red-5 rounded-[100%] absolute top-1 text-white text-xs py-0 px-1'>{activeNotifications?.length}</p>
                                </div>}
                        </div>
                        <div className={`${headerStyles.rightHeader}`}>

                            {props.goTomessage && token && role && <img src={message} alt='message' onClick={() => { token && (role === 'user' ? navigate('/user/message') : role === 'vendor' && navigate('/vendor/message')) }} className={`cursor-pointer`} />}

                            {/* Notification */}
                            {props.goToNotification && token && role && <img src={notifiCationBellIcon} alt='notifiCationBellInActive' onClick={props.handleclickNotification} className={`cursor-pointer relative`} />}

                            {activeNotifications?.length > 0 && token && role &&
                                <div className='bg-red-5 rounded absolute bottom-10 left-[3.35rem]'>
                                    <p className='bg-red-5 rounded-[100%] absolute top-1 text-white text-xs py-0 px-1'>{activeNotifications?.length}</p>
                                </div>}
                            {props.border && token && role && <div className={`border-l border-solid border-primeryColour-7 h-10`} />}
                            {props.profile && <div className={`relative pr-[2em]`}>
                                <div className={`flex items-center justify-between w-24 cursor-pointer `} onClick={() => openProfileDetails()}>
                                    <img src={accountDefault} alt='accountDefault' />
                                    <p className={`font-medium text-white text-xs leading-5 font-[Roboto-Medium] tracking-[0.15px]`}>{t("profile")}</p>
                                    <img src={dropArrow} alt='dropArrow' className={openDetails === true ? `rotate-180` : null} />
                                </div>
                                {openDetails === true ?
                                    <ClickAwayListener onClickAway={() => setOpenDetails(false)}>
                                        <div className={`bg-white shadow-md w-fit rounded-b absolute -right-6 top-14 py-[1%] z-50 ${(token && role === 'vendor') ? '!p-0' : 'px-[15%]'} `}>

                                            {/* If the role of the person who is logged in is Vendor  */}
                                            {(token && role === 'vendor') ?
                                                // <div className={`max-h-[20rem] overflow-hidden hover:overflow-y-auto`}>
                                                <div className={``}>

                                                    {vendorProfileArr?.map((vendor, idx) =>
                                                        <div
                                                            key={idx}
                                                            onClick={() => setOpenDetails(false)}
                                                        >
                                                            <List className={`navItems `} sx={{ marginBottom: "0.2rem", marginTop: "0.2rem", paddingTop: 0, paddingBottom: "2px" }} >
                                                                <ListItem disablePadding >
                                                                    <Link className={`w-full`} to={vendor?.link}
                                                                        style={
                                                                            routeLocation?.pathname === vendor?.link ? activeStyle : style
                                                                        }

                                                                    >
                                                                        <ListItemButton className='py-3'>
                                                                            <ListItemIcon><img src={vendor?.logo} alt="vendorLogo" /></ListItemIcon>
                                                                            <div><p>{t(vendor?.name)}</p></div>
                                                                        </ListItemButton>
                                                                    </Link>

                                                                </ListItem>
                                                            </List>
                                                        </div>)
                                                    }
                                                    <div onClick={() => handleLogout()} className={`flex items-center gap-5 mb-3 ${i18n.dir() === 'rtl' ? 'mr-[22.5%]' : 'ml-[24%]'} pb-[5%] cursor-pointer`}>
                                                        <img src={Logout} alt="Logout" />
                                                        <p className={`text-deepgrey-3 font-[Roboto-Regular] text-sm`}>{t("Logout")}</p>
                                                    </div>

                                                </div>
                                                :
                                                // if the logged in person is user 
                                                (token && role === 'user') ? <div>
                                                    {userProfileArr.map((user, idx) =>
                                                        <div key={idx}
                                                        // onClick={vendor?.openPostReq}
                                                        >
                                                            <List className={`navItems `} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }} >
                                                                <ListItem disablePadding >
                                                                    <NavLink className={`w-full`} to={user?.link}
                                                                        style={
                                                                            ({ isActive }) => isActive ? activeStyle : style
                                                                        }

                                                                    >
                                                                        <ListItemButton className='py-4'>
                                                                            <ListItemIcon><img src={user?.logo} alt="userImage" /></ListItemIcon>
                                                                            <div><p>{t(user?.name)}</p></div>
                                                                        </ListItemButton>
                                                                    </NavLink>

                                                                </ListItem>
                                                            </List>
                                                        </div>)
                                                    }
                                                    <div onClick={() => logout()} className={`flex items-center gap-5 mb-3 ${i18n.dir() === 'rtl' ? 'mr-[22.5%]' : 'ml-[29%]'} py-[5%] cursor-pointer`}>
                                                        <img src={Logout} alt="Logout" />
                                                        <p className={`text-deepgrey-3 font-[Roboto-Regular] text-sm`}>{t("Logout")}</p>
                                                    </div>
                                                </div>
                                                    :
                                                    // If none is logged in 
                                                    <div className={`flex flex-col items-center justify-center gap-1.5 w-full py-3`}>
                                                        {token && (role === 'admin' || role === 'subAdmin') ?
                                                            <span className={`w-full flex justify-center text-sm hover:bg-[#ee5a3640] rounded-md px-2 py-1 text-gray-2 my-2 font-medium font-[Roboto-Regular] cursor-pointer`} onClick={() => { navigate('/admin/dashboard') }}>{`Go To Dashboard`}</span>
                                                            :
                                                            <>
                                                                <span className={`text-[10.5px] text-gray-3 font-normal font-[Roboto-Regular]`}>{t("ifNewUser")}</span>
                                                                <span className={`w-full flex justify-center hover:bg-[#ee5a3640] rounded-md px-2 py-1 text-xs text-gray-2 font-normal font-[Roboto-Regular] cursor-pointer`} onClick={() => { localStorage.setItem('userLogin', 'userLogin'); navigate('/signup') }}>{`Signup as Vendor`}</span>
                                                                <span className={`w-full flex justify-center hover:bg-[#ee5a3640] rounded-md px-2 py-1 text-xs text-gray-2 font-normal font-[Roboto-Regular] cursor-pointer`} onClick={() => { localStorage.setItem('userLogin', 'userLogin'); navigate('/usersignup') }}>{`Signup as User`}</span>
                                                            </>
                                                        }
                                                        <ButtonField
                                                            buttonextracls={`bg-seconderyColour-5`}
                                                            buttonnamecls={`font-medium text-white text-xs text-center w-[12em]`}
                                                            buttonName={(role === 'admin' || role === 'subAdmin' || role === 'user') ? "Logout" : "Login"}
                                                            onClick={() => {
                                                                role ? handleLogout() : navigate('/login')
                                                            }}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </ClickAwayListener>
                                    : null}
                            </div>}
                        </div>
                    </div>
                    <div className={`${headerStyles.hamBurger}`}>
                        <HamList button={<img src={hamburgerIcon} alt={`hamburger`} className={`p-[0.8em]`} />}
                            hamListArr={hamListArr}
                        />

                    </div>
                </div>
                {props.showNotification && token && role &&
                    <ClickAwayListener onClickAway={props.handleclickNotification}>
                        <div className={` ${i18n.dir() === 'rtl' ? 'absolute top-[88%] left-[24px]' : 'absolute top-[88%] right-0'}`}>
                            <NotificationShow showAllNotification={props.allnotification} detailsPage={props.detailsPage} readNotificationApi={props.readNotificationApi} />
                        </div>
                    </ClickAwayListener>
                }
            </div >
            <div className={`${headerStyles.searchResponse}`}>

                {showSearch && (role !== 'admin') &&
                    <SearchField
                        placeholderVendor="Search Services & Vendors"
                        placeholderLocation="Location"
                        dropDown={true}
                        location={true}
                    />}
            </div>
            <div className={`${headerStyles.searchBarTab}`}>
                {
                    showSearch && (role !== 'admin') &&
                    <SearchField
                        placeholderVendor="Search Services & Vendors"
                        placeholderLocation="Location"
                        dropDown={true}
                        location={false}
                    />
                }
            </div>

            <ConfirmationModal
                isOpen={modalOpen}
                onConfirm={() => logout()}
                onCancel={() => setModalOpen(false)}
                message="Are you sure you want to logout?"
            />
        </div>
    )
}

// eslint-disable-next-line react/no-typos
LandingHeader.propTypes = {
    searchBar: PropTypes.bool,
    goTomessage: PropTypes.bool,
    goToNotification: PropTypes.bool,
    border: PropTypes.bool,
    profile: PropTypes.bool
}

export default LandingHeader