import userBlack from "../../../Images/adminSideBar/userBlack.svg"
import NotificationIcon from "../../../Images/SubAdmin/NotificationIcon.svg"
import HyperlinkIcon from "../../../Images/SubAdmin/HyperlinkIcon.svg"
import text from "../../../Common/Languages/en_US.json"

export const adminSideMenu = [
    { name: text.subAdmin, icon: userBlack, link: 'subAdmin' },
    { name: text.manageNotification, icon: NotificationIcon, link: 'notification' },
    { name: text.addHyperlink, icon: HyperlinkIcon, link: 'addHyperlink' },
    { name: text.addAnnoucement, icon: HyperlinkIcon, link: 'addAnnouncement' },
]