import React, { useState } from 'react'
import { useForm, Controller } from "react-hook-form"
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import text from '../../../Common/Languages/en_US.json'
import ButtonField from '../../../Common/FormFields/ButtonField'
import CheckBox from '../../../Common/FormFields/CheckBox'
import { Icon, IconButton, InputAdornment } from '@mui/material'
import showEye from '../../../Images/CommonImages/showEyePassword.svg'
import closeEye from '../../../Images/CommonImages/closeEyePassword.svg'
import PhoneInputField from '../../../Common/FormFields/PhoneInputField'

const AddUserForm = (props) => {
    // const [formData, setFormData] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [phoneNo, setPhoneNo] = useState('us');
    //for checking the terms and condition
    const [check, setCheck] = useState(false)

    const {
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        // console.log(data, "#");
        props.editUserFormData ?
            props.editUserApi(data)
            : props.createNewUserByAdmin(data)
        
            props.totalUserCreateMsg?.error === false && reset({
                name: '',
                email: '',
                phoneNo: '',
                place: '',
                location: '',
                fullAddress: '',
                // password: '',
                // cPassword: ''
            })
    };

    const phoneChangeHandler = (value) => {
        setPhoneNo(value);
   } 
    return (
        <div className={`p-10`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex justify-between textFieldText'>
                    <div className={`w-[65%]`}>
                        {/* Name and Email  */}
                        <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
                            {/* Name */}
                            <div className='flex flex-col'>
                                <Controller
                                    name={"name"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm `}
                                                    placeholder='*Enter Name'
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={(props.editUserFormData?.name ? props.editUserFormData?.name : value)}
                                                    floatingLabel={'*Enter Name'}

                                                />
                                            )
                                        )
                                    }}
                                    rules={{
                                        required: true,
                                        pattern: /^[a-zA-Z]/
                                    }}
                                />
                                {errors.name && errors.name.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{props.editUserFormData ? text.NoChangeTxt : text.requiredField}</span>
                                )}
                                {errors.name && errors.name.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">Invalid Name</span>
                                )}
                            </div>

                            <div className='flex flex-col'>
                                {/* Email  */}
                                <Controller
                                    name={"email"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm arrowRemoved`}
                                                    placeholder='*Enter Email'
                                                    onChange={onChange}
                                                    value={(props.editUserFormData?.email ? props.editUserFormData?.email : value)}
                                                    disabled={props.editUserFormData?.email ? true : false}
                                                    floatingLabel={'*Enter Email'}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{
                                        required: props.editUserFormData ? false : true,
                                        pattern: /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/i
                                    }}
                                />
                                {errors.email && errors.email.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.email && errors.email.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">Please enter a valid email</span>
                                )}
                            </div>
                        </div>

                        {/* Password and Re-enter password  */}
                        {/* {!props.editUserFormData &&
                            <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
                                <div className='flex flex-col'>
                                    <Controller name={"password"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (
                                                    <TextFieldInput
                                                        onlyValue
                                                        textnewclass={`flex-1 w-full text-sm  textFieldHeight`}
                                                        placeholder='*Password'
                                                        onChange={onChange}
                                                        value={(value)}
                                                        typePassword={showPassword}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    tabIndex={-1}
                                                                >
                                                                    <Icon className="text-20" color="action" tabIndex={-1}>
                                                                        {showPassword ? (
                                                                            <img src={closeEye} alt="closeEye" />
                                                                        ) : (
                                                                            <img src={showEye} alt="showEye" />
                                                                        )}
                                                                    </Icon>
                                                                </IconButton>
                                                            </InputAdornment>}
                                                    />
                                                )
                                            )
                                        }}
                                        rules={{
                                            required: true,
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/i,
                                            maxLength: 10
                                        }}
                                    />
                                    {errors.password && errors.password.type === "required" && (
                                        <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                    )}
                                    {errors.password && errors.password.type === "pattern" && (
                                        <span className="error-message text-red-400 text-xs">Not Valid</span>
                                    )}
                                    {errors.password && errors.password.type === "maxLength" && (
                                        <span className="error-message text-red-400 text-xs">Too Long</span>
                                    )}
                                </div>
                                <div className='flex flex-col'>
                                    <Controller name={"cPassword"}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                                            return (
                                                (
                                                    <TextFieldInput
                                                        onlyValue
                                                        textnewclass={`flex-1 w-full text-sm  textFieldHeight`}
                                                        placeholder='*Confirm Password'
                                                        onChange={onChange}
                                                        value={(value)}
                                                        typePassword={showCPassword}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setShowCPassword(!showCPassword)}
                                                                    tabIndex={-1}
                                                                >
                                                                    <Icon className="text-20" color="action" tabIndex={-1}>
                                                                        {showCPassword ? (
                                                                            <img src={closeEye} alt="closeEye" />
                                                                        ) : (
                                                                            <img src={showEye} alt="showEye" />
                                                                        )}
                                                                    </Icon>
                                                                </IconButton>
                                                            </InputAdornment>}
                                                    />
                                                )
                                            )
                                        }}
                                        rules={{
                                            validate: (value) => value === getValues("password")
                                        }}
                                    />
                                    {errors.cPassword && errors.cPassword.type === "validate" && (
                                        <span className="error-message text-red-400 text-xs">Doesn't match</span>
                                    )}
                                </div>
                            </div>
                        } */}

                        {/* Phone number and place  */}
                        <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
                            {/* Phone Number */}
                            <div className='flex flex-col'>
                                <Controller name={"phoneNo"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                // <TextFieldInput
                                                //     onlyValue
                                                //     textnewclass={`flex-1 w-full text-sm arrowRemoved`}
                                                //     placeholder='*Enter phone number'
                                                //     typeNumber={true}
                                                //     onChange={onChange}
                                                //     value={(props.editUserFormData?.phoneNo ? props.editUserFormData?.phoneNo : value)}
                                                //     disabled={props.editUserFormData?.phoneNo ? true : false}
                                                //     floatingLabel={'*Enter phone number'}
                                                // />
                                                <PhoneInputField
                                                    // phoneName={'phoneNo'}
                                                    defaultCountry={'kw'}
                                                    placeholder={'*Enter Phone number'}
                                                    label={'*Enter Phone Number'}
                                                    extraCls={`w-full text-sm mt-[0.45rem]`}
                                                    inputCls={`w-full h-[3.3rem] cursor-default`}
                                                    onChange={(value)=>{onChange(value)}}
                                                    value={props.editUserFormData?.phoneNo ? props.editUserFormData?.phoneNo : value}
                                                    enableSearch={true}
                                                    disabled={props.editUserFormData?.phoneNo ? true : false}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{
                                        required: props.editUserFormData ? false : true,
                                        pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                                        minLength: 10,
                                        // maxLength: 10
                                    }}
                                />
                                {errors.phoneNo && errors.phoneNo.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.phoneNo && errors.phoneNo.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">Please enter a valid phone number</span>
                                )}
                                {errors.phoneNo && errors.phoneNo.type === "maxLength" && (
                                    <span className="error-message text-red-400 text-xs">Phone number should contain 10 numbers</span>
                                )}
                                {errors.phoneNo && errors.phoneNo.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">Phone number should contain 10 numbers</span>
                                )}
                            </div>

                            {/* Place  */}
                            <div className='flex flex-col '>
                                <Controller name={"place"}
                                    control={control}
                                 
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm  `}
                                                    placeholder='*Select Place'
                                                    onChange={onChange}
                                                    value={(value)}
                                                    floatingLabel={'*Select Place'}
                                                />
                                            )
                                        )

                                    }}
                                    rules={{ required: true }}
                                />
                                {errors.place && errors.place.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                            </div>
                        </div>

                        {/* Location and Full Address  */}
                        <div className={`grid grid-cols-2 gap-6 w-full my-4`}>
                            {/* location */}
                            <div className='flex flex-col'>
                                <Controller
                                    name={"location"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm`}
                                                    placeholder='Location'
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={(props.editUserFormData?.location ? props.editUserFormData?.location : value)}
                                                    disabled={props.editUserFormData?.location ? true : false}
                                                    floatingLabel={'Location'}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{ required: true }}
                                />
                                {errors.location && errors.location.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                            </div>
                            <div className='flex flex-col '>
                                <Controller
                                    name={"fullAddress"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`flex-1 w-full text-sm`}
                                                    placeholder='Full Address'
                                                    onChange={onChange}
                                                    value={(value)}
                                                    floatingLabel={'Full Address'}
                                                />
                                            )
                                        )

                                    }}
                                rules={{ required: true }}
                                />
                                {errors.fullAddress && errors.fullAddress.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Terms&condition */}
                {!props.editUserFormData &&
                    <div className={`flex justify-start items-center`}>
                        <CheckBox
                            sx={{ '& .css-b29amo-MuiButtonBase-root-MuiCheckbox-root': { padding: 0 } }}
                            required={true}
                            size={`small`}
                            checked={props.value}
                            handleBoxChange={(e) => setCheck(e.target.checked)
                            }
                        />
                        <p className={`text-sm`}>I agree to the</p>&nbsp;
                        <a href='/termsandconditions' target='_blank' className={`text-sm text-skyblue-6 cursor-pointer`} >Terms and Conditions</a>
                    </div>

                }

                <div className={`flex justify-start my-4`}>
                    <div className='flex gap-6'>
                        <ButtonField
                            buttonName={`cancel`}
                            buttonInsidecls={`gap-2`}
                            type='reset'
                            buttonextracls={` !px-6 !py-2 !text-deepgrey-3 bg-Gray-5 font-[Roboto-Medium]`}
                            onClick={props.toggleUserForm}
                        />
                        <ButtonField
                            buttonName={props.editUserFormData ? `Update` : `Add`}
                            type='submit'
                            loading={props.loading}
                            buttonInsidecls={`!flex-row-reverse gap-2 `}
                            buttonextracls={`${check === true || props.editUserFormData ? '!text-white bg-skyblue-6' : 'text-deepgrey-3 bg-Gray-5'} font-[Roboto-Medium] !px-6 !py-2 `}
                            onClick={handleSubmit(onSubmit)}
                            disabled={check === true ? false : props.editUserFormData ? false : true}
                        />
                    </div>
                </div>
                {props.totalUserCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.totalUserCreateMsg?.message}</p> : null}
            </form>
        </div>
    )
}

export default AddUserForm