import React, { useEffect } from 'react'
import UserTab from './UserTab'
import UserCard from './UserCard'
import { useLocation, useNavigate } from 'react-router-dom'
import Message from './Message'
import YourRequirements from './MyReqUser'
import FavouriteVendorUser from './FavoriteVendors'
import MessageSection from '../../VendorDetailsPage/Message'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import UserProfileEdit from './UserProfileEdit'
import TabComponent from '../../../Common/UiComponents/TabComponent'
import { useState } from 'react'
import VendorSettingChangePass from '../../Authentication/Settings/VendorSettingChangePass'
import Chatbox from '../../VendorDetailsPage/Message/Chatbox'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'

const UserMainPages = (props) => {

    const location = useLocation()
    const navigate = useNavigate()
    const role = localStorage.getItem('role')
    const { getFavVendorApiCall, handleFavouriteVendor, deleteFavVendorApiCall, notification, messageClose} = ForLanding()
    const userId = localStorage.getItem('userId')
    const [value, setValue] = useState('0')

    useEffect(() => {
        if (role !== 'user') {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (location.pathname === '/user/FavoriteVendors')
        {
            getFavVendorApiCall(userId) 
        } else if (location.pathname === '/user/message') {
            props.getChatsApiCAll()
        }
    }, [userId, location.pathname])

    const editProfileArr = [
        {
            label: "Profile Details",
            value: "0",
            backgroundcolor: "bg-[#F7F7F7]",
            content: <UserProfileEdit
                handleUserProfileEdit={props.handleUserProfileEdit}
                userData={props.userData}
                editVendorProfileDetailsApi={props.editVendorProfileDetailsApi}
                goBackFromUserProfileEdit={props.goBackFromUserProfileEdit}
                mapLocationTxt={props.mapLocationTxt}
                setMapLocationTxt={props.setMapLocationTxt}
                mapLocation={props.mapLocation}
                handleClickLocation={props.handleClickLocation}
                loading={props.loading}
            />
        },
        {
            label: "Change Password",
            value: "1",
            backgroundcolor: "bg-white",
            content:
                <VendorSettingChangePass
                    changePasswordForVendorApiCall={props.changePasswordForVendorApiCall}
                    goBackFromUserProfileEdit={props.goBackFromUserProfileEdit}
                    forUserEdit='forUserEdit'
                    changePasswordLoader={props.changePasswordLoader}
                />
        },
    ]

    return (
        <div className={`flex gap-[1%] p-[2%] bg-white-5`}>
            <UserCard userData={props?.userData} editUserProfile={props.editUserProfile} handleUserProfileEdit={props.handleUserProfileEdit} editVendorProfileDetailsApi={props.editVendorProfileDetailsApi} />
            <div className='flex flex-col w-[78%] h-[100%]'>
                {props.editUserProfile === true ?
                    <TabComponent
                        mappingname={editProfileArr}
                        onClick={props.handleUserProfileEdit}
                        handleUserProfileEdit={props.handleUserProfileEdit}
                        value={value}
                        setValue={setValue}
                    />
                    :
                    <>
                        <UserTab />
                        {location.pathname === `/user` ?
                            <YourRequirements
                                toggleUserMyRequirementForm={props.toggleUserMyRequirementForm}
                                showMyReqrFormUser={props.showMyReqrFormUser}
                                myRequirementCreateUser={props.myRequirementCreateUser}
                                getAllRequirementsUser={props.getAllRequirementsUser}
                                getUserRequirementsByUserId={props.getUserRequirementsByUserId}
                                goToUserMyReqrLiveDetails={props.goToUserMyReqrLiveDetails}
                                showUserMyReqrLiveDetails={props.showUserMyReqrLiveDetails}
                                goBackFromMyReqrLiveDetails={props.goBackFromMyReqrLiveDetails}
                                saveReqData={props.saveReqData}
                                loadingUser={props.loadingUser}
                                downloadPdfAttachments={props.downloadPdfAttachments}
                                multipleMediaApiCall={props.multipleMediaApiCall}
                                currentUserRequirementPage={props?.currentUserRequirementPage} 
                                setCurrentUserRequirementPage={props?.setCurrentUserRequirementPage} 
                                totalUserRequirementItems={props?.totalUserRequirementItems} 
                                itemsPerPage={props?.itemsPerPage}
                            />
                            :
                            location.pathname === `/user/FavoriteVendors` ?
                                <FavouriteVendorUser
                                    getFavVendorApiCall={getFavVendorApiCall}
                                    handleFavouriteVendor={handleFavouriteVendor}
                                    deleteFavVendorApiCall={deleteFavVendorApiCall} />
                                :
                                location.pathname === `/user/message` &&
                                <Chatbox
                                    getChatbyChatIdApiCAll={props.getChatbyChatIdApiCAll}
                                    postChatApiCall={props.postChatApiCall}
                                    getChatDetailsWithUserApiCAll={props.getChatDetailsWithUserApiCAll}
                                    chatArr={props.chatArr}
                                    singleChatDetail={props.singleChatDetail}
                                    singleChatWith={props.singleChatWith}
                                    multipleMediaApiCall={props.multipleMediaApiCall}
                                    getChatsApiCAll={props.getChatsApiCAll}
                                    downloadPdfAttachments={props.downloadPdfAttachments}
                                    downloadImage={props.downloadImage}
                                    chatMessageReadApi={props.chatMessageReadApi}
                                    loading={props.loading}
                                />}
                    </>
                }
            </div>
            <SnackBarComponent
                messageOpen={notification.open}
                messageClose={messageClose}
                notificationText={notification.message}
                subText={notification.subText}
                alertType={notification.alertType}
                borderClass={notification.borderClass}
            />
        </div>
    )
}

export default UserMainPages