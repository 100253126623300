import React, { useState, useEffect } from 'react'
import UploadButton from '../../UiComponents/UploadButton'
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import dummyImageUpload from '../../../Images/CommonImages/dummyImageUpload.svg'
import texts from '../../Languages/en_US.json'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const UploadMultipleImage = (props) => {
    const [isError, setIsError] = useState(false)
    const { t } = useTranslation()

    const [uploadImageFirst, setUploadImageFirst] = useState()

    const prductImage4 = [fileUploadIcon, fileUploadIcon, fileUploadIcon, fileUploadIcon]
    const prductImage3 = [fileUploadIcon, fileUploadIcon, fileUploadIcon]
    const prductImage2 = [fileUploadIcon, fileUploadIcon]
    // console.log(props?.multipleUploadState, "multipleUploadState")
    return (
        <>
            <UploadButton
                // sendData={(data) => setUploadImageFirst(data)}
                sendData={props.sendData}
                dropZoneRootCls={`w-full`}
                dropzoneGetRootPropsCls={`border border-dashed border-skyblue-5 py-[5em] rounded flex justify-center items-center bg-skyblue-0 cursor-pointer`}
                extracls='flex justify-center items-center items-center rounded-md '
                type='button'
                fileUploadIcon={props.uploadicon ? props.uploadicon : dummyImageUpload}
                accept={props.accept}
                textContentOuterCls={`flex-col flex-col-reverse`}
                textContent2
                texExtraClsRoot={`flex flex-col items-center`}
                texExtraCls1={`text-skyblue-6 text-base leading-6 font-[Roboto-Medium] pt-2.5`}
                texExtraCls2={`text-gray-6 text-[13px] leading-[19px] font-[Roboto-Regular] pt-2.5`}
                texExtraCls3={`bg-skyblue-5 py-2.5 w-[70%] rounded-full text-xs leading-3 text-white font-[Roboto-Medium] mt-5`}
                text1={t(props.text1)}
                text2={t(props.text2)}
                text3={t(props.text3)}
                disabled={props.disabled}
                multiple={props.multiple}
                setIsError={setIsError}
                fileLimit={props?.fileLimit}
            // loading={props.loading}
            />
                {
                props?.fileLimit && isError && <span className={`text-red-600 text-[12px]`}>
                    {`You can select maximum ${props?.fileLimit} images`}
                </span>
            }
            <div className={`flex w-full justify-between`}>
                {/* {(props.multipleUpload?.length > 0 && props.multipleUpload?.length <= 5) &&
                    props.multipleUpload?.map((item, idx) =>
                        <div key={idx} className={``}>
                            <img
                                // src={`${process.env.REACT_APP_DG_BASE_URL}/uploads?path=${item?.path}&height=auto&width=75&fit=contain`}
                                src={`${URL.createObjectURL(item)}`}
                                alt='uploadImg'
                                className={`rounded w-20 h-20`}
                            />
                        </div>)
                } */}

                {/* {
                    uploadImageFirst?.length === 1 ?
                        prductImage3.map((img4) =>
                            <img
                                key={img4}
                                src={img4}
                                alt='uploadImg'
                                height={90}
                                width={80}
                                className={`rounded`}
                            />
                        )
                        :
                        uploadImageFirst?.length === 2 ?
                            prductImage2.map((img4) =>
                                <img
                                    key={img4}
                                    src={img4}
                                    alt='uploadImg'
                                    height={90}
                                    width={80}
                                    className={`rounded`}
                                />
                            )
                            :
                            uploadImageFirst?.length === 3 ? <img
                                src={fileUploadIcon}
                                alt='uploadImg'
                                height={90}
                                width={80}
                                className={`rounded`}
                            />
                                :
                    uploadImageFirst?.length === 4 ?
                        null
                        :
                        prductImage4.map((img4) =>
                            <img
                                key={img4}
                                src={img4}
                                alt='uploadImg'
                                height={90}
                                width={80}
                                className={`rounded`}
                            />
                        )
                } */}
            </div>
        
        </>
    )
}

export default UploadMultipleImage