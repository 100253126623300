import React, { useEffect } from 'react'
import BidCard from '../../../Common/Layout/Card/BidCard'
import DefaultProfileicon from '../../../Images/Vendor/DefaultProfileicon.svg'
import moment from 'moment'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import PaginationComponent from '../../../Common/UiComponents/paginationComponent/PaginationComponent'

const ExpiredBid = (props) => {

    useEffect(() => {
        props.getAllOfMyRequirements('expire')
    }, [props?.currentPage]);

    const exxpiredRequirementsData = useSelector((state) => state.vendor.myrequirements)

    return (
        <div className='overflow-y-scroll h-[78vh]'>
            {props.loading === true ?
                <div className='flex h-full justify-center items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <div>
                    {exxpiredRequirementsData && exxpiredRequirementsData?.length > 0 ?
                        <>
                            {exxpiredRequirementsData?.map((allReq, idx) =>
                                <div key={idx}>
                                    {allReq?.status === 'expire' &&
                                        <BidCard
                                            DefaultProfileicon={DefaultProfileicon}
                                            name={allReq?.user?.name}
                                            email={allReq?.user?.email}
                                            question={allReq?.title}
                                            content={allReq?.description}
                                            flex={true}
                                            showdate={true}
                                            date={moment(allReq?.startDate).format('LL')}
                                            // peoplereplied={true}
                                            // numberofpeoplereplied={allReq?.numberofpeoplereplied}
                                            uploadDocument={false}
                                            expiredtime={false}
                                            time={moment(allReq?.endDate).format('LL')}
                                            onClick={() => props.goToAllReqrLiveDetails(allReq, false)}
                                            item={allReq}
                                        />
                                    }
                                </div>
                            )}
                        </>
                        :
                        <NoContentPage
                            NoContentMainDiv={`h-[78vh]`}
                            text2={`There is no data`}
                        />
                    }
                </div>}
                   <PaginationComponent
                                totalItems={props?.totalRequirementsItems}
                                itemsPerPage={props?.itemsPerPage}
                                currentPage={props?.currentPage}
                                onPageChange={props?.setCurrentPage}
                                siblingCount={1}
                                boundaryCount={2}
                                variant="outlined"
                                color="primary"
                            />
        </div>
    )
}

export default ExpiredBid