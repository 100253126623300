import React, { useState } from 'react';
import MuiCarousel from '../../UiComponents/Carousel'
import RatingField from '../../UiComponents/Rating'
import ButtonField from '../../FormFields/ButtonField'
import LikeIcon from '../../UiComponents/LikeIcon'
import phone from '../../../Images/Vendor/phone.svg'
import location from '../../../Images/Vendor/location.svg'
import distance from '../../../Images/Vendor/distance.svg'
import PropTypes from 'prop-types';
import ratingicon from '../../../Images/Vendor/ratingicon.svg'
import trackicon from '../../../Images/Vendor/trackicon.svg'
import defaultserviceIcon from '../../../Images/vendors/defaultprofile.svg'
import NoContentPage from '../NoContentPage/NoContentPage'
import bidAward from '../../../Images/Vendor/bidAward.svg'
import editIcon from '../../../Images/adminTable/editIcon.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import { Tooltip } from '@mui/material'
import { distanceFunc, priceAfterDiscount } from '../../../Utils/CommonFunc'
import deleteicon from "../../../Images/profileDashboardIcons/delete.svg"
import DeclinePopup from '../../FormFields/DeclinePopup'
import { useTranslation } from 'react-i18next'
import ShareButton from '../../UiComponents/ShareButton'
import cardBatch from '../../../Images/Vendor/cardBatch.svg'

const CommonCard = (props) => {
  const role = localStorage.getItem('role')
  const { getChatDetailsWithUserApiCAll, chatBtnLoading } = ForLanding()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const pathLocation = useLocation()
  const token = localStorage.getItem('token')
  let lat = parseFloat(sessionStorage.getItem('latitude'))
  let lng = parseFloat(sessionStorage.getItem('longitude'))

  const [openTrackModal, setOpenTrackModal] = useState(false)
  const handleClickForTrack = () => {
    setOpenTrackModal(!openTrackModal)
  }
  const clickedVendorLatlng = React.useMemo(() => ({
    lat: props.item?.lat ? props.item?.lat : props.item?.business ? props.item?.business?.lat : props.item?.services ? props.item?.services?.business?.lat : props.item?.product ? props.item?.product.business?.lat : 0,
    lng: props.item?.long ? props.item?.long : props.item?.business ? props.item?.business?.long : props.item?.services ? props.item?.services?.business?.long : props.item?.product ? props.item?.product.business?.long : 0,
    location: props.item?.location ? props.item?.location : props.item?.business ? props.item?.business?.location : props.item?.services ? props.item?.services?.business?.location : props.item?.product && props.item?.product?.business?.location
  }), []);
  const goToOriginalGMap = () => {
    if (token) {
      window.open(`https://www.google.com/maps/dir/${parseFloat(sessionStorage.getItem('latitude'))},${parseFloat(sessionStorage.getItem('longitude'))}/${clickedVendorLatlng?.location}/@${clickedVendorLatlng?.lat},${clickedVendorLatlng?.lng},${10}z`);
      setOpenTrackModal(!openTrackModal)
    } else {
      navigate('/login')
    }
  }

  const onMessageClick = (item) => {
    if (token !== null) {
      getChatDetailsWithUserApiCAll(item?.user?.id).then((res) => {
        if (res.id) {
          role === 'user' ? navigate('/user/message', { state: res }) : navigate('/vendor/message', { state: res })
        } else {
          role === 'user' ? navigate('/user/message', { state: item }) : navigate('/vendor/message', { state: item })
        }
      }).catch((err) => { console.log(err) });
    } else {
      navigate('/login')
    }
  }

  const onTrackClick = (item) => {
    if (token) {
      navigate('/maps', { state: item })
    } else {
      navigate('/login')
    }
  }

  //likeIcon common object made
  const likeIconFunc = <div className={`absolute top-[8%] z-10 right-[4%]`}>
    {props.showLikeIcon &&
      <LikeIcon
        size="small"
        onChange={() => {
          if (props.favValue !== 1) {
            props.onChange()
          } else {
            props.onDelChange()
          }
        }}
        favValue={props.favValue}
      />
    }
  </div>

  const shareUrl = `${process.env.REACT_APP_SHARE_URL}/details/${props?.item?.userId ? props?.item?.userId : props?.item?.user?.id}`


  return (
    <div className={` border w-full rounded-md bg-white relative shadow shadow-gray-500/20 group/item mb-5  duration-500 carousel ${props.extracls}  ${props.height ? "h-[29rem]" : null}`}>
     {props?.isLandingPage && <div className={`absolute z-[10] pt-4`}>
              <img src={cardBatch} alt="Batch" className={`h-7`} />
      </div>}

      {props.carousel ? (   //this checking is for to show carousel or not
        <div className={`relative`}>
          {/* <div className={`flex absolute top-[8%] z-10 left-0`}>
              <img src={cardBatch} alt="Batch" className={``} />
          </div> */}
          <MuiCarousel
            itemContents={props.carouselimg} // carouselimg reffers to show the images
            height={props.carouselHeight}
            width={props.carouselwidth}
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            stopAutoPlayOnHover={false}
            animation="slide"
            indicators={false}
            navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
              style: {
                borderRadius: 50,
                margin: 2,
                backgroundColor: '#333',
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
              }
            }} navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
              style: {
                bottom: '0',
                top: 'unset'
              }
            }}
          />

          {props.featurecrown ? (   //this checking is for to show featurecrown section or not
            <div className={`flex absolute top-[8%] z-10 left-0`}>
              <img src={props.crownicon} alt="crownicon" className={`w-5 h-5 bg-Active100 cursor-pointer`} />
              <img src={props.featuretag} alt="featuretag" className={`cursor-pointer`} />
            </div>
          ) : null}

          {likeIconFunc}
        </div>
      )
        :
        props.noContentImg ?
          <div className={`${props.noContentCls} relative`}>
            <NoContentPage
              // text1={`Sorry`}
              NoContentMainDiv={`pt-10`}
              text2={`There are no images`}
            />
            {likeIconFunc}
          </div>
          :
          null
      }


      <div className={`flex justify-between items-center px-3 py-3  text-sm font-[Roboto-Regular] relative cursor-pointer`} onClick={props.onHandelOpeModal}>
        <div className={`flex items-center gap-1`}>
          <div>
            {props.profile ? (  //this checking is for to show profileicon  or not
              <img crossOrigin='anonymous' src={props.profileicon ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.profileicon}&height=60&width=60` : defaultserviceIcon} alt="profileicon" className={`${props.profileicon ? 'border rounded-full' : 'h-[60px] w-[60px]'}`} />
            ) : null}
          </div>

          <div className={`flex flex-col pr-5`}>
            <p className={`text-sm text-skyblue-6 font-[Roboto-Bold]`}>{props.itemname}</p>

            {props.itememail ? (    //this checking is for to show itememail or not   
              <p className={`text-xs text-[#434343]/90 font-[Roboto-regular]`}>{props.email}</p>
            ) : null}

            {props.ratingforname ? (    //this checking is for to show ratingforname or not
              <div className='flex'>
                <div className={`flex items-center mr-4`}>
                  <img src={ratingicon} alt='rating icon' height={15} width={15} />
                  <p className={`pt-1 pl-1 font-[Roboto-Medium] text-[13px] text-[#434343]/[.6]`}>{props.ratingvalue} out of 5</p>
                </div>
                {props.item?.selected === true && <img src={bidAward} alt="bidAward" className='h-10 w-20 absolute -right-2 ' />}
              </div>
            ) : null}
          </div>

        </div>
        <div className=''>
          {props.Shareicon ? (  //this checking is for to show if Shareicon is true thn its shows shareicon otherwise it shows date
            <ShareButton url={shareUrl} fbCaption={'Please visit Explore...'} twitterCaption={'Please visit Explore...'} whatsappCaption={'Please visit Explore...'} />
          )
            :
            (
              <p className={`font-[Roboto-Regular] text-xs text-[#434343]/[0.6]`}>{props.date}</p>
            )
          }
          {props.deleteIcon && (pathLocation?.pathname === '/vendor/services' || pathLocation?.pathname === '/vendor/products') &&
            <img src={deleteicon} alt="deleteIcon" height={30} width={30} className={`cursor-pointer pl-4`} onClick={props.deleteIconFunc} />
          }
        </div>

      </div>
      {props.acceptdeclinesection ? (
        <div className={`flex justify-between px-3`}>
          {props.item?.selected !== true &&
            <div className={`flex gap-3`}>
              <ButtonField
                buttonextracls={`bg-skyblue-6 text-white p-2 w-20`}
                buttonName="Accept"
                buttonnamecls={`  text-[10px] font-[Roboto-Medium]`}
                onClick={props.handleAcceptBid}
              />
              <ButtonField
                buttonextracls={`bg-black/[.12] text-black/[.35] p-2 w-20`}
                buttonName="Decline"
                buttonnamecls={`  text-[10px] font-[Roboto-Medium]`}
                onClick={props.handleDeclineBid}
              />
            </div>
          }
          {(props.item?.business?.lat !== null && props.item?.business?.long !== null) &&
            <div
              className='flex items-center cursor-pointer'
              // onClick={() => onTrackClick(props.item)}
              onClick={() => handleClickForTrack()}
            >
              <img src={trackicon} alt="trackicon" height={15} width={15} />
              <p className={`pl-2 text-sm text-deepgrey-3 font-[Roboto-Medium]`}>Track</p>
            </div>
          }
        </div>
      ) : (props.item?.selected === true && props.item?.business?.lat !== null && props.item?.business?.long !== null) ?
        <div
          className='flex items-center px-3 w-fit cursor-pointer'
          //  onClick={() => onTrackClick(props.item)}
          onClick={() => handleClickForTrack()}
        >
          <img src={trackicon} alt="trackicon" height={15} width={15} />
          <p className={`pl-2 text-sm text-deepgrey-3 font-[Roboto-Medium]`}>Track</p>
        </div>
        : null
      }

      {props.itemtext ? (    //this checking is for to show itemtext or not
        <div className={`cursor-pointer`} onClick={props.onHandelOpeModal}>
          <Tooltip title={props.text1}>
            <p className='px-3 pt-2 text-base font-[Roboto-Medium] break-word text-deepgray-5 w-[13em] truncate'>
              {props.text1}
            </p>
          </Tooltip>
          <p className='px-3 pb-2 text-xs font-[Roboto-Medium] truncate break-word text-deepgray-4 w-[10rem]'>
            {props.text2}
          </p>
        </div>
      ) : null}


      {props.rating ? (     //this checking is for to show rating  or not
        <div className='flex px-3'>
          {props.RatingField ? (   //this checking is for to show if RatingField is true thn show the field othewise its shows out of 5 section
            <RatingField
              size="small"
              highlightSelectedOnly={true} />
          ) :
            // <div className={`flex items-center mr-4`}>
            //   <img src={ratingicon} alt='rating icon' height={15} width={15} />
            //   <p className={`pt-1 pl-1 font-[Roboto-Medium] text-[13px] text-[#434343]/[.6]`}>{props.ratingvalue} out of 5</p>
            // </div>
            null
          }
          {props.percentage ?
            <p className={`bg-skyblue-5 text-white lowercase text-[13px] rounded px-3 text-center py-1 font-[Roboto-Bold] cursor-pointer`} onClick={props.onHandelOpeModal}>{props.offerpercentage}%&nbsp;off</p>
            :
            props.price ?
              <p className={`bg-skyblue-5 text-white lowercase text-[13px] rounded px-3 text-center py-1 font-[Roboto-Bold] cursor-pointer`} onClick={props.onHandelOpeModal}>{props.offerprice}&nbsp;{t(`KD`)}&nbsp;off</p>
              :
              (props.addOffer && pathLocation?.pathname === '/vendor/services') ?
                <ButtonField
                  buttonextracls={`bg-skyblue-6 text-white text-xs capitalize`}
                  buttonName='addOffer'
                  onClick={props.handleAddOffer}
                />
                :
                <div className={`!h-[1.6944em]`}></div>
          }
        </div>
      ) : null}

      {/* {props.addOffer && pathLocation?.pathname === '/vendor' &&
        <div className='px-3'>
          <ButtonField
            buttonextracls={`bg-skyblue-6 text-white text-xs capitalize`}
            buttonName='addOffer'
            onClick={props.handleAddOffer}
          />
        </div>
        } */}
      {props.border ? (    //this checking is for to show border  or not
        <div className={`border border-gray-1.1 mt-5`}></div>
      ) : null}


      <div className={`px-3 cursor-pointer`} onClick={props.onHandelOpeModal}>
        {props.phone ? (   //this checking is for to show phone  or not
          <div className={`flex pb-[4%]`}>
            <img src={phone} alt="phone" />
            <p className={`pl-2 text-deepgrey-3 text-[13px] font-[Roboto-Regular] `}>{props.phonetext}</p>
          </div>
        ) : null}

        {/* //this checking is for to show location  or not */}
        <div className={`flex flex-col justify-between pb-[4%] gap-2`}>
          {props.location &&
            <div className={`flex `}>
              <img src={location} alt="phone" />
              <Tooltip title={props?.locationtext}>
                <p className={`pl-2 text-deepgrey-3 text-[13px] font-[Roboto-Regular] cursor-default truncate w-[10em]`}>{props.locationtext}</p>
              </Tooltip>
            </div>
          }
          {props.showlocationamount ? (    //this checking is for to show showlocationamount  or not
            <div className={`flex items-center gap-2`}>
              <p className={`text-deepgrey-5 text-lg font-[Roboto-Medium] z-10 ${(props.offerpercentage || props.offerprice) && 'line-through'}`}>{props.amount}&nbsp;{t(`KD`)}</p>
              {props.offerpercentage ?
                <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(props.amount, null, props.offerpercentage)}&nbsp;{t(`KD`)}</p>
                :
                props.offerprice &&
                <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(props.amount, props.offerprice, null)}&nbsp;{t(`KD`)}</p>
              }
              {props.editPrice && <img src={editIcon} alt='edit' onClick={props.editPriceOnClick} className={`cursor-pointer`} />}
            </div>
          ) : null}
        </div>


        <div className={`flex justify-between w-full`}>
          {props.distance ? (    //this checking is for to show distance  or not
            <div className={`flex pb-[4%]`}>
              <img src={distance} alt="distance" />
              <p className={`pl-2 text-deepgrey-3 text-[13px] font-[Roboto-Regular]`}>{distanceFunc(lat, props?.item?.lat, lng, props.item?.long) + " K.M away"}</p>
            </div>
          ) : null}

          {props.showamount ? (   //this checking is for to show showamount  or not
            <>
              <p className={`text-deepgrey-5 text-lg font-[Roboto-Medium] ${(props.offerpercentage || props.offerprice) && 'line-through'}`}>{props.amount}&nbsp;{t(`KD`)}</p>
              {props.offerpercentage ?
                <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(props.amount, null, props.offerpercentage)}&nbsp;{t(`KD`)}</p>
                :
                props.offerprice &&
                <p className={`font-[Roboto-Medium]`}>{priceAfterDiscount(props.amount, props.offerprice, null)}&nbsp;{t(`KD`)}</p>
              }
            </>
          ) : null}
        </div>
      </div>
      <div className={`px-3 py-3 cursor-pointer`}>
        <p className={`font-[Roboto-Regular] text-xs text-[#434343]/[0.6] truncate`}>{props?.description}</p>
      </div>
      {props.hoverbutton ? (   //this checking is for to show hoverbutton section or not
        <div className={`group/edit invisible flex flex-wrap gap-2 px-3 pb-3 justify-between group-hover/item:visible delay-200`}>
          <ButtonField
            buttonextracls={`bg-skyblue-6 text-white p-3 mr-4 `}
            buttonName={props.buttonName2}
            loading={chatBtnLoading}
            img={props.buttonimg}
            buttonnamecls={`pl-2  text-[10px] font-[Roboto-Medium]`}
            onClick={() => onMessageClick(props.item)}
          />
          {props.hoverbuttonTrack === true &&
            <ButtonField
              buttonextracls={`border-1 border-deepgray-4 bg-white text-black p-3 text-xs font-[Roboto-Medium]`}
              buttonName={props.buttonName3}
              img={props.buttonimg2}
              variant="outlined"
              buttonnamecls={`pl-2 text-[10px] font-[Roboto-Medium]`}
              // onClick={() => onTrackClick(props.item)}
              onClick={() => handleClickForTrack()}
            />
          }
        </div>

      ) : null}
      {props.vendorfullwidth ? (
        <div className={`w-full p-[3%]`}>
          <ButtonField
            buttonextracls={`bg-skyblue-6 text-white p-3 mr-4 w-full`}
            buttonName={props.vendorbutton}
            img={props.vendoricon}
            buttonnamecls={`pl-2  text-[10px] font-[Roboto-Medium]`}
            onClick={() => onMessageClick(props.item)}
          />
        </div>
      ) : null}

      {openTrackModal &&
        <DeclinePopup
          dialogTitlecls={`!text-yellow-600`}
          open={openTrackModal}
          handleClose={handleClickForTrack}
          title={`Track`}
          DialogContent={
            <>
              <p>{`Where do you want to go?`}</p>
            </>
          }
          cancelTextCls={`!bg-blue-600 !text-white`}
          notCancelText={`Browser's Google Map`}
          handleCancelButton={goToOriginalGMap}
          submitBtnCls={`!bg-green-600`}
          DeleteBtn={"In-App Map"}
          onClick={() => onTrackClick(props.item)}
        />
      }
    </div>

  )
}

// eslint-disable-next-line react/no-typos
CommonCard.propTypes = {
  carouselimg: PropTypes.array,
  itemname: PropTypes.string,
  offerpercentage: PropTypes.string,
  phonetext: PropTypes.string,
  locationtext: PropTypes.string,
  distancetext: PropTypes.string,
  buttonName2: PropTypes.string,
  buttonName3: PropTypes.string,
  carousel: PropTypes.bool,
  height: PropTypes.bool,
  featurecrown: PropTypes.bool,
  profile: PropTypes.bool,
  itememail: PropTypes.bool,
  rating: PropTypes.bool,
  Shareicon: PropTypes.bool,
  itemtext: PropTypes.bool,
  RatingField: PropTypes.bool,
  border: PropTypes.bool,
  phone: PropTypes.bool,
  location: PropTypes.bool,
  showlocationamount: PropTypes.bool,
  distance: PropTypes.bool,
  showamount: PropTypes.bool,
  hoverbutton: PropTypes.bool,
  ratingforname: PropTypes.bool,
  showLikeIcon: PropTypes.bool,
  editPrice: PropTypes.bool,
  addOffer: PropTypes.bool,
  percentage: PropTypes.bool,
  price: PropTypes.bool
}

export default CommonCard