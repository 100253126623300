import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import ButtonField from '../../../Common/FormFields/ButtonField';
import InputField from '../../../Common/FormFields/InputField';
import CreateAddress from '../../Authentication/Signup/CreateAddress';
import Businessbuttonicon from "../../../Images/accountcreate/bussinessbuttonicon.svg"
import Businessbuttonblueicon from "../../../Images/accountcreate/bussinessbuttonblueicon.svg"
import Indivisualbuttonicon from "../../../Images/accountcreate/indivisualbuttonicon.svg"
import Indivisualbuttonblueicon from "../../../Images/accountcreate/indivisualbuttonblueicon.svg"
import CheckBox from '../../../Common/FormFields/CheckBox';
import CreateBusinessAddressByAdmin from './CreateBusinessAddressByAdmin';
import PhoneInputField from '../../../Common/FormFields/PhoneInputField';
const AddVendorFromForAdmin = (props) => {
  const { handleSubmit, reset, control, handleError, formState: { errors } } = useForm();
  const [colorForBussiness, setColorForBussiness] = useState(false); //to change the bussinessbutton color on onclick
  const [colorForIndivisual, setColorForIndivisual] = useState(false); //to change the indivisualbutton color on onclick
  const [typecheck, setTypecheck] = useState("")
  const [check, setCheck] = useState(false) // state for signup checkbox

  const [vendorFormdata, setVendorFormData] = useState({ name: "", email: "", phoneNo: "", typecheck: "" })
    ;
  const onSubmit = (data) => {
    setVendorFormData(data, typecheck)
    props.editVendorFormData ? props.editclick(data, typecheck) : props.createVendorByAdmin(data, typecheck)
    props.totalVendorCreateMsg?.error === false && reset(vendorFormdata)
  }

  const handleChecking = (e) => {
    setCheck(e.target.checked)
  }


  const handleClickForChooseBussiness = () => {
    if (typecheck === "business") {
      setTypecheck('')
      setColorForBussiness(!colorForBussiness)
      setColorForIndivisual(false)

    } else {
      setColorForBussiness(!colorForBussiness)
      setColorForIndivisual(false)
      setTypecheck("business")
    }
  }


  //this function is use  to change the color on Choose IndividualButton click

  const handleClickForChooseIndivisual = () => {
    if (typecheck === "individual") {
      setColorForIndivisual(!colorForIndivisual)
      setColorForBussiness(false)
      setTypecheck("")

    } else {
      setColorForIndivisual(!colorForIndivisual)
      setColorForBussiness(false)
      setTypecheck("individual")
    }
  }

  return (
    <>
      {!props.showAddAddress ?
        <div className={`w-full flex lg:flex-row flex-col justify-between p-[5%]`}>

          {/* middleside start*/}

          <div className={` w-[50%]`}>
            <form onSubmit={handleSubmit(onSubmit, handleError, reset)} className={`flex flex-col items-start justify-between h-[28em] `}>
              {/* namesection */}

              <div className={`w-full my-3 `}>
                <Controller
                  name={"name"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[a-zA-Z][a-zA-Z ]*/i
                  }}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      extracls={` text-sm `}
                      placeholder="*Enter your name"
                      textinputname="name"
                      variant="outlined"
                      type="text"
                      id="name"
                      defaultValue={(props.editVendorFormData?.name ? props.editVendorFormData?.name : value)}
                      onChange={onChange}
                      value={value}
                      floatingLabel={'*Enter your name'}
                    />
                  )}
                />
                <small className={`text-red-500`}>
                  {errors?.name && errors?.name?.type === "required" && (<p>This field is required</p>)}
                  {errors?.name && errors?.name?.type === "pattern" && (
                    <p>Please enter a valid name</p>
                  )}
                </small>
              </div>

              {/* emailsection */}

              <div className={`flex flex-col w-full`}>
                <div className={`flex space-between border border-gray-200 rounded w-full  cursor-pointer items-center`} >
                  <Controller
                    name={"email"}
                    control={control}
                    rules={{
                      required: props.editVendorFormData ? false : true,
                      pattern: /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/i

                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        extracls={`w-full text-sm !p-0`}
                        placeholder="*Enter your email"
                        textinputname="email"
                        variant="outlined"
                        type="email"
                        id="email"
                        onChange={onChange}
                        value={(props.editVendorFormData?.email ? props.editVendorFormData?.email : value)}
                        disabled={props.editVendorFormData?.email ? true : false}
                        floatingLabel={'*Enter your email'}
                      />
                    )}
                  />

                </div>
                <small className={`text-red-500 mt-2`}>
                  {errors?.email && errors?.email?.type === "required" && (<p>This field is required</p>)}
                  {errors?.email && errors?.email?.type === "pattern" && (
                    <p>Please enter a valid email</p>
                  )}
                </small>


              </div>


              {/* Phone Number section */}

              <div className={`flex flex-col w-full`}>
                <div className={`flex space-between border border-gray-200 rounded w-full  cursor-pointer items-center`} >
                  <Controller
                    name={"phoneNo"}
                    control={control}
                    rules={{
                      required: props.editVendorFormData ? false : true,
                      pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                      minLength: 10
                      // maxLength: 10

                    }}
                    render={({ field: { onChange, value } }) => (
                      // <InputField
                      //   extracls={`w-full text-sm`}
                      //   placeholder="*Enter your Phone number"
                      //   textinputname="phoneNo"
                      //   variant="outlined"
                      //   type="number"
                      //   id="phoneNo"
                      //   onChange={onChange}
                      //   value={(props.editVendorFormData?.phoneNo ? props.editVendorFormData?.phoneNo : value)}
                      //   disabled={props.editVendorFormData?.phoneNo ? true : false}
                      //   floatingLabel={'*Enter your Phone Number'}
                      // />
                      <PhoneInputField
                        // phoneName={'phoneNo'}
                        defaultCountry={'kw'}
                        placeholder={'*Enter Phone number'}
                        label={'*Enter Phone Number'}
                        extraCls={`w-full text-sm mt-[0.45rem]`}
                        inputCls={`w-full h-[3.3rem] cursor-default`}
                        onChange={(value) => { onChange(value) }}
                        value={props.editVendorFormData?.phoneNo ? props.editVendorFormData?.phoneNo : value}
                        enableSearch={true}
                        disabled={props.editVendorFormData?.phoneNo ? true : false}
                      />
                    )}
                    
                  />
                </div>
                <small className={`text-red-500 mt-2`}>
                  {errors?.phoneNo?.type === "required" && <p>This field is required</p>}
                  {errors?.phoneNo?.type === "pattern" &&
                    <p>Please enter a valid phone number</p>
                  }
                  {errors?.phoneNo?.type === "maxLength" && (
                    <p>Phone number should contain maximum 10 numbers</p>
                  )}
                </small>

              </div>


              {/* Choose Vendor Type */}
              <div>
                <p className={`font-[Roboto-Regular] text-base text-[Grey-900] mb-3`}>
                  Choose Vendor Type
                </p>
                <div className={`flex`}>

                  <ButtonField
                    buttonextracls={`bg-gray-0.5 border-[1.5px] border-solid border-deepgray-0.5 font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs mr-6 ${colorForBussiness ? " bg-[#DCF2FA]/80 border-[#0099CC] " : "bg-gray-0.5 border-deepgray-0.5"} `}
                    buttonName="Businesses"
                    imgtext={`justify-between w-28`}
                    buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm ${colorForBussiness ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                    img={colorForBussiness ? Businessbuttonblueicon : Businessbuttonicon}
                    onClick={handleClickForChooseBussiness}

                  />

                  <ButtonField
                    buttonextracls={` border-[1.5px] border-solid  font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs ${colorForIndivisual ? " bg-[#DCF2FA]/80 border-[#0099CC] " : "bg-gray-0.5 border-deepgray-0.5"} `}
                    imgtext={`justify-between w-28`}
                    buttonName="Individuals"
                    buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm ${colorForIndivisual ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                    img={colorForIndivisual ? Indivisualbuttonblueicon : Indivisualbuttonicon}
                    onClick={handleClickForChooseIndivisual}

                  />
                </div>
              </div>
              {/* Terms&condition */}
              <div className={`flex items-center gap-3 h-[2em]`}>
                <CheckBox
                  checked={check ? true : false}
                  handleBoxChange={(e) => handleChecking(e)}
                />
                <p onClick={() => setCheck(!check)} className={`flex h-full items-center cursor-pointer text-Grey-900 font-[Roboto-Regular] font-normal text-sm`}> I agree to the
                  <span className={`text-Active-100 pl-1 font-bold`}>
                    Terms and Conditions
                  </span>
                </p>
              </div>

              {/* Register&ContinueButton */}
              <div className={`flex mt-5`}>
                <ButtonField
                  buttonextracls={`${check === true ? `bg-skyblue-6 cursor-pointer` : `bg-gray-400`}  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-56 h-12`}
                  imgtext={`justify-between w-40`}
                  buttonName="Register and continue >"
                  buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={check === true ? false : true}
                />
                
                  <ButtonField
                    buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                    buttonName="Cancel"
                    buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                    type="submit"
                    onClick={props.cancelclick}
                  />
                  
              </div>

              {props.totalVendorCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.totalVendorCreateMsg?.message}</p> : null}

            </form>
          </div>


          {/* middleside end*/}



        </div>
        :
        <CreateBusinessAddressByAdmin
          businessAddAddressByAdmin={props.businessAddAddressByAdmin}
          editVendorFormData={props.editVendorFormData}
          editbusinessAddressDetails={props.editbusinessAddressDetails}
          doUploadedMedia={props.doUploadedMedia}
          doUploadedLogo={props.doUploadedLogo}
          // doUploadedMultipleImages={props.doUploadedMultipleImages}
          typecheck={typecheck}
          cancelAddressclick={props.cancelAddressclick}
          multipleMediaApiCall={props.multipleMediaApiCall}
        />
      }
    </>)
}

export default AddVendorFromForAdmin