import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ButtonField from '../../../Common/FormFields/ButtonField';
import { ForAdmin } from '../../../Containers/Admin/Hooks';
import ApproveMediaModal from '../../ApproveMedia/ApproveMediaModal';
import { Tooltip } from '@mui/material';
import { doGetApiCall } from "../../../Utils/ApiConfig"

const ApproveMediaTable = () => {
    const approveMedialist = useSelector((state) => state?.approveMediaList?.approveMediaList);
    const { approveUser } = ForAdmin();
    const [selectedItem, setSelectedItem] = useState(null);


    const handleRowClick = async (item) => {
        if (item?.reserveLogoId) {
            try {
                const getReLogo = await getReserveLogo(item?.reserveLogoId); // Properly await the result
                ;
                const updatedItem = { ...item, reserveLogo: getReLogo };
                setSelectedItem(updatedItem);
            } catch (error) {
                console.error("Error fetching reserve logo:", error);
                setSelectedItem({ ...item, reserveLogo: null });

            }
        } else {
            setSelectedItem(item);
        }
    };

    const getReserveLogo = async (id) => {
        const data = {
            url: `${process.env.REACT_APP_DG_BASE_URL}/business/reserve/logo/get/${id}`,
        };
        try {
            const res = await doGetApiCall(data); // Await the API call
            if (!res.error && res.status === 200) {
                return res?.result; // Return the fetched result
            } else {
                console.error("API returned an error or unexpected status:", res);
                return null;
            }
        } catch (err) {
            console.error("Error during API call:", err);
            return null;
        }
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
    };

    return (
        <div className="w-full border-[#EBEBEB] overflow-x-auto">
            <table className="table-auto w-[110vw]">
                <thead className="bg-white sticky top-0 z-10">
                    <tr className="h-10">
                        <th className="pl-5 w-[15%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]">User Name</th>
                        <th className="pl-5 w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]">Unique Id</th>
                        <th className="pl-5 w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]">Phone No.</th>
                        <th className="w-[8%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]">Email id</th>
                        <th className="w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]">Address</th>
                        <th className="pl-5 w-[10%] text-[#585F71] text-left pr-8 text-base font-normal font-[Roboto-Regular]">Governorate</th>
                        <th className="w-[10%] text-[#585F71] text-left pr-8 text-base font-normal font-[Roboto-Regular]">Date</th>
                        <th className="w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] pr-6">Place</th>
                        <th className="w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] pr-6">Active/Inactive</th>
                        <th className="w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] pr-6"></th>
                    </tr>
                </thead>
                <tbody>
                    {approveMedialist?.length > 0 ? (
                        approveMedialist?.map((item, idx) => (
                            <tr
                                key={idx}
                                className="drop-shadow-md border-t-2 border-b-2 border-[#EBEBEB] cursor-pointer hover:bg-gray-100"
                            >
                                <td className="text-left text-[#1F2533] text-sm py-6 pl-5 font-medium font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.user?.name}</td>
                                <td className="text-left text-[#3677B1] text-[14px] pl-5 font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.user?.id}</td>
                                <td className="text-left pr-8 text-[#1F2533] text-[14px] pl-5 font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.user?.phoneNo}</td>
                                <td className="text-left pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.user?.email}</td>
                                <Tooltip placement='bottom-start' title={item?.address}>
                                    <td className="text-left pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular] truncate" onClick={() => handleRowClick(item)}>{item?.address}</td>
                                </Tooltip>
                                <td className="text-left pr-8 text-[#1F2533] text-[14px] pl-5 font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.governorate}</td>
                                <td className="text-left pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{moment(item?.user?.updatedAt).format("YYYY-MM-DD")}</td>
                                <Tooltip placement='bottom-start' title={item?.places}>
                                    <td className="text-left pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular] truncate" onClick={() => handleRowClick(item)}>{item?.places}</td>
                                </Tooltip>
                                <td className="text-left pr-8 text-[#1F2533] text-[14px] font-normal font-[Roboto-Regular]" onClick={() => handleRowClick(item)}>{item?.user?.status}</td>
                                <td className='text-left pr-6 text-[14px] text-[#1F2533] font-normal font-[Roboto-Regular] pl-8'>
                                    <div className='flex justify-between'>
                                        <div className='flex items-center gap-2'>
                                            <div className='flex'>
                                                <ButtonField
                                                    buttonName="Approved"
                                                    onClick={() => approveUser(item?.id, 'approved')}
                                                    buttonextracls="!px-4 !py-2 w-9 h-7 capitalize !text-white bg-skyblue-6 text-xs hover:bg-deepBlue-2 hover:drop-shadow-xl mr-2"
                                                />
                                                <ButtonField
                                                    buttonName="Decline"
                                                    onClick={() => approveUser(item?.id, 'rejected')}
                                                    buttonextracls="!px-4 !py-2 w-9 h-7 mr-2 capitalize !text-white bg-red-500 text-xs hover:bg-red-800 hover:drop-shadow-xl"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9" className="text-center py-4 text-[#585F71] text-base font-normal font-[Roboto-Regular]">
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Modal */}
            <ApproveMediaModal
                title="Approve Media Details"
                isOpen={!!selectedItem}
                onClose={handleCloseModal}
                reserveImages={selectedItem?.reserveImages && selectedItem.reserveImages?.length > 0 ? selectedItem.reserveImages : []}
                thumbnail={selectedItem?.thumbnail && selectedItem.thumbnail?.length > 0 ? selectedItem.thumbnail : []}
                logo={selectedItem?.logo}
                reserveLogo={selectedItem?.reserveLogo}
                footerActions={[
                    {
                        label: 'Approve',
                        onClick: () => approveUser(selectedItem?.id, 'approved'),
                        classes: 'px-4 py-2 text-white rounded bg-skyblue-6 hover:bg-deepBlue-2 hover:drop-shadow-xl',
                    },
                    {
                        label: 'Decline',
                        onClick: () => approveUser(selectedItem?.id, 'rejected'),
                        classes: 'px-4 py-2 text-white rounded bg-red-500 hover:bg-red-800 hover:drop-shadow-xl',
                    },
                ]}
                details={{
                    'Business Name': selectedItem?.name,
                    ' Business Timing': `${selectedItem?.startTime} to ${selectedItem?.endTime}`,
                    'Display Name': selectedItem?.displayName,
                    Governorate: selectedItem?.governorate,
                    Email: selectedItem?.user?.email,
                    'Vendor Name': selectedItem?.user?.name,
                    'Unique Id': selectedItem?.user?.uniqueId,
                    Phone: selectedItem?.user?.phoneNo
                }}
            >

            </ApproveMediaModal>

        </div>
    );
};

export default ApproveMediaTable;
