import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Likeicon from "../../Images/Vendor/likeicon.svg"
const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '  #ff4d4d',
    },
    '& .MuiRating-iconHover': {
        color: '  #ff4d4d',
    },
});

export default function LikeIcon(props) {
    return (
        <Box
            sx={{
                '& > legend': { mt: 2 },
                bgcolor:"rgba(0, 0, 0, 0.25)",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                width:"35px",
                height:"35px",
                borderRadius:"50%",
            }}
        >
            <StyledRating
                max={1}
                name={props.name}
                defaultValue={0}
                size={props.size}
                onChange={() =>
                    props.onChange()
                }
                value={props.favValue}
                disabled={props.disabled}
                // getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={
                    <img src={Likeicon} alt="Likeicon" />
                }
            />
        </Box>
    );
}
LikeIcon.propTypes = {
    name: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func
};