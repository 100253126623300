import React, { useEffect, useState } from 'react'
import UserMainPages from '../../../Components/User/UserPages'
import LandingHeader from '../../../Common/Layout/LandingHeader/LandingHeader'
import { UserAuthHooks } from '../Auth/Hooks'
import { useDispatch, useSelector } from 'react-redux'
import { ForUser } from './Hooks'
import { ForNotification } from '../../Notification/Hooks'
import { ForVendor } from '../../VendorDetailPage/Hooks'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import { ForUploadMediaHooks } from '../../UploadMedia/Hooks'
import Footer from '../../../Common/Layout/Footer/Footer'
import { ForSignup } from '../../Authentication/Signup/Hook'
import { useNavigate } from 'react-router-dom'
import { useJwt } from 'react-jwt'
import BannerAnnouncement from '../../../Components/LandingPage/BannerAnnouncement'
import { ForAdmin } from '../../Admin/Hooks'
import getLocalStorageData from '../../../Utils/GetLocalStoregeData'
import { getAnnouncement } from '../../Admin/reducer/SettingReducer'


const UserIndex = () => {
    const { getUser } = UserAuthHooks()
    const { toggleUserMyRequirementForm,
        showMyReqrFormUser,
        myRequirementCreateUser,
        getAllRequirementsUser,
        getUserRequirementsByUserId,
        goToUserMyReqrLiveDetails,
        showUserMyReqrLiveDetails,
        goBackFromMyReqrLiveDetails,
        saveReqData,
        loadingUser,
        currentUserRequirementPage, 
        setCurrentUserRequirementPage, 
        totalUserRequirementItems, 
        itemsPerPage
    } = ForUser()

    const { getAllAnnouncement } = ForAdmin()


    const [announceList, setList] = useState(null)



    const userData = useSelector((state) => state.users.userDetailsInUsers)
    const { showNotification, getNotification, handleshow, detailsPage, allnotification, readNotificationApi } = ForNotification()
    const { editVendorProfileDetailsApi, changePasswordForVendorApiCall, notification, messageClose, editUserProfile, handleUserProfileEdit, goBackFromUserProfileEdit, getChatsApiCAll, postChatApiCall, getChatbyChatIdApiCAll, getChatDetailsWithUserApiCAll, downloadPdfAttachments, downloadImage, chatMessageReadApi, loading, changePasswordLoader } = ForVendor()

    const { mapLocationTxt,
        setMapLocationTxt,
        mapLocation,
        handleClickLocation } = ForSignup()

    useEffect(() => {
        getNotification()
        getAllAnnouncement()
        // getChatsApiCAll()
    }, [])
    useEffect(() => {
        getUser()
    }, [])

    const dispatch = useDispatch()

    const announcementData = useSelector((state) => state.setting.announcement)

    useEffect(() => {
        if (announcementData.length > 0) {
            const announcementDataByRole = announcementData.filter((item) => item?.sentTo === 'user')
            if (announcementDataByRole && announcementDataByRole?.length > 0) {
                setList(announcementDataByRole)
            }
        }else{
          setList(null)
        }
    }, [announcementData?.length])


    const handleCloseAnnounce = () => {
      dispatch(getAnnouncement([]))
    }


    const chatArr = useSelector((state) => state.vendor.chats)
    const singleChatDetail = useSelector((state) => state.vendor.chatById)
    const singleChatWith = useSelector((state) => state.vendor.chatWithByUserId)
    const { multipleMediaApiCall } = ForUploadMediaHooks()

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }

    return (
        <div onClick={() => checkTokenExpiration()}>
            <div className='sticky top-0 z-50'>
                {/* <MainHeader /> */}
                <LandingHeader
                    searchBar={true}
                    goTomessage={true}
                    goToNotification={true}
                    border={true}
                    profile={true}
                    handleclickNotification={handleshow}
                    showNotification={showNotification}
                    detailsPage={detailsPage}
                    allnotification={allnotification}
                    readNotificationApi={readNotificationApi}
                />

                <BannerAnnouncement isOpen={!!announceList} handleCloseAnc={handleCloseAnnounce} announceList={announceList} />
            </div>

            <UserMainPages
                userData={userData}
                toggleUserMyRequirementForm={toggleUserMyRequirementForm}
                showMyReqrFormUser={showMyReqrFormUser}
                myRequirementCreateUser={myRequirementCreateUser}
                getAllRequirementsUser={getAllRequirementsUser}
                getUserRequirementsByUserId={getUserRequirementsByUserId}
                goToUserMyReqrLiveDetails={goToUserMyReqrLiveDetails}
                showUserMyReqrLiveDetails={showUserMyReqrLiveDetails}
                goBackFromMyReqrLiveDetails={goBackFromMyReqrLiveDetails}
                saveReqData={saveReqData}
                handleUserProfileEdit={handleUserProfileEdit}
                editUserProfile={editUserProfile}
                goBackFromUserProfileEdit={goBackFromUserProfileEdit}
                editVendorProfileDetailsApi={editVendorProfileDetailsApi}
                changePasswordForVendorApiCall={changePasswordForVendorApiCall}
                getChatbyChatIdApiCAll={getChatbyChatIdApiCAll}
                postChatApiCall={postChatApiCall}
                getChatDetailsWithUserApiCAll={getChatDetailsWithUserApiCAll}
                chatArr={chatArr}
                singleChatDetail={singleChatDetail}
                singleChatWith={singleChatWith}
                multipleMediaApiCall={multipleMediaApiCall}
                getChatsApiCAll={getChatsApiCAll}
                downloadPdfAttachments={downloadPdfAttachments}
                downloadImage={downloadImage}
                chatMessageReadApi={chatMessageReadApi}
                loading={loading}
                mapLocationTxt={mapLocationTxt}
                setMapLocationTxt={setMapLocationTxt}
                mapLocation={mapLocation}
                handleClickLocation={handleClickLocation}
                changePasswordLoader={changePasswordLoader}
                loadingUser={loadingUser}
                currentUserRequirementPage={currentUserRequirementPage} 
                setCurrentUserRequirementPage={setCurrentUserRequirementPage} 
                totalUserRequirementItems={totalUserRequirementItems} 
                itemsPerPage={itemsPerPage}
            />
            <Footer />

            <SnackBarComponent
                messageOpen={notification?.open}
                messageClose={messageClose}
                notificationText={notification?.message}
                subText={notification?.subText}
                alertType={notification?.alertType}
                borderClass={notification?.borderClass}
            />
        </div>
    )
}

export default UserIndex