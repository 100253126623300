import React from 'react';
import CloseIcon from "@mui/icons-material/Close";

// Helper Components
const ImageGrid = ({ images, title }) => (
    <div className="w-1/3">
        <h3 className="text-lg font-semibold mb-4">{title}</h3>
        {images?.length > 0 ? (
            <div className="grid grid-cols-2 gap-2">
                {images.map((image, index) =>
                    image?.mimetype?.includes('image') ? (
                        <img
                            key={index}
                            crossOrigin="anonymous"
                            src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${image?.key}&height=75&width=100`}
                            alt={`${title}-${index}`}
                            className="w-full h-24 object-cover rounded border-2 border-gray-300"
                        />
                    ) : image?.mimetype?.includes('video') ? (
                        <video
                            crossOrigin="anonymous"
                            src={image?.key && `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${image?.key}&height=75&width=100`}
                            width="100"
                            height="75"
                            controls
                            autoPlay
                            muted
                        />
                    ) : null
                )}
            </div>
        ) : (
            <p className="text-sm text-gray-500">No images available</p>
        )}
    </div>
);

const LogoImage = ({ logo, title }) => (
    <div className="w-1/2">
        <h3 className="text-lg font-semibold mb-4">{title}</h3>
        {logo ? (
            <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logo?.key}&height=100&width=100`}
                alt="Logo"
                className="object-cover rounded-full w-50 h-50 border-2 border-gray-300"
            />
        ) : (
            <p className="text-sm text-gray-500">No logo available</p>
        )}
    </div>
);

const ApproveMediaModal = ({
    title = 'Approve Media',
    details = {}, // Object containing additional details
    isOpen = false,
    onClose,
    reserveImages = [],
    thumbnail = [],
    logo,
    reserveLogo,
    footerActions = [],
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-[60%] h-[90%] p-6 rounded-lg shadow-lg overflow-hidden">
                {/* Top Section: Title and Details */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <CloseIcon
                        onClick={onClose}
                        className="cursor-pointer text-slate-500"
                    />
                </div>

                {/* Scrollable Content */}
                <div className="h-[85%] overflow-y-auto">
                    {/* Details Section */}
                    <div className="mb-6 mx-4">
                        {Object.keys(details).length > 0 ? (
                            <ul className="text-sm text-gray-700">
                                {Object.entries(details).map(([key, value], index) => (
                                    <li key={index} className="flex justify-between border-b py-2">
                                        <span className="font-bold text-[1rem] capitalize">{key}:</span>
                                        <span className='text-sm font-semibold'>{value}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-500">No additional details available</p>
                        )}
                    </div>
                    <div className="flex justify-between gap-3 mb-4 mx-4">
                        <LogoImage logo={logo} title="Logo" />
                        <LogoImage logo={reserveLogo} title="Updated Logo" />
                    </div>
                    {/* Middle Section: Reserve Images, Thumbnail, and Logo */}
                    <div className="flex justify-between gap-3 mb-4 mx-4">
                        <ImageGrid images={thumbnail} title="Thumbnails" />
                        <ImageGrid images={reserveImages} title="Updated Thumbnails" />
                    </div>
                </div>

                {/* Footer Section */}
                <div className="mt-4 mx-4">
                    <div className="flex justify-end gap-4">
                        {footerActions.map((action, index) => (
                            <button
                                key={index}
                                onClick={() => { action.onClick(); onClose() }}
                                className={action.classes || 'px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700'}
                            >
                                {action.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApproveMediaModal;
