import React from 'react'
import { Box } from '@mui/material'
import { adminSideMenu } from './SettingSidebarData'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const SettingSidebar = () => {
    const location = useLocation();

    const activeStyle = {
        backgroundColor: "#E8EFF5",
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
        margin: '0px',
        padding: '0px',
        paddingRight: '0px',
        borderRadius: '1px 1px 0px 0px',
        color: '#585F71',
        // filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(0deg)'
    };

    const style = {
        color: '#585F71',
        fontFamily: 'Roboto-Regular',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '15px',
    }

   

    return (
        <div className={`bg-settingNavGrey w-full min-h-full`}>
            <Box className={` w-full h-full drop-shadow-md`}>
                {
                    adminSideMenu && adminSideMenu?.length &&
                    adminSideMenu?.map((item, index) => {
                        return (
                            <nav key={index}>
                                <List className={`navItems`} sx={{ marginBottom: "0.5rem", marginTop: "0.5rem", paddingTop: 0, paddingBottom: "2px" }}>
                                    <ListItem disablePadding >
                                        <NavLink
                                            to={item?.link}
                                            style={
                                                ({ isActive }) => isActive ? activeStyle : style
                                            }
                                            className={`w-full`}
                                        >
                                            <ListItemButton className='py-4'>
                                                <ListItemIcon>
                                                    <img src={location?.pathname === item?.link ? item?.colorIcon : item?.icon} alt="menu" className="menuImg" />
                                                </ListItemIcon>
                                                <div onClick={() => { item?.onClick() }}>

                                                <p className={`menuTxt`}>{item?.name}</p>
                                                </div>
                                            </ListItemButton>
                                        </NavLink>
                                    </ListItem>
                                </List>
                            </nav>
                        )
                    })
                }
            </Box>
        </div>

    )
}

export default SettingSidebar