import { Autocomplete, Checkbox, TextField } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const MultipleSelect = (props) => {

  const {t} = useTranslation()
  // console.log(props, "props $4")

  return (
    // <Autocomplete
    //   className={props.autocompleteExtraCls}
    //   multiple
    //   size={props.size}
    //   id={props.id}
    //   onChange={props.onChange}
    //   value={props.value}
    //   options={props.autocompleteArray}
    //   disableCloseOnSelect
    //   inputValue={props.inputValue}
    //   onInputChange={props.onInputChange}
    //   getOptionLabel={(option) => option.name}
    //   renderOption={(props, option, { selected }) => (
    //     <li {...props}>
    //       {props.slelectedCheck &&
    //         <Checkbox
    //           style={{ marginRight: 8 }}
    //           checked={selected}
    //         />}
    //       {option.name}
    //     </li>
    //   )}
    //   style={{ width: '100%' }}
    //   renderInput={(params) => (
    //     <TextField {...params} label={props.label} placeholder={props.placeholder} />
    //   )}
    // />
    <Autocomplete
      className={props.autocompleteExtraCls}
      disableClearable
      multiple={props.multiple}
      id={props.id}
      name={props.name}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      options={props.autocompleteArray}
      isOptionEqualToValue={props.isOptionEqualToValue}
      getOptionLabel={(option) => option.name}
      label={props.label}
      renderInput={(params) => (
        <TextField {...params} label={t(props.label)} placeholder={t(props.placeholder)}/>

      )}

      popupIcon={<KeyboardArrowDownIcon />} 
    />
  )
}

// eslint-disable-next-line react/no-typos
MultipleSelect.PropTypes = {
  autocompleteArray: PropTypes.array,
  autocompleteExtraCls: PropTypes.string,
  id: PropTypes.string,
  slelectedCheck: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
  multiple: PropTypes.bool
}

export default MultipleSelect