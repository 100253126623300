import React from 'react'
import CommonAboutus from '../../Common/Layout/AboutUs/CommonAboutus'

const Aboutus = () => {
  return (
    <div>
      <CommonAboutus/>
    </div>
  )
}

export default Aboutus
