import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  hyperlinks: [],
  subAdmin: [],
  announcement:[],
  loading: false,
  error: false,
};

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        getHyperlinks: (state, action)=>{
            state.hyperlinks = action.payload;
            state.loading = true;
            state.error = false;
        },
        getSubAdmin: (state, action)=>{
            state.subAdmin = action.payload;
            state.loading = false;
            state.error = false;
        },
        getAnnouncement: (state, action)=>{
            state.announcement = action.payload;
            state.loading = false;
            state.error = false;
        },
    }
},


)

export const {getHyperlinks, getSubAdmin, getAnnouncement} = settingSlice.actions;
export default settingSlice.reducer;