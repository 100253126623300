import { useEffect } from 'react';
import TabComponent from '../../../Common/UiComponents/TabComponent'
import AddOfferForm from './AddOfferForm'
import NewServiceFormForVendor from './NewServiceFormForVendor'
import VendorServiceOfferActive from './VendorServiceOfferActive'
import VendorServiceOfferPending from './VendorServiceOfferPending'
import { useSelector } from 'react-redux'
import { isAccess } from "../../../Utils/isAccess"
import VendorServiceOfferDenied from './VendorServiceOfferDenied';
import { el } from 'date-fns/locale';

const VendorserviceOfferpage = (props) => {
  const subscriptionData = useSelector((state) => state?.subscriptionDetails?.subscriptionDetail)
  useEffect(() => {
    if (props.value === '0') {
      props.getServiceByBusiness('active');
    } else if (props.value === '1') {
      props.getServiceByBusiness('pending');
    }
    else if (props.value === '2') {
      props.getServiceByBusiness('denied');
    }
    else {
      console.log("else called")
    }
  }, [props.value])

  useEffect(() => {
    props.getCategoryInVendor()
  }, [])

  const categories = [
    {
      label: "active",
      value: "0",
      backgroundcolor: "bg-[#F7F7F7]",
      content: <VendorServiceOfferActive
        userData={props.userData}
        toggleAddOffer={props.toggleAddOffer}
        deletePendingServices={props.deletePendingServices}
      />
    },
    {
      label: "pending",
      value: "1",
      backgroundcolor: "bg-white",
      content: <VendorServiceOfferPending
        toggleAddOffer={props.toggleAddOffer}
        selectedService={props.selectedService}
        userData={props.userData}
        deletePendingServices={props.deletePendingServices}
        editServiceForm={props.editServiceForm}
        editServiceData={props.editServiceData}
        subscriptionValid={isAccess("offer", "add", subscriptionData)}
      />
    },
    {
      label: "denied",
      value: "2",
      backgroundcolor: "bg-white",
      content: <VendorServiceOfferDenied
        toggleAddOffer={props.toggleAddOffer}
        selectedService={props.selectedService}
        userData={props.userData}
        deletePendingServices={props.deletePendingServices}
        editServiceForm={props.editServiceForm}
        editServiceData={props.editServiceData}
        subscriptionValid={isAccess("offer", "add", subscriptionData)}
      />
    },
  ]
  return (
    <div className={`bg-white mt-3`}>
      <div>
        {props.showNewServiceForm ?
          (
            <NewServiceFormForVendor
              createVendorPostService={props.createVendorPostService}
              getAllPostedService={props.getAllPostedService}
              goBackToActiveServiceOfferedTab={props.goBackToActiveServiceOfferedTab}
              editServiceData={props.editServiceData}
              editServices={props.editServices}
              sendData={props.sendData}
              doUploadedMedia={props.doUploadedMedia}
              uploadData={props.uploadData}
              multipleMediaApiCall={props.multipleMediaApiCall}
              serviceSubmitError={props.serviceSubmitError}
              categoryArr={props.categoryArr}
              addCategoryId={props.addCategoryId}
              addSubCategoryId={props.addSubCategoryId}
              loading={props.loading}
              serviceSubmitErrorMsg={props.serviceSubmitErrorMsg}
            />
          ) : (
            props.showAddOfferForm ?
              <AddOfferForm
                goBackToPendingServiceOfferedTab={props.goBackToPendingServiceOfferedTab}
                createNewOffers={props.createNewOffers}
                submitError={props.submitError}
                loading={props.loading}
              />
              :
              <TabComponent
                mappingname={categories}
                button={true}
                buttonextracls={`${isAccess("service", "add", subscriptionData) ? "bg-[#676767]" : "bg-skyblue-6"}  text-white rounded text-sm mt-2 mr-4 capitalize`}
                buttonname="addNew"
                onClick={props.toggleNewService}
                value={props.value}
                setValue={props.setValue}
                buttonSubsMessage={`You've reached your subscription limit. Upgrade your subscription, please.`}
                buttonTooltip={"Add New Offer"}
                subscriptionValid={isAccess("service", "add", subscriptionData)}
                subscriptionData={subscriptionData?.subscription}
              />
          )
        }

      </div>
    </div>
  )
}

export default VendorserviceOfferpage