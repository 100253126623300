import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "@mui/material";

const PaginationComponent = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  siblingCount = 1,
  boundaryCount = 1,
  variant = "outlined",
  color = "primary",
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="flex justify-center items-center my-4">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => onPageChange(page)}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        variant={variant}
        color={color}
      />
    </div>
  );
};

PaginationComponent.propTypes = {
  totalItems: PropTypes.number.isRequired, // Total number of items
  itemsPerPage: PropTypes.number.isRequired, // Items to display per page
  currentPage: PropTypes.number.isRequired, // Current active page
  onPageChange: PropTypes.func.isRequired, // Function to handle page change
  siblingCount: PropTypes.number, // Number of sibling pages to show
  boundaryCount: PropTypes.number, // Number of boundary pages to show
  variant: PropTypes.string, // Pagination variant: "text" or "outlined"
  color: PropTypes.string, // Color of pagination: "primary", "secondary", etc.
};

export default PaginationComponent;
