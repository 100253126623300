import React from 'react'
import facebook from "../../../Images/SocialMedia/facebook.svg"
import twitter from "../../../Images/SocialMedia/twitter.svg"
import instagram from "../../../Images/SocialMedia/instagram.svg"
import playstore from "../../../Images/Vendor/playstore.svg"
import appstore from "../../../Images/Vendor/appstore.svg"
import footerLogo from '../../../Images/CommonImages/exploreFooterLogo.svg'
import text from '../../Languages/en_US.json'
import { useTranslation } from 'react-i18next'
import footerStyles from './FooterStyles.module.css'
import { Link } from 'react-router-dom'

const Footer = () => {

    // Use the useTranslation hook inside your functional components to access the translation function or i18n instance.
    const { t, i18n } = useTranslation();

    // func for changing language
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng.target.value);
    };

    return (
        <>
            <div className={`flex w-full py-[2.5%] flex-wrap h-fit px-[4%] justify-between bg-[#0D074D] text-white`}>
                {/* leftside */}
                <div className={`flex flex-col text-left justify-between w-[40%]`}>
                    <div className={`flex flex-col gap-5`}>
                        <img src={footerLogo} alt={t("explore")} className={`w-[8em]`} />
                        <p className={`text-xs text-deepgrey-2 leading-5 font-normal font-[Roboto-Regular] ${footerStyles.footerResponsive}`}>{t("digidialssubtext")}</p>
                    </div>
                    <p className={`text-xs text-deepgrey-2 leading-6 font-normal font-[Roboto-Medium]`}>{t("allrightsreserved")}</p>
                </div>
                {/* middleside */}
                <div className="flex flex-col text-left pl-[1%]">
                    <p className="text-xs text-white leading-6 font-normal pb-1 font-[Roboto-Regular]">
                        {t("quicklinks")}
                    </p>
                    <div className="flex flex-col text-left justify-between h-32">
                        <Link to="/aboutus">
                            <p className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer font-[Roboto-Regular]">
                                {t("aboutus")}
                            </p>
                        </Link>
                        <Link to="/faq">
                            <p className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer font-[Roboto-Regular]">
                                {t("faqs")}
                            </p>
                        </Link>
                        <Link to="/privacypolicy">
                            <p className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer font-[Roboto-Regular]">
                                {t("privacypolicy")}
                            </p>
                        </Link>
                        <Link to="/termsandconditions">
                            <p className="text-xs text-deepgrey-2 leading-5 font-normal cursor-pointer font-[Roboto-Regular]">
                                {t("termscondition")}
                            </p>
                        </Link>
                    </div>
                </div>
                {/* middleside */}
                <div className={`text-left flex flex-col justify-between h-32`}>
                    <p className={`text-xs text-white leading-6 font-normal font-[Roboto-Regular]`}>{t("followuson")}</p>
                    <div className=' flex  items-center cursor-pointer pt-8 md:pt-1'>

                        <a href="/" target='_blank' className='rounded-full py-1 w-8 flex items-center justify-center h-8  duration-500 bg-blue-6 mr-4' >
                            <img src={facebook} alt="facebook" height={10} width={10} />
                        </a>
                        <a href="/" target='_blank' className='rounded-full py-1 w-8 flex items-center justify-center h-8  duration-500 bg-blue-6 mr-4 '>
                            <img src={instagram} alt="linkedin" height={16} width={16} />
                        </a>
                        <a href="/" target='_blank' className='rounded-full py-1 w-8 flex items-center justify-center h-8  duration-500 bg-blue-6 '>
                            <img src={twitter} alt="linkedin" height={16} width={16} />
                        </a>
                    </div>

                    <div className={`flex`}>

                        <img src={playstore} alt="playstore" height={100} width={100} className={`mr-2`} />
                        <img src={appstore} alt="appstore" height={100} width={100} />

                    </div>
                </div>
                {/* rightside */}
                <div className={`flex flex-col justify-between h-16 pr-[2%]`}>
                    <button className={`text-xs text-white leading-6 font-normal cursor-pointer font-[Roboto-Regular]`} onClick={changeLanguage} value="en_US">{text.English}</button>
                    <button className={`text-xs text-white leading-6 font-normal cursor-pointer font-[Roboto-Regular]`} onClick={changeLanguage} value="hin">हिंदी</button>
                    <button className={`text-xs text-white leading-6 font-normal cursor-pointer font-[Roboto-Medium]`} onClick={changeLanguage} value="ar">عربي</button>
                </div>

            </div>
        </>
    )
}

export default Footer