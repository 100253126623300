import React, { useEffect } from 'react'
import AdminIndex from '../../Components/Admin'
import { useJwt } from "react-jwt";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ForAdmin } from './Hooks';

const AdminMain = () => {
    const navigate = useNavigate()
    const userId = localStorage?.getItem('userId')

    const { getTotalUserDetailsApi } = ForAdmin()

    const selectedUserId = useParams()
    const pathLocation = useLocation()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (!token || isExpired) {
            localStorage.clear();
            sessionStorage.clear();
            navigate('/login');
        }
    }

    useEffect(() => {
        checkTokenExpiration();
    }, [token])

    useEffect(() => {
        if (pathLocation?.pathname !== `/admin/totalUsers/${selectedUserId?.id}`) {
            getTotalUserDetailsApi(userId)
        }
    }, [])

    return (
        <div onClick={() => checkTokenExpiration()}>
            <AdminIndex />
        </div>
    )
}

export default AdminMain;