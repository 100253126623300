import dashBlack from "../../../Images/adminSideBar/dashBlack.svg"
import userBlack from "../../../Images/adminSideBar/userBlack.svg"
import vendorBlack from "../../../Images/adminSideBar/vendorBlack.svg"
import subBlack from "../../../Images/adminSideBar/subBlack.svg"
import categoryBlack from "../../../Images/adminSideBar/categoryBlack.svg"
import supportBlack from "../../../Images/adminSideBar/supportBlack.svg"
import cmsBlack from "../../../Images/adminSideBar/cmsBlack.svg"
import settingBlack from "../../../Images/adminSideBar/settingBlack.svg"
import dashColor from "../../../Images/adminSideBar/dashColor.svg"
import userColor from "../../../Images/adminSideBar/userColor.svg"
import vendorColor from "../../../Images/adminSideBar/vendorColor.svg"
import subColor from "../../../Images/adminSideBar/subColor.svg"
import categoryColor from "../../../Images/adminSideBar/categoryColor.svg"
import supportColor from "../../../Images/adminSideBar/supportColor.svg"
import cmsColor from "../../../Images/adminSideBar/cmsColor.svg"
import settingColor from "../../../Images/adminSideBar/settingColor.svg"
import Plans_default from "../../../Images/adminSideBar/Plans_default.svg"
import Plans_Selected from "../../../Images/adminSideBar/Plans_Selected.svg"
import Subscription_default from "../../../Images/adminSideBar/Subscription_default.svg"
import Subscription_selected from "../../../Images/adminSideBar/Subscription_selected.svg"
import approve_media from '../../../Images/adminSideBar/approve_media.svg'
import approve_media_color from '../../../Images/adminSideBar/approve_media_color.svg'
import text from "../../../Common/Languages/en_US.json"

export const adminSideMenu = [
    { name: text.dashboard, icon: dashBlack, link: '/admin/dashboard' ,colorIcon: dashColor},
    { name: text.totalusers, icon: userBlack, link: '/admin/totalUsers', colorIcon: userColor },
    { name: text.totalvendors, icon: vendorBlack, link: '/admin/totalVendors' , colorIcon: vendorColor},
    { name: text.subscriptionList, icon: Subscription_default, link: '/admin/subscription' , colorIcon: Subscription_selected},
    { name: text.transactionList, icon: subBlack, link: '/admin/transaction' , colorIcon: subColor},
    { name: text.plans, icon: Plans_default, link: '/admin/subscriptionplans', colorIcon: Plans_Selected },
    { name: text.categories, icon: categoryBlack, link: '/admin/categories' , colorIcon: categoryColor},
    { name: text.approveMedia, icon: approve_media, link: '/admin/approvemedia', colorIcon: approve_media_color },
    { name: text.support, icon: supportBlack, link: '/admin/support' , colorIcon: supportColor},
    { name: text.cmspages, icon: cmsBlack, link: '/admin/cmspages' , colorIcon: cmsColor},
    { name: text.settings, icon: settingBlack, link: '/admin/settings' , colorIcon: settingColor},
]

