import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useSelector } from 'react-redux';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AdminBarChart = () => {
    const mostViewedVendorsData = useSelector((state) => state.dashboard.mostViews)
    const mostViewedVendorsDataNotNull = mostViewedVendorsData?.filter((item) => item?.user !== null)

    const totalUsers = mostViewedVendorsDataNotNull?.length || 0;
    const expiredUsers = mostViewedVendorsDataNotNull?.filter((item) => item?.expiredSubscriptionUsers).length || 0;
    const totalViewers = mostViewedVendorsDataNotNull?.reduce((sum, item) => sum + (item?.views || 0), 0);

    const data = {
        labels: ['Total Users', 'Expired Users', 'Total Viewers'],
        datasets: [
            {
                label: 'Users Overview',
                data: [totalUsers, expiredUsers, totalViewers],
                backgroundColor: [
                    'rgba(0, 186, 52, 0.6)',
                    'rgba(0, 133, 255, 0.6)',
                    'rgba(249, 134, 0, 0.6)',
                ],
                borderColor: [
                    'rgb(0, 186, 52)',
                    'rgb(0, 133, 255)',
                    'rgb(249, 134, 0)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                // text: 'Users Statistics',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="w-full py-6">
            <div
                style={{
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)',
                }}
                className="flex flex-col gap-4 px-10 py-6 rounded-lg bg-white"
            >
                {/* Add responsive container */}
                <div className="relative h-[300px] w-full">
                    <Bar data={data} options={options} />
                </div>
            </div>
        </div>
    );
};

export default AdminBarChart;
