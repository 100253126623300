import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    approveMediaList: [],
    loading: false,
    error: false,
}

export const approveMediaListSlice = createSlice({
    name: 'approveMediaList',
    initialState,
    reducers: {
        getApproveMediaList: (state, action) => {
            console.log(state, "state**")
            state.approveMediaList = action.payload;
            state.loading = false;
            state.error = false;
        }
    }
})

export const { getApproveMediaList } = approveMediaListSlice.actions;
export default approveMediaListSlice.reducer;