import { useState } from "react";
import { Checkbox, FormControlLabel, Button, TextField, ClickAwayListener } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ContactUsModal = ({ handleCloseModal, handleContactUs }) => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        termsAgreed: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        message: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });

        if (value.trim() !== "") {
            setErrors({
                ...errors,
                [name]: false,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {
            name: formData.name.trim() === "",
            email: formData.email.trim() === "",
            message: formData.message.trim() === "" || formData.message.trim().length < 20 || formData.message.trim().length > 500,
        };

        setErrors(newErrors);

        let payload = {
            name: formData?.name,
            email: formData?.email,
            query: formData?.message,
        };
        if (formData?.phone) {
            payload.phoneNo = formData?.phone;
        }


        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        handleContactUs(payload, setIsLoading, () => {
            setFormData({
                name: "",
                email: "",
                phone: "",
                message: "",
                termsAgreed: false,
            });
            setErrors({
                name: false,
                email: false,
                message: false,
            });
            handleCloseModal?.();
        });
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <ClickAwayListener onClickAway={handleCloseModal}>
                <div className="max-w-[600px] mx-auto p-6 bg-white shadow-lg rounded-md relative">
                    <CloseIcon onClick={handleCloseModal} className={`absolute right-3 top-3 cursor-pointer`} />
                    <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            className="mb-4"
                            error={errors.name}
                            helperText={errors.name ? "Name is required." : ""}
                        />

                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            type="email"
                            className="mb-4"
                            error={errors.email}
                            helperText={errors.email ? "Email is required." : ""}
                        />

                        <TextField
                            label="Phone (Optional)"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            type="tel"
                            className="mb-4"
                        />

                        <TextField
                            label="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={4}
                            className="mb-4"
                            error={errors.message}
                            helperText={errors.message ? "Message should be between 20 to 500 characters." : ""}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="termsAgreed"
                                    checked={formData.termsAgreed}
                                    onChange={handleChange}
                                />
                            }
                            label="I agree to the terms and conditions"
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="w-full bg-orange-600 hover:bg-orange-700 text-white py-2"
                            disabled={!formData?.termsAgreed}
                        >
                          {isLoading ? 'Please wait...' : 'Submit'}
                        </Button>
                    </form>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default ContactUsModal;
