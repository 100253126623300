

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import ButtonField from '../../../Common/FormFields/ButtonField';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import locationImage from "../../../Images/Setting/locationImg.svg"
import { useDispatch, useSelector } from 'react-redux';
import { getLocationReducer } from '../../../Containers/Authentication/Signup/reducer/VendorAccountReducer';
import UploadButton from '../../../Common/UiComponents/UploadButton';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer';
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
const UserProfileEdit = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit, reset, control, handleError, formState: { errors } } = useForm({
        defaultValues: {
            name: props.userData?.name ? props.userData?.name : '',
            location: props.userData?.location ? props.userData?.location : ''
        }
    });
    const onSubmit = (data) => {
        props.editVendorProfileDetailsApi(data)
        reset()
    }

    // useEffect(() => {
    //     if (props.userData?.location !== null) {
    //         dispatch(getLocationReducer(props.userData?.location))
    //     }
    // }, [])

    useEffect(() => {
        if (props.userData) {
            dispatch(setUploadedLogo(props.userData?.picture))
        }
    },[])
    const { logoUpload, doUploadedLogo } = ForUploadMediaHooks()
    return (
        <div className={`w-full flex lg:flex-row flex-col px-[5%] py-[2%] min-h-screen bg-white`}>

            <form onSubmit={handleSubmit(onSubmit, handleError, reset)} className={`w-full flex flex-col items-start h-[28em] `}>
                <div className='w-[15%] flex justify-between h-full flex-col '>
                            <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-center text-2xl w-[20em] mb-5`}>Upload Your Profile Image</p>
                            <div className={`relative`}>
                                {logoUpload &&
                                    (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                    <div className={`absolute -top-[0.85rem] left-[17rem] -right-4 cursor-pointer`}>
                                        <CloseIcon
                                            sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                            onClick={() => dispatch(setUploadedLogo({}))}
                                        />
                                    </div>
                                }
                                <UploadButton
                                    sendData={(data) => {
                                        if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                            doUploadedLogo(data)
                                        }
                                    }}
                                    extracls='flex justify-center items-center bg-white  items-center rounded-md w-[30em]'
                                    type='button'
                                    fileUploadIcon={
                                        logoUpload &&
                                            (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') && logoUpload?.key ?
                                            `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=20&width=auto` :
                                            fileUploadIcon
                                    }
                                    accept={{"image/jpeg": [".jpeg", ".jpg"],
                                        "image/png": [".png"]}}
                                    multiple={false}
                                    disabled={logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                        true : false
                                    }
                                />
                            </div>
                        </div>
                        
                {/* Name section */}
                <div className={`!w-[50%] my-2 userEditTextfield`}>
                    <Controller
                        name={"name"}
                        control={control}
                        rules={{
                            required: props.userData?.name ? false : true
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldInput
                                onlyValue
                                extracls={`h-11 text-sm `}
                                floatingLabel="Enter your name"
                                variant="outlined"
                                type="text"
                                onChange={onChange}
                                value={value}
                                // defaultValue={(props.userData?.name ? props.userData?.name : value)}
                            // disabled={props.userData?.name ? true : false}
                            />
                        )}
                    />
                    {errors.name && errors.name.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                </div>

                {/* Email section */}
                <div className={`!w-[50%] my-2 cursor-pointer userEditTextfield`} >
                    <Controller
                        name={"email"}
                        control={control}
                        rules={{
                            required: props.userData?.email ? false : true
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldInput
                                extracls={`h-11 text-sm !p-0 signUpEmailExtracls`}
                                floatingLabel="*Enter your email"
                                variant="outlined"
                                type="email"
                                onChange={onChange}
                                value={value}
                                defaultValue={(props.userData?.email ? props.userData?.email : value)}
                                disabled={props.userData?.email ? true : false}
                            />
                        )}
                    />
                </div>

                {/* Phone Number section */}
                <div className={`!w-[50%] my-2 userEditTextfield`}>
                    <Controller
                        name={"phoneNo"}
                        control={control}
                        rules={{
                            // pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                            maxLength: 10,
                            required: props.userData?.email ? false : true
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldInput
                                onlyValue
                                extracls={`w-full h-11 text-sm signUpPhoneExtracls`}
                                floatingLabel="Enter your Phone number"
                                variant="outlined"
                                type="number"
                                onChange={onChange}
                                value={value}
                                defaultValue={(props.userData?.phoneNo ? props.userData?.phoneNo : value)}
                                disabled={props.userData?.phoneNo ? true : false}
                            />
                        )}
                    />
                </div>

                {/* Location */}
                {/* <div className={`!w-[50%] my-2 userEditTextfield `}>
                    <Controller
                        name={"location"}
                        // defaultValue={props.mapLocationTxt}
                        control={control}
                        rules={{
                            // required: (locationSelector && locationSelector?.length > 0) ? false : true,
                            pattern: /^[ A-Za-z0-9_@./,#&+-]*$/,
                            minLength: 2,
                            maxLength: 100
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldInput
                                onlyValue
                                extracls={`text-sm `}
                                variant="outlined"
                                type="text"
                                id="location"
                                onChange={onChange}
                                value={locationSelector && locationSelector?.length > 0 ? locationSelector : value}
                                defaultValue={(props.userData?.location ? props.userData?.location : value)}
                                // defaultValue={locationSelector && locationSelector?.length > 0 ? locationSelector : value}
                                // disabled={props.mapLocationTxt ? true : false}
                                floatingLabel={'enterlocation'}
                                endAdornment={
                                    <div className={`flex justify-center items-center`}>
                                        {((locationSelector && locationSelector?.length > 0) || !props.userData?.location) &&
                                            <CloseIcon
                                                sx={{ fontSize: "20px", cursor: "pointer" }}
                                                onClick={() => dispatch(getLocationReducer('')) }
                                            />
                                        }
                                        {!value &&
                                            <img src={locationImage} alt="locationImage" className='cursor-pointer' onClick={() => props.handleClickLocation()} />
                                        }
                                    </div>
                                }

                            />
                        )}
                    />
                     <p className={`text-red-500 text-xs mt-2`}>Caution: Once you click on the location icon, the input field will be disabled and cannot be reversed back</p> 
                    <small className={`text-red-500 `}>
                        {errors?.location && errors?.location?.type === "required" && (<p>This field is required</p>)}
                    </small>
                </div> */}

                <div className={`flex mt-6`}>
                    <ButtonField
                        buttonextracls={`bg-skyblue-6 cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-32 h-12`}
                        imgtext={`justify-between`}
                        buttonName="Update"
                        buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        loading={props.loading}
                        disabled={props.loading === true ? true : false}
                    />
                    <ButtonField
                        buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                        buttonName="Cancel"
                        buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                        type="reset"
                        onClick={props.goBackFromUserProfileEdit}
                    />
                </div>
            </form>
        </div>
    )
}

export default UserProfileEdit