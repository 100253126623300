import React, { useState, useEffect, useRef } from 'react';
import "./index.css"
import emojisection from '../../../Images/profileDashboardIcons/emojisection.svg'
import { useLocation } from 'react-router-dom';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import { useSelector } from 'react-redux'
import socketConnection from '../../../Utils/socketConnection'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import chatStarterImg from '../../../Images/Vendor/chatStarterImg.png';
import { ForUser } from '../../../Containers/User/UserPages/Hooks';


const Chatbox = (props) => {
    const loctn = useLocation()
    const { getSupportUser } = ForUser();

    const { cleanupMedia } = ForUploadMediaHooks()

    const [clicked, setClicked] = useState(null)

    // For saving the data of a chat when any of the chats is clicked 
    const [saveAccData, setSaveAccData] = useState({})

    //useState for the input field where texts are written
    const [inputValue, setInputValue] = useState('');

    // this is for opening the chats 
    const [open, setOpen] = useState(false)
    const [supportData, setSupprotData] = useState(null);
    // const userId = parseInt(localStorage.getItem('userId'));

    const singleChatDetail = useSelector((state) => state.vendor.chatById)

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const dataSckt = socketConnection("message", props.getChatsApiCAll, fetchSupportData)
        // console.log(dataSckt, "dataSckt #")
    }, []);

    useEffect(() => {
        fetchSupportData();
    }, []);

    const fetchSupportData = async () => {
        const supportData = await getSupportUser();
        setSupprotData(supportData);
    };

    const handleclick = (data) => {
        setOpen(true)
        setClicked(data?.id)
        if (data?.message) {
            props.chatMessageReadApi(data?.id)
            const saveAccDetails = data?.participants?.filter((item) => item?.id !== parseInt(localStorage.getItem('userId')))
            setSaveAccData({
                logo: saveAccDetails[0]?.business[0]?.logo,
                name: saveAccDetails[0]?.business[0]?.name ? saveAccDetails[0]?.business[0]?.name : saveAccDetails[0]?.role === 'admin' ? 'Explore Support' : saveAccDetails[0]?.name,
                email: saveAccDetails[0]?.role === 'admin' ? 'support@explore.com' : saveAccDetails[0]?.email,
                phoneNo: saveAccDetails[0]?.phoneNo,
                userId: saveAccDetails[0]?.id
            })
            props.getChatbyChatIdApiCAll(data?.id)
            fetchSupportData()
            cleanupMedia()
        } else {
            setSaveAccData({
                logo: data?.logo ? data?.logo : data?.services ? data?.services?.business?.logo : data?.product && data?.product?.business?.logo,
                name: data?.name ? data?.name : data?.business ? data?.business?.name : data?.services ? data?.services?.business?.name : data?.product && data?.product?.business?.name,
                email: data?.user?.email,
                phoneNo: data?.phoneNo ?? data?.user?.phoneNo,
                userId: data?.userId ?? data?.user?.id

            })
            props.getChatbyChatIdApiCAll(null)
            cleanupMedia()
        }
    }

    // This useEffect is to check if there is chat with the selected person or not 
    useEffect(() => {
        if (loctn?.state && loctn?.state) {
            handleclick(loctn?.state)
        } else {
            console.log("data missing #")
        }
    }, [loctn?.state])


    // func for handling the input field 
    const handleInput = (e) => {
        if (e.target.value.trim().length > 0) {
            setInputValue(e.target.value);
        } else {
            setInputValue('');
        }
    };

    // func for onsubmit of the textfield 
    const handleInputSubmit = (userId) => {
        if (userId !== null) {
            props.postChatApiCall(inputValue, userId);
            setInputValue('')
            // cleanupMedia()
        } else {
            // props.postChatApiCall(inputValue, saveAccDetails[0]?.id, saveAccData?.id);
        }
    };
    useEffect(() => {
        const chatData = props.chatArr[0]?.participants.filter(participant => participant.id === saveAccData?.userId)
        if (chatData && chatData[0]?.id) {
            props.getChatbyChatIdApiCAll(props.chatArr[0]?.id)
            props.chatMessageReadApi(props.chatArr[0]?.id)
            fetchSupportData()
        }
    }, [])

    return (
        <div className={`flex bg-white h-[85vh] justify-between cssformessageSection mt-3 gap-2`}>
            <div className={`w-[35%] border-2 border-grey-400`}>
                <ChatList
                    chatArr={props.chatArr}
                    handleclick={handleclick}
                    clicked={clicked}
                    handleSearchChat={props.handleSearchChat}
                    supportData={supportData}
                />
            </div>

            {/* When one of the chats on the left side is clicked */}
            {open === true ?
                <div className={`w-[65%]`}>
                    <ChatWindow
                        saveAccData={saveAccData}
                        // saveAccDetails={saveAccDetails}
                        singleChatDetail={singleChatDetail}
                        // postChatApiCall={props.postChatApiCall}
                        handleInputSubmit={handleInputSubmit}
                        handleInput={handleInput}
                        inputValue={inputValue}
                        downloadPdfAttachments={props.downloadPdfAttachments}
                        downloadImage={props.downloadImage}
                        loading={props.loading}
                        // divRef={divRef}
                        // scrollToBottom={scrollToBottom}
                        getChatbyChatIdApiCAll={props?.getChatbyChatIdApiCAll}
                        chatMessageReadApi={props?.chatMessageReadApi}
                        chatArr={props.chatArr}
                        fetchSupportData={fetchSupportData}
                        getChatsApiCAll={props.getChatsApiCAll}
                    />
                </div>
                : <div className={`w-[65%]`}>
                    <img src={chatStarterImg} alt='chatStarterImg' />
                </div>
            }
        </div>
    );
};

export default Chatbox;