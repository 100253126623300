import React from 'react'
import CheckBox from '../../../Common/FormFields/CheckBox';
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import usericon from '../../../Images/adminTable/userAvatar.svg'
import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';


/**
 * @component MostViewedVendorTable
 * @description Users table in details
 * @param {*} props 
 */

const MostViewedVendorTable = () => {

    const mostViewedVendorsData = useSelector((state) => state.dashboard.mostViews)
    const mostViewedVendorsDataNotNull = mostViewedVendorsData?.filter((item) => item?.user !== null)
    // console.log(mostViewedVendorsDataNotNull, "mostViewedVendorsDataNotNull**")

    return (
        <div className={`w-full  border-2  rounded-b-md overflow-y-hidden hover:overflow-y-scroll h-[70vh] bg-white`}>

            <table className="table-auto w-full ">

                <thead className={`sticky top-0 z-50  border-b-2 border-[#ddd] bg-gray-200`}>
                    {/* <tr className=' w-full h-10 border-b-2 border-[#ddd]'>
                        <th className='whitespace-nowrap px-4 py-5 text-primeryColour-3 font-[Roboto-Regular]  text-[19px]'>{text.totalUsers}</th>
                    </tr> */}

                    <tr className='h-14'>
                        {/* <th className={`w-[5%] text-gray-3 text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox size={'small'} sx={{ color: '#00000057' }} />
                        </th> */}
                        <th className={`w-[25%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular] pl-6`}>{text.userName}</th>
                        <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>{text.categoryName}</th>
                        <th className={`w-[10%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>{text.totalViewers}</th>
                        <th className={`w-[25%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>{text.placeName}</th>
                        <th className={`w-[15%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>{text.subscriptionType}</th>
                        <th className={`w-[15%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]`}>{text.creationDate}</th>
                        {/* <th className={`w-[20%] text-[#585F71] text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>{text.action}</th> */}

                    </tr>
                </thead>
                <tbody>

                    {mostViewedVendorsDataNotNull && mostViewedVendorsDataNotNull?.length > 0 && mostViewedVendorsDataNotNull?.map((viewData, idx) => {
                        return (
                            <tr
                                key={idx}
                                className={`border-t-2 border-b-2 last:border-b-0`}>
                                {/* <td className={`text-left text-[14px] font-normal font-[SansRegular] px-[1%]`}>
                                    <CheckBox size={'small'} sx={{ color: '#00000057' }} />
                                </td> */}
                                <td className={`text-left text-deepgray-5 text-sm py-4 pl-6 font-[Roboto-Regular] w-[25%]`}>
                                    <div className='flex items-center'>
                                        <img src={usericon} alt="usericon" height={30} width={30} className="mr-2" />
                                        <Tooltip title={viewData?.user?.name}>
                                            <span>{viewData?.user?.name?.length > 20 ? viewData?.user?.name?.slice(0, 20) + "..." : viewData?.user?.name}</span>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className={` text-sm text-deepgray-5 font-normal font-[Roboto-Regular] text-start w-[10%]`}>{viewData?.services[0]?.category[0]?.name}</td>
                                {/* <Tooltip title={viewData?.view}> */}
                                <td className={`text-center text-sm text-deepgray-5 font-[Roboto-Regular] truncate w-[10%]`}>{viewData?.views}</td>
                                {/* </Tooltip> */}
                                <Tooltip title={viewData?.location}>
                                    <td className={`text-start text-sm text-deepgray-5 font-[Roboto-Regular] w-[25%]`}>{viewData?.location?.length > 30 ? viewData?.location?.slice(0, 30) + "..." : viewData?.location}</td>
                                </Tooltip>
                                <td className={`text-start text-sm text-deepgray-5 font-[Roboto-Regular] w-[15%]`}>{viewData?.user?.planPriority ? viewData?.user?.planPriority === 3 ? "Platinum" : viewData?.user?.planPriority === 2 ? "Gold" : "Basic" : "N/A"}</td>
                                <td className={`text-start text-sm text-deepgray-5 font-[Roboto-Regular] w-[15%]`}>{moment(viewData.createdAt).format("YYYY-MM-DD")}</td>

                                {/* <td className={`text-left pr-6 text-sm text-deepgray-5 font-normal font-[Roboto-Regular] `}>
                                    <div className='flex items-center gap-8'>
                                        <img src={editIcon} alt="edit icon" className='text-start' />
                                        <img src={deleteIcon} alt="delete icon" className='text-start' />
                                        <img src={optionMenuIcon} alt="option icon" className='text-start' />
                                    </div>
                                </td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default MostViewedVendorTable

