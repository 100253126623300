import React, { useState } from 'react'
// import CheckBox from '../../../Common/FormFields/CheckBox';
import editIcon from '../../../Images/adminTable/editIcon.svg'
import deleteIcon from '../../../Images/adminTable/deleteIcon.svg'
import usericon from '../../../Images/adminTable/userAvatar.svg'
// import optionMenuIcon from '../../../Images/adminTable/optionMenu.svg'
// import ToggleSwitch from '../../../Common/FormFields/ToggleSwitch';
import './index.css'
// import TotalUsersHeader from '../TotalUsers/TotalUsersHeader';
import text from "../../../Common/Languages/en_US.json"
// import { useSelector } from 'react-redux';
// import ButtonField from '../../../Common/FormFields/ButtonField';
// import { ForAdmin } from '../../../Containers/Admin/Hooks';
import { CircularProgress, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import ConfirmationModal from '../../../Common/Popups/ConfirmationModal'
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from "dayjs";
dayjs.extend(localizedFormat);


/**
 * @component SubAdminTable
 * @description list of sub admins
 * @param {*} props 
 */

const AnnouncementTable = (props) => {
    const [chipData, setChipData] = useState();
    const [openChipModal, setOpenChipmodal] = useState(false);
    const [Id, setId] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);


    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (itemToDelete) {
            props.deleteAnnouncement(itemToDelete)
            setIsModalOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
        setItemToDelete(null)
    };

    //chipmodal handler function

    const handleChipClick = (data, id) => {
        setChipData(data);
        setOpenChipmodal(!openChipModal);
        setId(id);
    }

    // function to close chipmodal
    const handleCloseChipModal = () => {
        setChipData({});
        setOpenChipmodal(false);
    }

   
    return (
        <div className='w-full border-r-2 border-t-2 border-gray-1 shadow-sm h-[65vh] overflow-y-scroll'>
            {props.loading === true ?
                <div className='flex justify-center h-full items-center'>
                    <CircularProgress size={20} color='success' />
                    <p className='pl-3'>Loading...</p>
                </div>
                :
                <table className="table-auto w-full bg-white">

                    <thead className='sticky top-0 z-10 bg-white'>
                        <tr className='h-10'>
                            {/* <th className={`w-[3%] text-gray-3 text-left  text-base font-normal font-[SansRegular] px-[1%] `}>
                            <CheckBox size={'small'} sx={{ color: '#00000057' }} />
                        </th> */}
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] truncate px-2`}>{text.title}</th>

                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap`}>{text.des}</th>
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] `}>{text.sentTo}</th>
                            <th className={`w-[15%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap`}>{text.expiry}</th>
                            {/* <th className={`w-[8%] text-deepgrey-3 text-left pr-8 text-base font-normal font-[Roboto-Regular] px-4 whitespace-nowrap `}>
                            {text.assignedRole}
                        </th> */}
                            <th className={`w-[10%] text-deepgrey-3 text-left text-base font-normal font-[Roboto-Regular]  pr-6`}>{text.action}</th>

                        </tr>
                    </thead>
                    <tbody>

                        { props?.announcedData && props.announcedData?.length > 0 &&   props.announcedData?.map((user, idx) => {
                            return (
                                <tr
                                    key={idx}
                                    className={' drop-shadow-md border-t-2 border-b-2   border-[#EBEBEB]'}>
                                   
                                   <Tooltip title={user?.title}>
                                    <td className={`text-left text-[14px]  !font-[Roboto-Regular] text-deepgray-5 px-4 py-3 whitespace-nowrap max-w-[200px] truncate`}>{user?.title}</td>
                                    </Tooltip>

                                    <Tooltip title={user?.description}>
                                    <td className={`text-left text-[14px]  !font-[Roboto-Regular] text-deepgray-5 px-4 whitespace-nowrap max-w-[200px] truncate`}>{user?.description}</td>
                                    </Tooltip>

                                    <td className={`text-left text-[14px]  font-[Roboto-Regular] text-deepgray-5 px-4 whitespace-nowrap capitalize`}>{user?.sentTo}</td>

                                    <td className={`text-left text-[14px]  font-[Roboto-Regular] text-deepgray-5 px-4 whitespace-nowrap`}>{dayjs(user?.expireIn).format('YYYY-MM-DD')}</td>

                                    <td className={`text-left pr-6 text-[14px]  font-[Roboto-Regular] `}>
                                        <div className='flex items-center gap-8'>
                                          
                                            <img onClick={() => handleDeleteClick(user?.id)} src={deleteIcon} alt="delete icon" className='text-start cursor-pointer' />
                                            {/* <img src={optionMenuIcon} alt="option icon" className='text-start cursor-pointer' /> */}
                                        </div>
                                    </td>
                                   
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
              <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                message="Are you sure you want to delete this?"
            />
        </div>
    )
}



export default AnnouncementTable

