import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import { useForm, Controller } from "react-hook-form"
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { useTranslation } from 'react-i18next'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import { useDispatch } from 'react-redux'
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'


const HyperlinkForm = (props) => {
    const { cleanupMedia, logoUpload } = ForUploadMediaHooks()
    const dispatch = useDispatch()

    // form handling using react-hook-form 
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = (data) => {
        // conditionally rendering edit or create api for hyperlink 
        props.hyperlinkFormData ? props.editHyperlinkApi(data) : props.createHyperlink(data)
        cleanupMedia()
        // resetting after submission 
        reset({
            hyperlinkName: '',
            addUrl: '',
            description: ''

        })
    };

    useEffect(()=> {
        dispatch(setUploadedLogo(props.hyperlinkFormData?.picture))
    },[])

    return (
        <div className={`px-[4%] py-[5%] flex flex-col gap-[3rem]`}>
            {/* form content  */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-5 textFieldText'>
                    <div className='flex justify-between w-full'>
                        <div className='w-[70%]'>
                            <div>
                                <Controller
                                    name={"hyperlinkName"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`w-full text-sm`}
                                                    placeholder='*Hyperlink Name'
                                                    onChange={onChange}
                                                    value={(value)}
                                                    defaultValue={props.hyperlinkFormData?.name ? props.hyperlinkFormData?.name : value}
                                                    floatingLabel={'*Hyperlink Name'}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{
                                        required: props.hyperlinkFormData ? false : true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2
                                    }}
                                />
                                {/* hyperlink name error msg */}
                                {errors.hyperlinkName && errors.hyperlinkName.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.hyperlinkName && errors.hyperlinkName.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">{text.fullNameValidation}</span>
                                )}
                                {errors.hyperlinkName && errors.hyperlinkName.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">{text.enterValidName}</span>
                                )}
                            </div>

                            {/* add url controller */}
                            <div>
                                <Controller
                                    name={"addUrl"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`w-full text-sm`}
                                                    placeholder='*Add URL'
                                                    onChange={onChange}
                                                    value={(value)}
                                                    defaultValue={props.hyperlinkFormData?.url ? props.hyperlinkFormData?.url : value}
                                                    floatingLabel={'*Add URL'}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{ required: props.hyperlinkFormData ? false : true, pattern: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }}
                                />
                                {/* all url error msg */}
                                {errors.addUrl && errors.addUrl.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.addUrl && errors.addUrl.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">{text.validUrl}</span>
                                )}
                            </div>

                            {/* description controller  */}
                            <div>
                                <Controller
                                    name={"description"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (
                                                <TextFieldInput
                                                    onlyValue
                                                    textnewclass={`w-full text-sm`}
                                                    placeholder='*Write Description'
                                                    onChange={onChange}
                                                    value={(value)}
                                                    multiline={true}
                                                    minRows={3}
                                                    defaultValue={props.hyperlinkFormData?.description ? props.hyperlinkFormData?.description : value}
                                                    floatingLabel={'*Write Description'}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            )
                                        )
                                    }}
                                    rules={{ required: props.hyperlinkFormData ? false : true, minLength: 3 }}
                                />
                                {/* description error msg */}
                                {errors.description && errors.description.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.description && errors.description.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">{text.sayMore}</span>
                                )}
                            </div>
                        </div>

                        <div>
                            <p className={`text-Grey-900 font-[Roboto-Medium] font-normal text-base mb-5`}>Upload Your Profile</p>
                            <div className={`relative`}>
                                {logoUpload &&
                                    (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                    <div className={`absolute -top-2 left-24 -right-4 cursor-pointer`}>
                                        <CloseIcon
                                            sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                            onClick={() => dispatch(setUploadedLogo({}))}
                                        />
                                    </div>
                                }
                                <UploadButton
                                    sendData={(data) => {
                                        if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
                                            props.doUploadedLogo(data)
                                        }
                                    }}
                                    extracls='flex justify-center items-center bg-white  items-center rounded-md '
                                    type='button'
                                    fileUploadIcon={
                                        logoUpload &&
                                            (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') && logoUpload?.key ?
                                            `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=20&width=auto` :
                                            fileUploadIcon
                                    }
                                    accept={'image/jpg, image/png'}
                                    multiple={false}
                                    disabled={logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') ?
                                        true : false
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* form cta  */}
                    <div className={`flex justify-start`}>
                        <div className='flex gap-6'>
                            <ButtonField
                                buttonName={text.cancel}
                                type='reset'
                                buttonextracls={` !px-6 !py-2 !text-gray-60 bg-gray-1 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
                                onClick={() => { props.goToLinkTable(); cleanupMedia() }}
                            />
                            <ButtonField
                                buttonName={text.save}
                                type='submit'
                                buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6 hover:bg-deepBlue`}
                                onClick={handleSubmit(onSubmit)}
                                loading={props.loading}
                                disabled={props.loading === true ? true : false}
                            />
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}

// propTypes
HyperlinkForm.propTypes = {
    goToLinkTable: PropTypes.func,
    createHyperlink: PropTypes.func

}

export default HyperlinkForm