import { CircularProgress } from "@mui/material";
import Dropzone from "react-dropzone";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const UploadButton = (props) => {
    const { t } = useTranslation();
    const [isSizeError, setIsSizeError] = useState(false);

    useEffect(() => {
        let timer;
        if (isSizeError) {
            timer = setTimeout(() => {
                setIsSizeError(false);
            }, 5000); // Remove error after 5 seconds
        }
        return () => clearTimeout(timer);
    }, [isSizeError]);

    const handleDrop = (data) => {
        const fileLimit = props?.fileLimit || 3;
        const maxVideoSize = 20 * 1024 * 1024; // 20 MB in bytes

        const validFiles = data.filter(file => {
            if (file.type.startsWith("video/")) {
                if (file.size > maxVideoSize) {
                    setIsSizeError(true);
                    return false; // Exclude video files > 20 MB
                }
            }
            return true; // Include all other files
        });

        if (validFiles.length <= fileLimit) {
            props.sendData(validFiles);
        } 
    };

    return (
        <div className={`${props.dropZoneRootCls}`}>
            <Dropzone
                onDrop={handleDrop}
                accept={props.accept}
                disabled={props.disabled}
                multiple={props.multiple}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className={`${props.dropzoneGetRootPropsCls}`}>
                            <input {...getInputProps()} />
                            <button className={props.extracls} type={props.type ? props.type : null}>
                                {props.loading === true ? (
                                    <div className="loaderwithtxt">
                                        <CircularProgress size={20} className="" sx={{ color: "white" }} />
                                    </div>
                                ) : (
                                    <div className={`${props.contentRootCls}`}>
                                        {props.icon ? (
                                            <img
                                                src={props.icon}
                                                alt="icon"
                                                height={props.imgHeight ? props.imgHeight : 20}
                                                width={props.imgWidth ? props.imgWidth : 20}
                                            />
                                        ) : null}
                                        <div className={`flex justify-between items-center w-full ${props.textContentOuterCls}`}>
                                            {props.textContent && (
                                                <p className={`${props.texExtraClsRoot}`}>
                                                    <span className={`${props.texExtraCls1}`}>{props.text1}</span>&nbsp;
                                                    <span className={`${props.texExtraCls2}`}>{props.text2}</span>&nbsp;
                                                    {props.disabled === false && (
                                                        <span className={`${props.texExtraCls3}`}>{props.text3}</span>
                                                    )}
                                                </p>
                                            )}
                                            {props.textContent2 && (
                                                <p className={`${props.texExtraClsRoot}`}>
                                                    <span className={`${props.texExtraCls1}`}>{props.text1}</span>
                                                    <span className={`${props.texExtraCls2}`}>{props.text2}</span>
                                                    {props.disabled === false && (
                                                        <span className={`${props.texExtraCls3}`}>{props.text3}</span>
                                                    )}
                                                </p>
                                            )}
                                            {props.fileUploadIcon && (
                                                <div className={`flex flex-col gap-2 ${props.fileUploadCss}`}>
                                                    <img
                                                        src={props.fileUploadIcon}
                                                        crossOrigin="anonymous"
                                                        alt="fileUploadIcon"
                                                        height={props.height ? props.height : 75}
                                                        width={props.width ? props.width : 75}
                                                    />
                                                    <p className={props.extratextcls}>{props.fileName}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </button>
                        </div>
                        {isSizeError && <p className="text-xs text-red-500 mt-2">Please Upload Video within 20 MB</p>}
                    </section>
                )}
            </Dropzone>
        </div>
    );
};

UploadButton.propTypes = {
    sendData: PropTypes.func,
    accept: PropTypes.object,
    extracls: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    imgHeight: PropTypes.number,
    imgWidth: PropTypes.number,
    texExtraClsRoot: PropTypes.string,
    texExtraCls: PropTypes.string,
    texExtraCls1: PropTypes.string,
    texExtraCls2: PropTypes.string,
    texExtraCls3: PropTypes.string,
    textHead: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
    text3: PropTypes.string,
    fileUploadIcon: PropTypes.any,
    multiple: PropTypes.bool,
    dropZoneRootCls: PropTypes.string,
    dropzoneGetRootPropsCls: PropTypes.string,
    contentRootCls: PropTypes.string,
    textContentOuterCls: PropTypes.string,
    textContent: PropTypes.bool,
    textContent2: PropTypes.bool,
    setIsError: PropTypes.func,
    isError: PropTypes.bool, // Added isError prop
    fileLimit: PropTypes.number,
};

export default UploadButton;
