import React, { useEffect, useState } from 'react'
import backArrow from '../../Images/LandingImages/arrowBack.svg'
import searchIcon from '../../Images/LandingImages/searchIcon.svg'
import HyperlinkIcon from '../../Images/Setting/HyperlinkIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { ForLanding } from '../../Containers/LandingPage/Hooks'
import { ForAdmin } from '../../Containers/Admin/Hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import searchIconDark from '../../Images/LandingImages/searchIconDark.svg'
import './LandingPage.css'
import usericon from '../../Images/adminTable/userAvatar.svg'
import RatingField from '../../Common/UiComponents/Rating'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PhoneIcon from '@mui/icons-material/Phone';
import TextFieldInput from '../../Common/FormFields/TextFieldInput'
import { ClickAwayListener, InputAdornment, CircularProgress } from '@mui/material'
import { getTotalSubCategory } from '../../Containers/Admin/reducer/CategoryReducer'
import { getSubCategoryVendor } from '../../Containers/LandingPage/LandingReducer'
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import NoContentPage from '../../Common/Layout/NoContentPage/NoContentPage'


const SeeAllCategory = (props) => {
    const [searchParams] = useSearchParams();
    const [subCategoryData, setSubCategoryData] = useState()
    const [openCategoryModal, setOpenCategoryModal] = useState(false)

    const [search, setSearch] = useState(false)
    const [searchSubCategory, setSearchSubCategory] = useState(false)
    const [catId, setCatId] = useState()
    const navigate = useNavigate()
    const categories = useSelector(state => state.totalCategoryInAdmin.totalCategories)
    const subCatVendors = useSelector(state => state.landingData.subCatVendor)
    const totalSubCat = useSelector(state => state.totalCategoryInAdmin.totalSubCategory)
    const dispatch = useDispatch()

    useEffect(() => {
        searchSubCategoryApi(searchParams.get("catId"));
        setCatId(searchParams.get("catId"));
        setSearch(false);
        dispatch(getSubCategoryVendor({}))
    }, []);



    const handleOpenCategoryModal = (subCatData, categoryId) => {
        // setOpenCategoryModal(true)
        setSubCategoryData(subCatData)
        setSearch(false)
        setCatId(categoryId)
        searchSubCategoryApi(categoryId)
        dispatch(getSubCategoryVendor({}))
    }
    const { getCategory, handleSearchCategory, categorySearchString, subCatSearchHandler, subCatSearchString, searchSubCategoryApi, defaultSubcat, setDefaultSubcat } = ForAdmin()
    const { getVendorsUnderSubCategory, loader } = ForLanding()

    useEffect(() => {
        getCategory()
    }, [categorySearchString])

    useEffect(() => {
        searchSubCategoryApi(catId)
    }, [subCatSearchString])

    const handleVendorDetail = (id) => {
        navigate(`/details/${id}`)
    }
    const handleSubCategoryVendor = (subCatId) => {
        setSearchSubCategory(false)
        getVendorsUnderSubCategory(subCatId)
        setDefaultSubcat(subCatId)
    }

    useEffect(() => {
        if (defaultSubcat) {
            setSearchSubCategory(false)
            getVendorsUnderSubCategory(defaultSubcat)
        }
    }, [defaultSubcat])

    return (
        <>
            <div className='sticky top-0 z-50'>
                <MainHeader />
                <LandingHeader
                    searchBar={true}
                    goTomessage={true}
                    goToNotification={true}
                    handleclickNotification={props.handleshow}
                    border={true}
                    profile={true}
                    showNotification={props.showNotification}
                    allnotification={props.allnotification}
                    detailsPage={props.detailsPage}
                    readNotificationApi={props.readNotificationApi}
                />
            </div>

            <div className={`flex`}>
                {/* category list  */}
                <div className={`w-[25%] bg-[#27216E] h-screen px-6 py-5`}>
                    {/* category top  */}
                    <div className={`flex justify-between items-center searchIconGap h-[2rem] w-full`}>
                        {search === false && <div className={`flex gap-3 items-center`}>
                            <img
                                src={backArrow} alt='back-arrow'
                                style={{ height: '19px', width: '19px', cursor: 'pointer' }}
                                onClick={() => {
                                    navigate(-1);
                                    dispatch(getTotalSubCategory([]))
                                    dispatch(getSubCategoryVendor({}))
                                }}
                            />
                            <p className='text-white text-xl font-[Roboto-Bold]'>Categories</p>
                        </div>
                        }

                        {search === true ?
                            <ClickAwayListener onClickAway={() => setSearch(false)}>
                                <div className='w-full'>
                                    <TextFieldInput
                                        onlyValue
                                        textnewclass={`flex-1 w-full text-md tableSearchBar`}
                                        placeholder={`Search category`}
                                        id="outlined-basic"
                                        variant="outlined"
                                        startAdornment={
                                            <InputAdornment adormentPosiotion={`end`}>
                                                <img src={searchIcon} alt='search' />
                                            </InputAdornment>}
                                        onChange={handleSearchCategory}
                                        autoFocus={true}
                                    />
                                </div>
                            </ClickAwayListener>
                            :
                            <div onClick={() => setSearch(true)}>
                                <img src={searchIcon} alt='searchIcon' style={{ cursor: 'pointer' }} />
                            </div>
                        }
                    </div>
                    {/* category items  */}
                    <div className='mt-4 h-[90vh] overflow-y-scroll scrollbarRemover'>

                        {categories?.length > 0 ? categories?.map((categoryData, idx) =>
                            <div key={idx} className={`flex items-center gap-3 p-2 cursor-pointer ${(catId?.toString() === categoryData?.id.toString()) ? `no-underline bg-[#413A8A]` : ``}`}
                                onClick={() => handleOpenCategoryModal(categoryData, categoryData?.id)}
                            >
                                <div className={`border-2 border-violet-0.5 h-[50px] w-[50px] rounded-full flex justify-center items-center`}>
                                    <img
                                        crossOrigin='anonymous'
                                        src={categoryData?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${categoryData?.picture?.key}&height=60&width=60` : HyperlinkIcon}
                                        alt={categoryData?.name}
                                        className={`h-fit w-fit rounded-full `}
                                    />
                                </div>
                                <p className={`text-white text-base font-[Roboto-Regular] font-normal`}>{categoryData?.name}</p>
                            </div>) : <p className='text-[#fff] mt-3'>No Category data available</p>}
                    </div>
                </div>

                {/* sub-category list  */}
                <div className={`w-[25%] bg-[#F5F5F5] h-screen px-6 py-5`}>
                    <div className={`flex justify-between items-center h-[2rem] searchIconGapDark`}>
                        {searchSubCategory === false && <div className={`flex gap-2 items-center`}>
                            <p className='text-[#515151] text-xl font-[Roboto-Bold]'>Sub-Categories</p>
                        </div>}

                        {searchSubCategory === true ?
                            <ClickAwayListener onClickAway={() => setSearchSubCategory(false)}>
                                <div className='w-full'>
                                    <TextFieldInput
                                        onlyValue
                                        textnewclass={`flex-1 w-full text-sm tableSearchBar`}
                                        placeholder={`Search Sub-Category`}
                                        // id="outlined-basic"
                                        variant="outlined"
                                        startAdornment={
                                            <InputAdornment adormentPosiotion={`end`}>
                                                <img src={searchIconDark} alt='search-icon-dark' />
                                            </InputAdornment>}
                                        onChange={subCatSearchHandler}
                                        autoFocus={true}
                                    />
                                </div>
                            </ClickAwayListener>
                            :
                            <div onClick={() => setSearchSubCategory(true)}>
                                <img src={searchIconDark} alt='searchIcon' style={{ cursor: 'pointer' }} />
                            </div>
                        }
                    </div>

                    {/* sub-category mapping  */}
                    <div className='mt-4 flex flex-col gap-5 h-[90vh] overflow-y-scroll scrollbarRemover'>
                        {totalSubCat?.length > 0 ? totalSubCat?.map((subCategoryItem, index) =>
                            <div className=''>
                                <div className={`flex gap-3 cursor-pointer items-center p-2 ${defaultSubcat === subCategoryItem?.id ? `no-underline bg-[#dbd8d8]` : ``}`} key={index} onClick={() => handleSubCategoryVendor(subCategoryItem.id)}>
                                    <img
                                        crossOrigin='anonymous'
                                        src={subCategoryItem?.picture?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${subCategoryItem?.picture?.key}&height=45&width=45` : usericon}
                                        alt={subCategoryItem?.name}
                                        className={`rounded-full border border-slate-400`}
                                    />
                                    <p className={`text-[#2f2e2e] font-[Roboto-Regular]`}>{subCategoryItem?.name}</p>
                                </div>

                            </div>
                        ) :
                            <p className='text-[#2f2e2e]'>No Sub-Category data available</p>}
                        {/* </div> */}
                        {/* } */}
                    </div>
                </div>

                {/* vendorList */}
                <div className={`w-[50%] bg-white h-screen px-8 py-5 scrollbarRemover`}>
                    <p className='text-[#515151] text-xl font-[Roboto-Bold]'>All Vendors</p>
                    <div className='mt-4 h-[90vh] overflow-y-scroll scrollbarRemover'>
                        {/* {subCategoryData?.subCategory?.length > 0 ? subCategoryData?.subCategory?.map((subCategoryItem, index) => */}

                        {loader ?
                            <div className='flex h-full justify-center items-center'>
                                <CircularProgress size={20} color='success' />
                                <p className='pl-3'>Loading...</p>
                            </div> :

                            <div className='flex flex-col gap-4'>
                                {/* vendors under sub-category  */}
                                {
                                    subCatVendors && subCatVendors?.vendorArr && subCatVendors?.vendorArr?.length > 0 ? subCatVendors?.vendorArr?.map((subCatVendor) =>
                                        <div className='flex cursor-pointer gap-3 min-h-[10rem] border-[#ccc] border-2 rounded px-2 py-2 vendor-box_shadow' onClick={() => handleVendorDetail(subCatVendor?.userId)}>
                                            <div className='w-[20%]'>
                                                <img
                                                    crossOrigin='anonymous'
                                                    src={subCatVendor?.logo?.key !== null ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${subCatVendor?.logo?.key}` : usericon}
                                                    alt={subCatVendor?.name}
                                                    className={`w-full max-h-[7rem] object-fill`}
                                                />

                                            </div>
                                            <div className='w-[80%] flex flex-col gap-4'>
                                                <div className="flex items-center gap-2">
                                                    <p className={`vendor_name font-[Roboto-Medium] text-lg vendor_name ${subCatVendor?.distance < 5 ? `text-seconderyColour-5` : `text-[#1F2533]`}`}>
                                                        {subCatVendor?.name}
                                                    </p>
                                                    {subCatVendor?.user?.planPriority === 3 ? (
                                                        <span className="px-2 py-1 text-xs font-[Roboto-Medium] text-white bg-orange-400 rounded">
                                                            Featured
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div className='flex gap-2'>
                                                    <p className='bg-green-600 text-white w-fit px-4 py-[2px] rounded-lg'>{subCatVendor?.avgRating}</p>
                                                    <RatingField
                                                        // SendRating={true}
                                                        size="small"
                                                        value={subCatVendor?.avgRating}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <p className='text-sm text-[#444]'>{subCatVendor?.location}</p>
                                                {/* services  */}
                                                {/* {subCatVendor?.services?.map((service, idx)=>(
                                                <div>{service?.name}</div>
                                            ))} */}

                                                {/* phone number section section */}
                                                <div className='flex justify-between cursor-default'>
                                                    {/* left  */}
                                                    <p className='bg-green-600 text-white w-fit px-4 py-[2px] h-[2.5rem] rounded-lg flex gap-2 items-center font-[Poppins-Medium]'>
                                                        <span>
                                                            <PhoneIcon className='' style={{ fontSize: '20px' }} />
                                                        </span>
                                                        {subCatVendor?.user?.phoneNo}
                                                    </p>

                                                    {/* right  */}
                                                    <div className='flex gap-1 h-[2.5rem] items-center'>
                                                        <TrendingUpIcon style={{ fontSize: '20px' }} />
                                                        <p className='text-sm'>{subCatVendor?.views} people recently enquired</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <div className={`flex justify-center items-center h-[70vh]`}>
                                        <NoContentPage
                                            text2={`No Data Found`}
                                        />
                                    </div>
                                }
                            </div>}
                        {/* ) :
                        null} */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SeeAllCategory