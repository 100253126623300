import React from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import upwardIcon from '../../../Images/adminContent/UpwardIcon.svg'
import PropTypes from 'prop-types';
import text from '../../../Common/Languages/en_US.json'


const DashboardCard = (props) => {
    return (

        <div className={`flex flex-col gap-4 border rounded-md p-4 drop-shadow bg-white h-full min-w-[13.1rem]`}>
            <p className={`text-[#828B9F] text-sm`}>{props.cardName}</p>
            <div className={`flex items-center gap-4`}>
                <p className='text-deepgray-5 font-[Roboto-Regular] text-xl'>{props.cardTotal}</p>
                {/* <ButtonField
                    buttonName={props.btnName}
                    startIcon={<img src={upwardIcon} alt='upward icon' className='w-[14px] h-[17px]' />}
                    buttonextracls={
                        props.graphColor === '#1DBF73' ?
                            `!px-2 !py-1 !text-white bg-[#1DBF73] text-xs` : props.graphColor === '#FF0033' ?
                                `!px-2 !py-1 !text-white bg-[#FF0033]  text-xs` : props.graphColor === '#FFCD46' ?
                                    `!px-2 !py-1 !text-white bg-[#FFCD46]  text-xs` : props.graphColor === '#186AB2' ?
                                        `!px-2 !py-1 !text-white bg-[#186AB2]  text-xs` : props.graphColor === '#823AA7' ?
                                            `!px-2 !py-1 !text-white bg-[#823AA7]  text-xs` : props.graphColor === '#0099CC' ?
                                                `!px-2 !py-1 !text-white bg-[#0099CC]  text-xs` : null} /> */}
            </div>
            {/* <p className={`text-[#747C8F] text-xs`}>
                {props.startText} <span className={props.graphColor === '#1DBF73' ? `text-green-primary-100` : props.graphColor === '#FF0033' ? `text-red-5` : props.graphColor === '#186AB2' ? `text-[#186AB2]` : props.graphColor === '#FFCD46' ? `text-yellow-5` : props.graphColor === '#823AA7' ? `text-[#823AA7]` : props.graphColor === '#0099CC' ? `text-[#0099CC]` : null}>{props.cardSavings}</span>&nbsp;{props.endText}</p> */}
        </div>

    )
}

// proptypes
DashboardCard.propTypes = {
    cardName: PropTypes.string,
    cardTotal: PropTypes.string,
    btnName: PropTypes.string,
    graphColor: PropTypes.string,
    cardSavings: PropTypes.string,
    startText: PropTypes.string,
    endText: PropTypes.string
}
export default DashboardCard