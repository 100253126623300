import React from 'react'
import Footer from '../Footer/Footer'
import LandingHeader from '../LandingHeader/LandingHeader'
import aboutus from '../../../../src/Images/aboutus/aboutus.jpg'

const CommonAboutus = (props) => {
    return (
        <div className={`faqdesign h-fit`}>
            {/* <MainHeader /> */}
            <LandingHeader
                searchBar={true}
                goTomessage={true}
                goToNotification={true}
                border={true}
                profile={true}
                handleclickNotification={props.handleshow}
                showNotification={props.showNotification}
                detailsPage={props.detailsPage}
                allnotification={props.allnotification}
                readNotificationApi={props.readNotificationApi}
            />
            <div className={`px-[6%] sm:px-[5%] py-[4%] sm:py-[3%]`}>
                <div className={`flex justify-between w-full mb-[2%]`}>
                    <p className={`text-gray-9.5 text-3xl font-[Roboto-Bold]`}>ABOUT US</p>
                </div>
                <div className='w-[100%] sm:w-[65%]'>
                    <div className="mb-6">
                        <img
                            src={aboutus}
                            alt="AboutUs"
                            className="w-full rounded-md"
                        />
                    </div>
                    <div className="text-[#3B4252] leading-relaxed mb-8 font-[Roboto]">
                        <div className='mb-4'>
                            It is a long established fact that a reader will be distracted by the
                            readable content of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters, as opposed to using 'Content here, content here', making it
                            look like readable English. Many desktop publishing packages and web
                            page editors now use Lorem Ipsum as their default model text, and a
                            search for 'lorem ipsum' will uncover many web sites still in their
                            infancy. Various versions have evolved over the years, sometimes by
                            accident, sometimes on purpose (injected humour and the like).
                        </div>
                        <div>
                            It is a long established fact that a reader will be distracted by the
                            readable content of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters, as opposed to using 'Content here, content here', making it
                            look like readable English. Many desktop publishing packages and web
                            page editors now use Lorem Ipsum as their default model text, and a
                            search for 'lorem ipsum' will uncover many web sites still in their
                            infancy. Various versions have evolved over the years, sometimes by
                            accident, sometimes on purpose (injected humour and the like).
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CommonAboutus
