import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminPieChart = () => {
    const dashboardData = useSelector((state) => state.dashboard.dashboardData);

    // Labels and values extracted dynamically
    const labels = ["Registered Users", "Vendors"];
    const dataValues = [
        dashboardData?.registeredUsers || 0,
        dashboardData?.totalVendor || 0
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                label: "User Distribution",
                data: dataValues,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "bottom",
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div className="w-full py-6">
            <div
                style={{
                    boxShadow:
                        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)",
                }}
                className="flex flex-col gap-4 px-10 py-6 rounded-lg bg-white"
            >
                <div className="relative h-[300px] w-full">
                    <Pie data={data} options={options} />
                </div>
            </div>
        </div>
    );
};

export default AdminPieChart;
