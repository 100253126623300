import React, { useEffect, useState } from 'react'

import text from "../../../Common/Languages/en_US.json"
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader';
import ButtonField from '../../../Common/FormFields/ButtonField';
import SubAdminFileUpload from './SubAdminFileUpload';
import SubAdminForm from './SubAdminForm';
import SubAdminTable from './SubAdminTable';
import AnnouncementTable from './AnnouncementTable';
import AnnouncmentForm from './AnnouncmentForm';
import { ForAdmin } from '../../../Containers/Admin/Hooks';
import SettingSidebar from './SettingSidebar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';



const AddAnnouncement = (props) => {



    return (
        <div className='bg-white flex'>
            {/* setting drawer  */}
            {/* <SettingSidebar /> */}
            {/* setting content  */}
            <div className='w-full'>

                {props.showannouncementForm ? (
                    <>
                        <TotalUsersHeader
                            userName={text.createAnnouncement}
                            dropDown={false}
                            addBtn={false}
                        />
                        <div className='flex'>
                            <div className='w-[90%]'>
                                <AnnouncmentForm
                                    announcementCreate={props.announcementCreate}
                                    getAllUsers={props.getAllUsers}
                                    subAdminUser={props.subAdminUser}
                                    showannouncementForm={props.showannouncementForm}
                                    goBackToTable={props.goBackToTable}
                                    editSubAdminFormData={props.editSubAdminFormData}
                                    editSubAdminApiCall={props.editSubAdminApiCall}
                                    subAdminCreateMsg={props.subAdminCreateMsg}
                                    loading={props.loading}
                                />
                            </div>
                        </div>
                    </>

                ) : (
                    <div className={`h-[75vh]`}>
                        <TotalUsersHeader
                            userName={`Announcement`}
                            dropDown={false}
                            addBtn={localStorage.getItem('role') === 'admin' && true}
                            toggleSubAdmin={props.toggleAnnounce}
                            btnColor={true}
                        />
                        <AnnouncementTable
                            getAllUsers={props.getAllUsers}
                            subAdminUser={props.subAdminUser}
                            announcedData={props.announcedData}
                            editSubAdminForm={props.editSubAdminForm}
                            deleteAnnouncement={props.deleteAnnouncement}
                            loading={props.loading}
                        />
                    </div>
                )}
                {/* subAdmin form component  */}

            </div>
        </div>
    )
}

// AddSubAdmin.propTypes = {
//     subAdminCreate: PropTypes.func,
//     getAllUsers: PropTypes.func,
//     subAdminUser: PropTypes.string,
//     showSubAdminForm: PropTypes.bool,
//     goBackToTable: PropTypes.func,
//     toggleSubAdmin: PropTypes.func,
// }

export default AddAnnouncement