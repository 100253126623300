import React, { useState, useEffect } from "react";
import { Modal, Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function BannerAnnouncement({ announceList, isOpen = true, handleCloseAnc }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : announceList?.length - 1
      );
      setIsAnimating(false);
    }, 500);
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex < (announceList?.length || 1) - 1 ? prevIndex + 1 : 0
      );
      setIsAnimating(false);
    }, 500);
  };

  useEffect(() => {
    if (!isOpen || !announceList?.length) return;

    const interval = setInterval(() => {
      handleNext();
    },7000);

    return () => clearInterval(interval);
  }, [isOpen, announceList?.length]);

  return (
    <Modal   sx={{
      "&:focus": {
        outline: "none",
      },
      "& *:focus": {
        outline: "none",
      },
    }}
   open={isOpen} onClose={handleCloseAnc} aria-labelledby="announcement-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          overflow: "hidden",
          background: "linear-gradient(135deg, #3a6cc5, #a155aa)",
          color: "#fff",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseAnc}
          sx={{ position: "absolute", top: 8, right: 8, color: "#fff" }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: 160,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              transform: isAnimating
                ? `translateX(-${(currentIndex + 1) * 100}%)`
                : `translateX(-${currentIndex * 100}%)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {announceList?.map((announcement, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  flexShrink: 0,
                  textAlign: "center",
                }}
              >
                <Typography
                  id="announcement-title"
                  gutterBottom
                  fontWeight="bold"
                  sx={{
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    fontSize:"30px"
                  }}
                >
                  {announcement.title}
                </Typography>
                <Typography
                  id="announcement-description"
                  mt={2}
                  sx={{
                    color: "#ffe4ec",
                  }}
                >
                  {announcement.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <IconButton
            aria-label="previous"
            onClick={handlePrev}
            disabled={announceList?.length <= 1}
            sx={{ color: "#fff" }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.8)">
            {`${currentIndex + 1} of ${announceList?.length || 0}`}
          </Typography>
          <IconButton
            aria-label="next"
            onClick={handleNext}
            disabled={announceList?.length <= 1}
            sx={{ color: "#fff" }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
}
