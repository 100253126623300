import React, { useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
import Accordionbutton from '../../../Common/UiComponents/Accordionbutton'
import PrivacyPolicyAndFaqForm from './PrivacyPolicyAndFaqForm'

const FAQ = (props) => {

  const AccordionMapping = [
    {
      id: 0,
      heading: "Why?",
      desc: "Why so serious? Lorem ipsum",
    },
    {
      id: 1,
      heading: "Why?",
      desc: "Why so serious? Lorem ipsum",
    },
    {
      id: 3,
      heading: "Why?",
      desc: "Why so serious? Lorem ipsum",
    },
    {
      id: 4,
      heading: "Why?",
      desc: "Why so serious? Lorem ipsum",
    },
  ]

  const [bgClick, setBgClick] = useState(false);

  // const handleBgChange = (id) => {
  //   setBgClick(id)
  // }

  return (
    <div className={` p-[2%]`}>
      {props.showFaqTextEditor === true ?
        <div>
          <PrivacyPolicyAndFaqForm
            createFaqApiCall={props.createFaqApiCall}
            handleTextEditor={props.handleFaqTextEditor}
            value={props.value}
          />
        </div>
        :
        <div className={`flex justify-between w-full  gap-10`}>
          <div className={`w-full`}>
            <div className={`flex justify-between items-center mb-[2%]`}>
              <p className={`text-base text-[#1F2533] font-[Roboto-Medium]`}>General Inquiries</p>
              <ButtonField
                buttonextracls={`bg-skyblue-6 text-white px-3 py-2`}
                buttonName={"Add New"}
                buttonnamecls={`text-xs font-[Roboto-Medium]`}
                onClick={props.handleFaqTextEditor}
              />
            </div>
            {props.allFaq?.map((faqData, idx) =>
              <div key={idx} className={`faqAdmin border-b`}>
                {faqData?.questions?.length > 0 && faqData?.answers?.length > 0 &&
                  <Accordionbutton
                    // onClick={() => handleBgChange(faqData.id)}
                    accordionMainCls={`${bgClick === faqData?.id ? 'bg-[#F5F5F5]' : ''}`}
                    AccordionSummary={
                      <div className={`flex justify-between px-3 py-1 items-start text-sm font-[Roboto-Regular]`}>
                        <p className={`text-base text-black font-[Roboto-Bold]`}>{faqData?.questions}</p>
                      </div>
                    }
                    AccordionDetails={
                      <div className={`mt-5`}>{faqData?.answers}</div>
                    }
                    panelItem={faqData?.id}
                    setExpanded={setBgClick}
                    expanded={bgClick}
                  />
                }
              </div>
            )}
          </div>

          {/* <div className={`w-full`}>
            <div className={`flex justify-between items-center mb-[2%]`}>
              <p className={`text-base text-[#1F2533] font-[Roboto-Medium]`}>Other Information</p>
              <ButtonField
                buttonextracls={`bg-skyblue-6 text-white px-3 py-2`}
                buttonName={"Add New"}
                buttonnamecls={`text-xs font-[Roboto-Medium]`}
                onClick={props.handleFaqTextEditor}
              />
            </div>
            {AccordionMapping.map((item, idx) =>
              <div key={idx} className={`faqAdmin border-b`}>
                <Accordionbutton
                  // onClick={() => handleBgChange(item.id)}
                  accordionMainCls={`${bgClick === item.id ? 'bg-[#F5F5F5]' : ''}`}
                  AccordionSummary={
                    <div className={`flex justify-between px-3 py-1 items-start text-sm font-[Roboto-Regular]`}>
                      <p className={`text-base text-black font-[Roboto-Bold]`}>{item.heading}</p>
                    </div>
                  }
                  AccordionDetails={
                    <div className={`mt-5`}>{item.desc}</div>
                  }
                  panelItem={item?.id}
                  setExpanded={setBgClick}
                  expanded={bgClick}
                />
              </div>
            )}
          </div> */}
        </div>
      }
    </div>
  )
}

export default FAQ