import React, { useEffect } from 'react'
import TotalUsersHeader from '../TotalUsers/TotalUsersHeader'
import ApproveMediaTable from './ApproveMediaTable'
import { ForAdmin } from '../../../../src/Containers/Admin/Hooks'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'

const ApproveMediaIndex = (props) => {
  const { getAllApproveMedia, adminSnackbar, messageClose } = ForAdmin()
  useEffect(() => {
    getAllApproveMedia()
  }, [])

  return (
    <>
      <div>
        <TotalUsersHeader userName={`Approve Media List`}
        />
        <div className={`h-[75vh] !overflow-auto `}>
          <ApproveMediaTable />
          <SnackBarComponent
            messageOpen={adminSnackbar.open}
            messageClose={messageClose}
            notificationText={adminSnackbar.message}
            subText={adminSnackbar.subText}
            alertType={adminSnackbar.alertType}
            borderClass={adminSnackbar.borderClass}
          />
        </div>
      </div>
    </>
  )
}

export default ApproveMediaIndex
