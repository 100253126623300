import React, { useEffect, useState } from 'react'
import ButtonField from '../../../Common/FormFields/ButtonField'
// import DropDownField from '../../../Common/FormFields/DropDownField'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput'
import './index.css'
import text from "../../../Common/Languages/en_US.json"
import { useForm, Controller } from "react-hook-form"
import { Box, Chip, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import locationImage from '../../../Images/Setting/locationImg.svg'
import PropTypes from 'prop-types';
// import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
// import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import UploadButton from '../../../Common/UiComponents/UploadButton'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { ForSignup } from '../../../Containers/Authentication/Signup/Hook'
import DropDownField from '../../../Common/FormFields/DropDownField'
import MultipleSelect from '../../../Common/FormFields/MultipleSelect'
import PhoneInputField from '../../../Common/FormFields/PhoneInputField'
import { governorateArray } from '../../../Utils/governorateArray'
import MuiDatePicker from '../../../Common/FormFields/MuiDatePicker'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        // fontWeight:
        //     personName.indexOf(name) === -1
        //         ? theme.typography.fontWeightRegular
        //         : theme.typography.fontWeightMedium,
    };
}

const announcementSelector = [{ name: "User", value: "user" },
                              { name: "Vendor", value: "vendor" },]

const AnnouncmentForm = (props) => {
    const theme = useTheme()
    // const { t } = useTranslation()
    // const userData = useSelector((state) => state.setting.users)
    // const [addSections, setAddSections] = useState()
    // const [addAssignedRole, setAddAssignedRole] = useState()

    const { cleanupMedia, logoUpload, doUploadedLogo } = ForUploadMediaHooks()
    const { getLocationData, mapLocationTxt } = ForSignup()
    const dispatch = useDispatch()
    const [sentTo, setsentTo] = useState(props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : '')
    const [input, setInput] = useState(
        // props.editSubAdminFormData?.place ? { name: props.editSubAdminFormData?.place, value: props.editSubAdminFormData?.place } : null
        );
    const noLocationFound = []

    const editSelectedMulti = props?.editSubAdminFormData?.sections.map((item) => item?.name)

    const [multipleSections, setMultipleSections] = useState(props?.editSubAdminFormData?.sections ? editSelectedMulti : [])
    const [addMultipleSections, setAddMultipleSections] = useState([])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
    })
    const [openMap, setOpenMap] = useState(false)
    const center = React.useMemo(() => ({
        lat: parseFloat(sessionStorage.getItem('latitude')),
        lng: parseFloat(sessionStorage.getItem('longitude'))
    }), [])
    const [marker, setMarker] = useState({})
    const onMapClick = React.useCallback((event) => {
        getLocationData(event.latLng.lat(), event.latLng.lng())
        setMarker({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            address: mapLocationTxt,
        });
    }, []);

    // handle multiple select unselect for data
    const selectedSections = addMultipleSections.filter((value, index, self) => {
        return self.indexOf(value) === index && self.lastIndexOf(value) === index;
    });

  

    // form handling using react-hook-form 
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            title: props.editSubAdminFormData?.name ? props.editSubAdminFormData?.name : '',
            sentTo: props.editSubAdminFormData?.location ? props.editSubAdminFormData?.location : '',
            expireIn: props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : null,
            description: props.editSubAdminFormData?.zipCode ? props.editSubAdminFormData?.zipCode : '',
            
        }
    });

    const onSubmit = (data) => {
       
        // setFormData(data)
        // setFormData(formData => [...formData, data]);
     props.announcementCreate(data)
        // resetting after submission 


        props.subAdminCreateMsg?.error === false &&
            reset({
                title: '',
                sentTo: '',
                expireIn: '',
               description: ''
            })
    };

    useEffect(() => {
        if (props.editSubAdminFormData && Object.keys(props.editSubAdminFormData)?.length > 0) {
            dispatch(setUploadedLogo(props.editSubAdminFormData?.picture))
        }
    }, [])


    // coustomize section's multiple data view
    const handleChangeSections = (event) => {
        const {
            target: { value },
        } = event;
        setMultipleSections(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div className={`px-[4%] py-[5%] flex flex-col gap-[3rem]`}>
            {/* form content  */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col gap-5 textFieldText'>
                    <div className='flex justify-between '>
                        <div className='w-[65%]'>
                            <div>
                                <Controller name={"title"}
                                    control={control}
                                    render={({ field: { onChange, value }, formState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='* Title'
                                                onChange={onChange}
                                                value={value}
                                                floatingLabel={'* Title'}
                                            />)
                                        )
                                    }}

                                    rules={{
                                        required: true, pattern: /^[a-zA-Z][a-zA-Z ]*/i, minLength: 2
                                    }}
                                />
                                {/* fullName error msg */}
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error-message text-red-400 text-xs">{text.requiredField}</span>
                                )}
                                {errors.title && errors.title.type === "minLength" && (
                                    <span className="error-message text-red-400 text-xs">{text.fullNameValidation}</span>
                                )}
                                {errors.title && errors.title.type === "pattern" && (
                                    <span className="error-message text-red-400 text-xs">{text.enterValidName}</span>
                                )}
                            </div>

                            {/* Email  */}
                           

                        

                            {/* Assigned dropDown  */}
                            {/* <div className={`grid grid-cols-2 gap-6 w-full dropdownText`}> */}
                            

                            {/* description controller  */}
                            <div>
                                <Controller name={"description"}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                                        return (
                                            (<TextFieldInput
                                                onlyValue
                                                textnewclass={`w-full text-sm`}
                                                placeholder='* Write Description'
                                                onChange={onChange}
                                                value={(value)}
                                                floatingLabel={'* Write Description'}
                                                inputProps={{ maxLength: 500 }}
                                            />)
                                        )
                                    }}
                                    rules={{
                                        required: true,
                                        minLength: 3
                                    }}
                                />

                                <small className={`text-red-400 text-xs`}>
                                    {errors?.description && errors?.description?.type === "required" && (<p>This field is required</p>)}

                                </small>

                            </div>
                        </div>

                        <div className='w-[30%]'> 

                      
                                {/* governorate  */}
                                <div className={`w-full mb-3   flex flex-col governorText`}>
                                    <Controller
                                        name={"sentTo"}
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        // defaultValue={props.editSubAdminFormData?.governorate ? props.editSubAdminFormData?.governorate : ''}
                                        render={({ field: { onChange, value } }) => (
                                            <DropDownField
                                                name='sentTo'
                                                id='sentTo'
                                                dropDownRootCls={`governorateCls`}
                                                size="small"
                                                selectOption={announcementSelector}
                                                placeholder={`* Send To`}
                                                // option={value}
                                                // handleChange={onChange}
                                                option={value ? value : sentTo}
                                                handleChange={(e) => { setsentTo(e.target.value); onChange(e.target.value); }}
                                            />
                                        )}
                                    />
                                    <small className={`text-red-400 text-xs`}>
                                        {errors?.sentTo && errors?.sentTo?.type === "required" && (<p>This field is required</p>)}

                                    </small>

                                </div>



                                {/* expire */}

                                <div className={`w-full !mt-5`}>
                                        <Controller name={"expireIn"}
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value }, formState: { error } }) => {
                                                return (
                                                    (
                                                        <MuiDatePicker
                                                            label='Set Expire Date'                                                           
                                                            value={value}
                                                            // views={['year', 'month', 'day']}
                                                            format={'dd-MM-yyyy'}
                                                            onChange={(newDate) => {
                                                                onChange(newDate)
                                                            }}
                                                        />
                                                    )
                                                )
                                            }}
                                           
                                        />
                                          <small className={`text-red-400 text-xs`}>
                                        {errors?.expireIn && errors?.expireIn?.type === "required" && (<p>This field is required</p>)}

                                    </small>
                                      
                                    </div>

                              

                         




                            
                           
                           
                        </div>
                    </div>

                    {/* form cta  */}
                    <div className={`flex justify-start`}>
                        <div className='flex gap-6'>
                            <ButtonField
                                buttonName={text.cancel}
                                type='reset'
                                buttonextracls={` !px-6 !py-2 !text-gray-60 bg-gray-1 font-[Roboto-Medium] hover:bg-deepBlue hover:text-white`}
                                onClick={() => { props.goBackToTable(); cleanupMedia() }}
                            />
                            <ButtonField
                                buttonName={text.save}
                                type='submit'
                                buttonextracls={` !px-6 !py-2 !text-white bg-skyblue-6 hover:bg-deepBlue`}
                                onClick={handleSubmit(onSubmit)}
                                loading={props.loading}
                                disabled={props.loading === true ? true : false}
                            />
                        </div>
                    </div>
                    {props.subAdminCreateMsg?.error === true ? <p className='text-red-600 text-sm'>{props.subAdminCreateMsg?.message}</p> : null}
                </div>



            </form>
        </div>
    )
}

// SubAdminForm.propTypes = {
//     subAdminCreate: PropTypes.func,
//     goBackToTable: PropTypes.func,

// }

export default AnnouncmentForm