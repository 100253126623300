import React from 'react'
import deleteImg from "../../../Images/vendors/deleteImg.svg"
import view from "../../../Images/vendors/View.svg"
import defaultprofile from '../../../Images/vendors/defaultprofile.svg'
import RatingField from '../../../Common/UiComponents/Rating'
import moment from 'moment'
import { ForLanding } from '../../../Containers/LandingPage/Hooks'
import NoContentPage from '../../../Common/Layout/NoContentPage/NoContentPage'
import SnackBarComponent from '../../../Common/Layout/Snackbar/SnackBar'
import dayjs from 'dayjs'; // Replacing moment with dayjs

const AllReviews = (props) => {
    const { deleteReviews, notification, messageClose } = ForLanding()
    const role = localStorage.getItem('role')


    return (
        <div className={`allReviewMain`}>
          

            {
                props.reviewData && props.reviewData.length > 0 ? (
                    <div className="w-full">
                        {props.reviewData.map((item) => (
                            <div
                                key={item?.id} 
                                className="flex flex-col gap-4 border-b-[1px] border-b-[#EBEBEB] p-4 w-full h-full"
                            >
                                {/* Header Section */}
                                <div className="w-full flex justify-between">
                                    <div className="flex items-center justify-start gap-3">
                                        <div className="w-16 h-16">
                                            <img
                                                src={defaultprofile}
                                                alt={`${item?.user?.name || 'User'}'s Profile`}
                                                loading="lazy" 
                                                className="object-cover rounded-full"
                                            />
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <p className="text-[#434343] font-[Roboto-Medium] text-xl">
                                                {item?.user?.name || 'Anonymous'}
                                            </p>
                                            <p className="text-[#43434399] text-base font-[Roboto-Regular]">
                                                {item?.user?.email || 'No email provided'}
                                            </p>
                                            <RatingField
                                                SendRating={true}
                                                size="small"
                                                value={item?.rate}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>

                                    {/* Admin Controls */}
                                    {role === 'admin' && (
                                        <div
                                            className="flex items-center justify-start gap-5 cursor-pointer"
                                            onClick={() => deleteReviews(item?.id, item?.businessId)}
                                        >
                                            <img src={deleteImg} alt="Delete Review" />
                                        </div>
                                    )}

                                    {/* Review Date */}
                                    {props.reviewDate && (
                                        <div className="h-full">
                                            <p className="text-[#43434399] text-xs font-[Roboto-Regular] flex items-start mt-1">
                                                {dayjs(item?.createdAt).format('LLL')}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {/* Body Section */}
                                <div className="flex flex-col items-start justify-between gap-6">
                                    <p className="text-[#7D7D7D] text-sm font-[Roboto-Regular]">
                                        {item?.description || 'No description provided.'}
                                    </p>
                                    {!props.reviewDate && (
                                        <p className="text-[#43434399] text-xs font-[Roboto-Regular]">
                                            {dayjs(item?.createdAt).format('LLL')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <NoContentPage NoContentMainDiv="h-[50vh]" text2="There are no reviews" />
                )
            }

            <SnackBarComponent
                messageOpen={notification.open}
                messageClose={messageClose}
                notificationText={notification.message}
                subText={notification.subText}
                alertType={notification.alertType}
                borderClass={notification.borderClass}
            />
        </div>
    )
}

export default AllReviews