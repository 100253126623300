import React, { useState } from 'react'
import serviceOffered from '../../../../src/Images/Vendor/serviceOffered.svg'
import LikeIcon from '../../UiComponents/LikeIcon'
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ImagePreviewer from '../../UiComponents/ImagePreviewer';
import play from '../../../../src/Images/play.jpg'

const BabyCareShowImg = (props) => {

  const firstImg = props.imgArr && props.imgArr?.length > 0 && props.imgArr[0]?.key
  const [bigImg, setBigImg] = useState(firstImg ? firstImg : "" )
  const [clicked, setClicked] = useState(null)
  const [previewImage, setPreviewImage] = useState([])
  const param = useParams();

  useEffect(() => {
    if(props.imgArr && props.imgArr?.length > 0){
      if(props.imgArr[0]?.mimetype?.includes('image')){
        setBigImg(`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${props.imgArr[0]?.key}&height=auto&width=auto`);
      }else{
        setBigImg(play)
      }
      setClicked(props.imgArr[0]?.id)
    }
    //setBigImg(firstImg ? firstImg : "");
  }, [param.id, firstImg])

  // const handleImgClick = (image) => {
  //   setBigImg(image?.key);
  //   setClicked(image?.id)
  // }

  const handleImgClick = (image) => {
    if(image?.mimetype?.includes('image')){
      setBigImg(`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${image?.key}&height=auto&width=auto`);
    }else{
      setBigImg(play)
    }
    setClicked(image?.id)
  }


  const [isModalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleOpenModal = (index) => {
        setCurrentIndex(0);
        setModalOpen(true);
        const Image =  props.imgArr.filter((item) => item.id === index);
        setPreviewImage(Image)
    };


  return (
    <>
    <div className={`flex justify-between ${props.mainDivCls} w-full`}>
      {/* Small images/thumbnail  */}
      <div className={`w-[20%] ${props.smallImgsDivCls} `}>
        {props.imgArr && props.imgArr?.length > 0 && props.imgArr?.slice(0, 4).map((image, idx) =>
          <div key={idx} className={`flex justify-center items-center bg-[#D9D9D9] cursor-pointer h-20 w-full`}>
            <img
              className={`${clicked === image?.id ? 'border border-gray-500' : ''}`}
              onClick={() => handleImgClick(image)}
              crossOrigin="anonymous"
              src={image?.key && image?.mimetype?.includes('image')? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${image?.key}&height=auto&width=auto` : play}
              alt="smallThumbnails"
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          </div>)
        }
      </div>
      {/* Big image  */}
      <div onClick={() => handleOpenModal(clicked)} className={`cursor-pointer bg-[#f3f3f3] ${props.bigImgDivCls} ${bigImg !== "" && play}`}>
        <div className={`flex items-center justify-center w-full`}>
          {bigImg === "" ?
            <img src={serviceOffered} alt="serviceOffered"/>
            :
            <img
              crossOrigin="anonymous"
              src={bigImg}
              alt="thumbnail"
              className={`w-full h-80 object-contain `}
              // style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          }
        </div>
        {props.likeIcon &&
          <div className={`absolute top-[3%] right-[3%]`}>
            <LikeIcon size='small' />
          </div>}
      </div>
    </div>

<ImagePreviewer
isModalOpen={isModalOpen}
setModalOpen={setModalOpen}
currentIndex={currentIndex}
setCurrentIndex={setCurrentIndex}
images={previewImage}
hideArrow={true}
/>
    </>
  )
}

export default BabyCareShowImg

BabyCareShowImg.propTypes = {
  imgArr: PropTypes.array,
  likeIcon: PropTypes.bool,
  mainDivCls: PropTypes.string,
  smallImgsDivCls: PropTypes.string,
  bigImgDivCls: PropTypes.string
}