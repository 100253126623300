import { Modal, Box, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';


const ImagePreviewer = ({ images, isModalOpen, setModalOpen, currentIndex, setCurrentIndex, hideArrow = false }) => {

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '50%',
                    // maxWidth: '900px',
                    height: '60%',
                    // minHeight: '50%',
                    // overflow: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 2,
                }}
            >
                {/* Close Button */}
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 1,
                    }}
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </IconButton>

                {/* Full Image */}
                {images && images?.length > 0 && (
                    <>
                        {images[currentIndex]?.mimetype?.startsWith('image/') && images[currentIndex]?.key && (
                            <img
                                crossOrigin="anonymous"
                                src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${images[currentIndex]?.key}`}
                                alt="img"
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    // maxWidth: '100%',
                                    height: '90%',
                                    objectFit: 'contain',
                                    margin: '0 auto',
                                }}
                            />
                        )}

                        {images[currentIndex]?.mimetype?.startsWith('video/') && images[currentIndex]?.key && (
                            <video
                                crossOrigin="anonymous"
                                controls
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    // maxWidth: '100%',
                                    height: '90%',
                                    objectFit: 'contain',
                                    margin: '0 auto',
                                }}
                                src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${images[currentIndex]?.key}`}
                                // type="video/mp4"
                                alt="video"
                                autoPlay
                                muted
                            />
                        )}
                    </>

                )}

                {/* Navigation Buttons */}
                {!hideArrow &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            // mt: 2,
                            // pb: 12
                        }}
                    >
                        <IconButton onClick={handlePrev}>
                            <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton onClick={handleNext}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
        </Modal>
    )
}

export default ImagePreviewer