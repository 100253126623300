import { ClickAwayListener, Icon, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckBox from '../../../Common/FormFields/CheckBox';
import ButtonField from '../../../Common/FormFields/ButtonField';
import { useTranslation } from 'react-i18next';
import DigidialsLogoBlue from '../../../Images/CommonImages/DigidialsLogoBlue.svg'
import InputField from '../../../Common/FormFields/InputField';
import OTPFieldInput from '../../../Common/FormFields/OTPFieldInput';
import greentick from '../../../Images/accountcreate/greentick.svg'
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import CloseIcon from '@mui/icons-material/Close';
import locationImage from "../../../Images/Setting/locationImg.svg"
import { getLocationReducer } from '../../../Containers/Authentication/Signup/reducer/VendorAccountReducer';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInputField from '../../../Common/FormFields/PhoneInputField';
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks';
import UploadButton from '../../../Common/UiComponents/UploadButton';
import fileUploadIcon from '../../../Images/SubAdmin/fileUploadIcon.svg'
import { setUploadedLogo } from '../../../Containers/UploadMedia/reducer/UploadMediaReducer'
import CircularProgress from '@mui/material/CircularProgress';

const SignupAsUser = (props) => {
    const { cleanupMedia, logoUpload, doUploadedLogo } = ForUploadMediaHooks()

    // const locationSelector = useSelector((state) => state.vendorAccount.location)

    const handleclick = () => {
        navigate("/login");
    }
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [showPassword, setShowPassword] = useState(true)
    const [showCPassword, setShowCPassword] = useState(true)

    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false);

    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexPhone = /^\+?\d{10,14}$/;

    const handleUpload = (data) => {
        if (data[0]?.type === 'image/png' || data[0]?.type === 'image/jpeg') {
            setLoading(true);
            doUploadedLogo(data).finally(() => setLoading(false));
        }
    };

    // const [location, setLocation] = useState()
    // useEffect(() => {
    //     if(localStorage.getItem('latitude') && localStorage.getItem('longitude')) {
    //         setLocation({
    //             lat: parseFloat(localStorage.getItem('latitude')),
    //             lng: parseFloat(localStorage.getItem('longitude'))
    //         }); 
    //     } else {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             setLocation({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude
    //             });
    //         })
    //     }
    // }, [navigator.geolocation])

    // This useEffect is for showing popup confirmation 
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            return 'Are you sure you want to leave? Your user sign-up process may not be complete.';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    // For showing or hiding the otp button in email input field 
    useEffect(() => {
        if (regexEmail?.test(props.createAccountUserForm?.values?.email)) {
            props.setShowEmailOtpButtonUser(true)
        } else {
            props.setShowEmailOtpButtonUser(false)
        }
    }, [props.createAccountUserForm?.values?.email])

    // For showing or hiding the otp button in phone input field 
    useEffect(() => {
        if (regexPhone?.test(props.phoneNo)) {
            props.setShowPhoneOtpButtonUser(true)
        } else {
            props.setShowPhoneOtpButtonUser(false)
        }
    }, [props.phoneNo])


    return (
        <div className={`w-full flex flex-col items-center justify-between pt-[2%] pb-[1%] px-[10%]`}>
            <div className={`flex items-center flex-col justify-between gap-2 pb-4`}>
                <img src={DigidialsLogoBlue} className='cursor-pointer h-14 w-28' alt='blue logo' onClick={() => navigate('/')} />
                <div className='flex items-center flex-col'>
                    <p className='text-primeryColour-4 font-[Roboto-Medium] text-[22px]'>{t("welcomeBack")}</p>
                    <p className='text-deepgrey-2 font-[Roboto-Regular] text-[15px]'>{t("enterDetails")}</p>
                </div>
            </div>
            {/* middleside start*/}
            <div className={`lg:w-[60%] w-[70%] flex gap-[2rem]`}>
                <form onSubmit={props.createAccountUserForm?.handleSubmit} className={`flex flex-col gap-4 items-start justify-between w-[85%]`}
                >

                    {/* Name section */}
                    <div className={`flex flex-col w-full`} >
                        <div className={`flex space-betweenrounded w-full cursor-pointer items-center`} >
                            <InputField
                                extracls={`w-full h-13 text-sm !p-0 signUpEmailExtracls userSignupFormCls`}
                                placeholder="enterName"
                                textinputname="name"
                                variant="outlined"
                                type="text"
                                id="name"
                                floatingLabel={`enterName`}
                                onChange={props.createAccountUserForm?.handleChange}
                                value={props.createAccountUserForm?.values?.name}
                                endAdornment={true}
                                error={
                                    props.createAccountUserForm?.touched?.name &&
                                    Boolean(props.createAccountUserForm?.errors?.name)
                                }
                                helperText={
                                    props.createAccountUserForm?.touched?.name &&
                                    props.createAccountUserForm?.errors?.name
                                }
                            />
                        </div>
                    </div>

                    {/* Email section */}

                    <div className={`flex flex-col w-full`}>
                        <div className={`flex space-between rounded w-full  cursor-pointer items-center`} >

                            <InputField
                                extracls={`w-full h-13 text-sm !p-0 signUpEmailExtracls userSignupFormCls`}
                                placeholder="enterEmail"
                                textinputname="email"
                                variant="outlined"
                                type="email"
                                id="email"
                                floatingLabel={`enterEmail`}
                                onChange={props.createAccountUserForm?.handleChange}
                                value={props.createAccountUserForm?.values?.email}
                                clickEnter={props.handleClickForOTP}
                                endAdornment={true}
                                inputEndAdornment={
                                    props.emailVerified === true ?
                                        <div className={`m-2`}>
                                            <img src={greentick} alt="greentick" />
                                        </div>
                                        :
                                        props.showEmailOtpButtonUser &&
                                        <div className={`m-1`}>
                                            <ButtonField
                                                buttonextracls={` font-medium font-[Roboto-Medium] capitalize w-20 h-8 text-xs 
                                                        ${props.createAccountUserForm?.values?.email ? "bg-skyblue-6 text-white"
                                                        : "bg-[#000000]/[.12] text-[#000000]/[.26] cursor-not-allowed"}`}
                                                buttonName="send OTP"
                                                onClick={props.handleClickForOTP}
                                            // disabled={props.showOTP ? true : false}
                                            />
                                        </div>}
                                error={
                                    props.createAccountUserForm?.touched?.email &&
                                    Boolean(props.createAccountUserForm?.errors?.email)
                                }
                                helperText={
                                    props.createAccountUserForm?.touched?.email &&
                                    props.createAccountUserForm?.errors?.email
                                }
                            />

                        </div>

                        {props.emailErrTexts?.error && <p className={`text-sm font-[Roboto-Regular] text-red-500 italic`}>{props.emailErrTexts.message}</p>}

                        {props.showOTP ?
                            <OTPFieldInput
                                title="*Enter OTP sent to your email id"
                                // value={props.autoFillOtp}
                                value={props.otpForEmail}
                                onChange={props.setOtpForEmail}
                                placeholder={[0, 0, 0, 0, 0, 0]}
                                OTPLength={6}
                                otpType="number"
                                onResendClick={() => props.EmailOTP(props.createAccountUserForm?.values)}
                            />
                            :
                            null
                        }
                    </div>

                    {/* Phone Number section */}
                    <div className={`flex flex-col w-full`}>
                        <div className={`flex space-between rounded w-full  cursor-pointer items-center relative`} >
                            <div className={`flex flex-col w-full`}>
                                <PhoneInputField
                                    // phoneName={'phoneNo'}
                                    defaultCountry={'kw'}
                                    placeholder={'*Enter your Phone number'}
                                    label={'Phone Number'}
                                    inputCls={`w-full h-[3rem] cursor-default ${props.phoneNoError ? 'border-red-500' : ''}`}
                                    onChange={props.phoneChangeHandler}
                                    value={props.phoneNo}
                                    clickEnter={props.handleClickForOTPMobile}
                                    enableSearch={true}
                                    disabled={props.emailVerified ? false : true}
                                />
                                {props.phoneNoError && <p className={`text-red-600 text-[13px] pl-3`}>
                                    {`Please enter a valid Phone Number`}
                                </p>}
                            </div>

                            <div className={`absolute right-[0.03rem] ${props.phoneNoError ? 'top-[0.25rem]' : ''}`}>
                                {
                                    props.phoneNoVerified === true ?
                                        <div className={`m-2`}>
                                            <img src={greentick} alt="greentick" width={14.5} />
                                        </div>
                                        :
                                        // props.showPhoneOtpButtonUser && 
                                        <div className={`m-1`}>
                                            <ButtonField
                                                buttonextracls={`font-medium font-[Roboto-Medium] capitalize w-20 h-8 text-xs 
                                                        ${props.phoneNo.length > 4 ? "bg-skyblue-6 text-white"
                                                        : "bg-[#000000]/[.12] text-[#000000]/[.26] cursor-not-allowed"}`}
                                                buttonName="Send OTP"
                                                onClick={props.handleClickForOTPMobile}
                                            // disabled={props.showOTPMobile ? true : false}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                        {props.showOTPMobile ?
                            <OTPFieldInput
                                title="*Enter OTP sent to your mobile number"
                                onChange={props.setOTPForMobile}
                                value={props.OTPForMobile}
                                placeholder={[0, 0, 0, 0, 0, 0]}
                                OTPLength={6}
                                otpType="number"
                                onResendClick={() => props.PhoneOTP(props.createAccountUserForm?.values, props.phoneNo)}
                            /> : null}
                    </div>
                    {/* Location  */}
                    {/* <div className={`flex flex-col w-full`} >
                        <div className={`flex space-between border border-gray-200 rounded w-full cursor-pointer items-center`} >
                            <InputField
                                extracls={`w-full h-13 text-sm !p-0 signUpEmailExtracls userSignupFormCls`}
                                placeholder="enterName"
                                textinputname="location"
                                variant="outlined"
                                type="text"
                                id="location"
                                floatingLabel={`Enter your Location`}
                                onChange={props.createAccountUserForm?.handleChange}
                                value={locationSelector && locationSelector?.length > 0 ? locationSelector : props.createAccountUserForm?.values?.location}
                                endAdornment={true}
                                error={
                                    props.createAccountUserForm?.touched?.location &&
                                    Boolean(props.createAccountUserForm?.errors?.location)
                                }
                                helperText={
                                    props.createAccountUserForm?.touched?.location &&
                                    props.createAccountUserForm?.errors?.location
                                }
                                inputEndAdornment={
                                    <div className={`flex justify-center items-center`}>
                                        {locationSelector && locationSelector?.length > 0 &&
                                            <CloseIcon
                                                sx={{ fontSize: "20px", cursor: "pointer" }}
                                                onClick={() => dispatch(getLocationReducer('')) }
                                            />
                                        }
                                        {!props.createAccountUserForm?.values?.location &&
                                            <img src={locationImage} alt="locationImage" className={`cursor-pointer mr-5`} onClick={props.handleClickLocation} />
                                        }
                                    </div>
                                }
                            />
                        </div>
                    </div> */}

                    {/* {location && Object.keys(location)?.length > 0 &&
                        <div className='w-full flex justify-between gap-6'>
                            <TextFieldInput
                                onlyValue
                                floatingLabel={`Latitude`}
                                textnewclass={`w-full text-sm text-black`}
                                value={location?.lat}
                                disabled
                            />
                            <TextFieldInput
                                onlyValue
                                floatingLabel={`Longitude`}
                                textnewclass={`w-full text-sm text-black`}
                                value={location?.lng}
                                disabled
                            />
                        </div>
                    } */}

                    {/* password */}
                    <div className={`flex flex-col w-full`}>
                        <div className={`flex space-between rounded w-full  cursor-pointer items-center`} >

                            <InputField
                                extracls={`w-full h-13 text-sm userSignupFormCls`}
                                placeholder="enterPass"
                                textinputname="password"
                                variant="outlined"
                                typePassword={showPassword}
                                id="password"
                                floatingLabel={`enterPass`}
                                onChange={props.createAccountUserForm?.handleChange}
                                value={props.createAccountUserForm?.values?.password}
                                endAdornment={true}
                                inputEndAdornment={
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        tabIndex={-1}
                                    >
                                        <Icon className="text-20" color="action" tabIndex={-1}>
                                            {showPassword ?
                                                <VisibilityOffIcon sx={{ color: '#747774' }} />
                                                :
                                                <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                        </Icon>
                                    </IconButton>
                                }
                                error={
                                    props.createAccountUserForm?.touched?.password &&
                                    Boolean(props.createAccountUserForm?.errors?.password)
                                }
                                helperText={
                                    props.createAccountUserForm?.touched?.password &&
                                    props.createAccountUserForm?.errors?.password
                                }
                            />
                        </div>
                    </div>


                    {/* confirmpassword */}
                    <div className={`flex flex-col w-full`}>
                        <div className={`flex space-between rounded w-full cursor-pointer items-center`} >
                            <InputField
                                extracls={`w-full h-13 text-sm userSignupFormCls`}
                                placeholder="reTypePass"
                                textinputname="cpassword"
                                variant="outlined"
                                id="cpassword"
                                floatingLabel={`reTypePass`}
                                onChange={props.createAccountUserForm?.handleChange}
                                value={props.createAccountUserForm?.values?.cpassword}
                                endAdornment={true}
                                clickEnter={props.createAccountUserForm?.handleSubmit}
                                typePassword={showCPassword}
                                inputEndAdornment={
                                    <IconButton
                                        onClick={() => setShowCPassword(!showCPassword)}
                                        tabIndex={-1}
                                    >
                                        <Icon className="text-20" color="action" tabIndex={-1}>
                                            {showCPassword ?
                                                <VisibilityOffIcon sx={{ color: '#747774' }} />
                                                :
                                                <RemoveRedEyeIcon sx={{ color: '#747774' }} />}
                                        </Icon>
                                    </IconButton>
                                }
                                error={
                                    props.createAccountUserForm?.touched?.cpassword &&
                                    Boolean(props.createAccountUserForm?.errors?.cpassword)
                                }
                                helperText={
                                    props.createAccountUserForm?.touched?.cpassword &&
                                    props.createAccountUserForm?.errors?.cpassword
                                }
                            />
                        </div>
                    </div>


                    {/* Terms&condition */}
                    <div className={`flex justify-center items-center w-full pt-4`}>
                        <CheckBox
                            sx={{ '& .css-b29amo-MuiButtonBase-root-MuiCheckbox-root': { padding: 0 } }}
                            required={true}
                            size={`small`}
                            checked={props.value}
                            handleBoxChange={(e) => setChecked(e.target.checked)
                            }
                        />
                        <p className={`pl-2 text-sm`}>{t("iAgreeToThe")}</p>&nbsp;
                        <a href='/termsandconditions' target='_blank' className={`text-sm text-skyblue-6 cursor-pointer`}>{t("termsAndConditions")}</a>
                    </div>
                    {/* Register&ContinueButton */}
                    <div className={`flex flex-col gap-3 items-center justify-center w-full`}>
                        <ButtonField
                            onClick={checked && props.Login}
                            buttonName={'SIGN UP'}
                            type={'submit'}
                            buttonextracls={`!px-2 !py-2 !text-white ${(props.loading === true || checked === false) && 'bg-deepgray-4'} bg-seconderyColour-5 text-[15px] font-[Roboto-Medium] w-[10em] hover:bg-skyblue-6`}
                            loading={props.loading}
                            disabled={(props.loading === true || checked === false) ? true : false}
                        />
                        {props?.loginError?.error === true && <p className={`text-red-5 text-sm text-center font-[Roboto-Regular] `}>{props?.loginError?.message}</p>}
                        <p className='text-deepgray-4 text-sm font-[Roboto-Medium]'>{`Return`}&nbsp;
                            <span className='text-skyblue-6 font-[Roboto-Medium] text-sm cursor-pointer' onClick={handleclick}>{`Sign in`}</span></p>
                    </div>

                </form>
                {/* upload logo section  */}
                <div className='w-[15%] flex justify-between h-full flex-col'>
                    <p className='text-Grey-900 font-[Roboto-Medium] text-center text-2xl w-[14em] mb-5'>
                        Upload Your Profile Image
                    </p>
                    <div className='relative'>
                        {logoUpload &&
                            (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') && (
                                <div className='absolute -top-[1.5rem] left-[13rem] -right-4 cursor-pointer'>
                                    <CloseIcon
                                        sx={{ fontSize: "12px", border: '1px solid red', borderRadius: '9999px' }}
                                        onClick={() => dispatch(setUploadedLogo({}))}
                                    />
                                </div>
                            )}
                        {loading ? (
                            <div className='flex justify-center items-center bg-white rounded-md w-[20em] h-[50px]'>
                                <CircularProgress size={24} />
                            </div>
                        ) : (
                            <UploadButton
                                sendData={handleUpload}
                                extracls='flex justify-center items-center bg-white rounded-md w-[20em]'
                                type='button'
                                fileUploadIcon={
                                    logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png') &&
                                        logoUpload?.key
                                        ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${logoUpload?.key}&height=20&width=auto`
                                        : fileUploadIcon
                                }
                                accept={{"image/jpeg": [".jpeg", ".jpg"],
                                "image/png": [".png"]}}
                                multiple={false}
                                disabled={
                                    logoUpload &&
                                        (logoUpload?.mimetype === 'image/jpeg' || logoUpload?.mimetype === 'image/png')
                                        ? true
                                        : false
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default SignupAsUser