import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UploadDocument from '../../UiComponents/UploadDocument';
import { useTranslation } from 'react-i18next';
import ImagePreviewer from '../../UiComponents/ImagePreviewer';
// import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks';

const ProfileUserImagesCard = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };


  const { t } = useTranslation();

  const isVideo = (mimetype) => {
    // Define supported video mimetypes
    const videoMimeTypes = ['video/mp4', 'video/webm', 'video/ogg'];
    return videoMimeTypes.includes(mimetype.toLowerCase());
  };
  

  return (
    <div className={`!bg-white p-5 border flex-col justify-between gap-4`}>
      {/* Images and Videos Section */}
      <div>
        <p className={`font-[Roboto-Medium] text-sm text-deepgray-4 mb-4`}>{t('images')}</p>
        <div className={`grid grid-rows-1 grid-flow-col gap-2`}>
          {props.imgArr &&
            props.imgArr.length > 0 &&
            props.imgArr.slice(0, 4).map((item, id) => {
              const isMediaVideo = isVideo(item.mimetype);
              return (
                <div
                  className="cursor-pointer "
                  key={id}
                  onClick={() => handleOpenModal(id)}
                >
                  {item?.key && isMediaVideo ? (
                    <div className='relative w-[60px] h-[60px] flex items-center justify-center'>
                      
                      <div className={`absolute bg-transparent inset-0 z-10`}></div>
                        <video
                          crossOrigin="anonymous"
                          src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item.key}&height=60&width=60`}
                          alt="video thumbnail"
                          width="60"
                          height="60"
                          controls
                          autoPlay
                          muted
                        />
                    </div>
                  ) : (
                    <img
                      crossOrigin="anonymous"
                      src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item.key}&height=60&width=60`}
                      alt="img"
                      width="60"
                      height="60"
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {/* PDF Section */}
      <div>
        <p className={`font-[Roboto-Medium] text-sm text-deepgray-4 my-4`}>{t('civilid')}</p>
        <UploadDocument
          uploadbutton={true}
          extracls={`!justify-between`}
          pdfname={
            props?.dataObject?.civilDocument?.name
              ? props?.dataObject?.civilDocument?.name
              : `No document`
          }
          view={true}
          viewid={'View ID'}
          kb={
            props?.dataObject?.civilDocument?.size
              ? `${(props?.dataObject?.civilDocument?.size / 1024)?.toFixed(2)} kb`
              : '0kb'
          }
          onDownloadClick={() =>
            props?.downloadPdfAttachments(props?.dataObject?.civilDocument)
          }
        />
      </div>

      {/* Modal to Preview Images or Videos */}
      <ImagePreviewer
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        images={props.imgArr}
        renderMedia={(item) =>
          isVideo(item.key) ? (
            <video
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item.key}`}
              controls
              style={{ maxWidth: '100%', maxHeight: '80vh' }}
              autoPlay
              muted
            />
          ) : (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${item.key}`}
              alt="preview"
              style={{ maxWidth: '100%', maxHeight: '80vh' }}
            />
          )
        }
      />
    </div>
  );
};

ProfileUserImagesCard.propTypes = {
  imgArr: PropTypes.array,
  dataObject: PropTypes.object,
  downloadPdfAttachments: PropTypes.func,
};

export default ProfileUserImagesCard;
