import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import ButtonField from '../../../Common/FormFields/ButtonField';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import Businessbuttonicon from "../../../Images/accountcreate/bussinessbuttonicon.svg"
import Businessbuttonblueicon from "../../../Images/accountcreate/bussinessbuttonblueicon.svg"
import Individualbuttonicon from "../../../Images/accountcreate/indivisualbuttonicon.svg"
import Individualbuttonblueicon from "../../../Images/accountcreate/indivisualbuttonblueicon.svg"

const EditProfileInfo = (props) => {
    const { handleSubmit, reset, control, handleError, formState: { errors } } = useForm({
        defaultValues: {
            name: props.vendorProfileData ? props.vendorProfileData?.user?.name : '',
        }
    });
    const onSubmit = (data) => {
        props.editVendorProfileDetailsApi(data)
        reset()
    }

    return (
        <div className={`w-full flex lg:flex-row flex-col justify-between px-[5%] py-[3%]`}>

            <form onSubmit={handleSubmit(onSubmit, handleError, reset)}>
                {/* Name section */}
                <div className={`!w-full my-2`}>
                    <Controller
                        name={"name"}
                        control={control}
                        rules={{
                            required: props.vendorProfileData?.user?.name ? false : true
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextFieldInput
                                onlyValue
                                extracls={`!w-full h-11 text-sm `}
                                floatingLabel="Enter your name"
                                variant="outlined"
                                type="text"
                                onChange={onChange}
                                value={value}
                                defaultValue={(props.vendorProfileData?.user?.name ? props.vendorProfileData?.user?.name : value)}
                                // disabled={props.vendorProfileData?.user?.name ? true : false}
                                sx={{ width: '100%' }}
                            />
                        )}
                    />
                    {errors.name && errors.name.type === "required" && (<span className="error-message text-red-400 text-xs">Required</span>)}
                </div>

                {/* Email section */}
                <div className={`!w-full my-2`}>
                   
                        <Controller
                            name={"email"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextFieldInput
                                    extracls={`!w-full h-11 text-sm !p-0 signUpEmailExtracls`}
                                    floatingLabel="*Enter your email"
                                    variant="outlined"
                                    type="email"
                                    onChange={onChange}
                                    value={(props.vendorProfileData?.user?.email ? props.vendorProfileData?.user?.email : value)}
                                    disabled={props.vendorProfileData?.user?.email ? true : false}
                                    sx={{ width: '100%' }}
                                />
                            )}
                        />
                   
                </div>

                {/* Phone Number section */}
                <div className={` w-full my-2`}>
                    
                        <Controller
                            name={"phoneNo"}
                            control={control}
                            rules={{
                                pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                                maxLength: 10

                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextFieldInput
                                    onlyValue
                                    extracls={`w-full h-11 text-sm signUpPhoneExtracls`}
                                    floatingLabel="Enter your Phone number"
                                    variant="outlined"
                                    type="number"
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={(props.vendorProfileData?.user?.phoneNo ? props.vendorProfileData?.user?.phoneNo : value)}
                                    disabled={props.vendorProfileData?.user?.phoneNo ? true : false}
                                    sx={{ width: '100%' }}
                                />
                            )}
                        />
                 
                    <small className={`text-red-500 mt-2`}>
                        {errors?.phoneNo?.type === "pattern" && <p>Please enter a valid phone number</p>}
                        {errors?.phoneNo?.type === "maxLength" && (<p>Phone number should contain maximum 10 numbers</p>)}
                    </small>

                </div>

                {/* Show Vendor Type */}
                <div>
                    <p className={`font-[Roboto-Regular] text-base text-[Grey-900] mb-3`}>
                        Vendor Type
                    </p>
                    <div className={`flex`}>
                        <ButtonField
                            buttonextracls={`bg-gray-0.5 border-[1.5px] border-solid border-deepgray-0.5 font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs mr-6 ${props.vendorProfileData?.user?.type === "business" ? " bg-[#DCF2FA]/80 border-[#0099CC] " : "bg-gray-0.5 border-deepgray-0.5"} `}
                            buttonName="Business"
                            imgtext={`justify-between w-28`}
                            buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm ${props.vendorProfileData?.user?.type === "business" ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                            img={props.vendorProfileData?.user?.type === "business" ? Businessbuttonblueicon : Businessbuttonicon}

                        />
                        <ButtonField
                            buttonextracls={` border-[1.5px] border-solid  font-medium font-[Roboto-Medium] capitalize w-40 h-16 text-xs ${props.vendorProfileData?.user?.type === "individual" ? " bg-[#DCF2FA]/80 border-[#0099CC] " : "bg-gray-0.5 border-deepgray-0.5"} `}
                            imgtext={`justify-between w-28`}
                            buttonName="Individual"
                            buttonnamecls={`font-[Roboto-Regular]  font-normal text-sm ${props.vendorProfileData?.user?.type === "individual" ? "text-[#0099CC]" : "text-deepgrey-2"}`}
                            img={props.vendorProfileData?.user?.type === "individual" ? Individualbuttonblueicon : Individualbuttonicon}
                        />
                    </div>

                    {/* Unique Id  */}
                    <div className={`my-4`}>
                        <p className={`text-text-60 font-[Roboto-Regular] text-sm font-normal mb-1`}>Unique Id</p>
                        <p className={`text-skyblue-6 font-[Roboto-Regular] text-sm font-normal`}>{props.vendorProfileData?.user?.uniqueId}</p>
                    </div>

                    <div className={`flex`}>
                        <ButtonField
                            buttonextracls={`bg-skyblue-6 cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-56 h-12`}
                            imgtext={`justify-between`}
                            buttonName="Update"
                            buttonnamecls={`font-[Roboto-Regular] text-white font-medium text-sm`}
                            type="submit"
                            loading={props.loading}
                            disabled={props.loading === true ? true : false}
                            onClick={handleSubmit(onSubmit)}
                        />
                        <ButtonField
                            buttonextracls={`cursor-pointer  border-[1.5px] border-solid border-deepgray-0.5 capitalize w-24 ml-5  h-12`}
                            buttonName="Cancel"
                            buttonnamecls={`font-[Roboto-Medium] text-deepgray-0.5 font-medium text-sm`}
                            type="reset"
                            onClick={props.toggleEditProfile}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditProfileInfo