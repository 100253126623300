import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LoginPage from '../Containers/Authentication/Login';
// import SignupPage from '../Components/Authentication/Signup/index'
import SignupPage from '../Containers/Authentication/Signup/index'
import VendorDescription from '../Containers/VendorDetailPage/index'
import AdminMain from '../Containers/Admin/index.jsx';
import HomeIndex from '../Containers/LandingPage/index.jsx'
import DashboardIndex from '../Containers/Admin/DashboardIndex';
import TotalUsersIndex from '../Containers/Admin/TotalUsersIndex';
import SubscriptionIndex from '../Containers/Admin/SubscriptionIndex';
import SettingsIndex from '../Containers/Admin/SettingsIndex';
import SettingSubAdminIndex from '../Containers/Admin/SettingSubAdminIndex';
import AnnouncementIndex from '../Containers/Admin/AnnouncementIndex.jsx';
import SettingsHyperlinkIndex from '../Containers/Admin/SettingsHyperlinkIndex';
import TotalVendorIndex from '../Containers/Admin/TotalVendorIndex';
// import TotalUserDetails from '../Components/Admin/TotalUsers/TotalUserDetails';
import AdminCategory from '../Containers/Admin/AdminCategory';
import SubscriptionPlan from '../Components/Authentication/Signup/SubscriptionPlan';
import SeeAll from '../Containers/LandingPage/SeeAll';
import VendorDetailsindex from '../Containers/LandingPage/VendorDetailsindex'
import ProductDetailsPage from '../Containers/LandingPage/ProductDetailsPage';
import VendorDetailPageAdmin from '../Components/Admin/TotalVendors/VendorDetailPageAdmin';
import OfferDetailsAndReview from '../Components/LandingPage/OfferDetailsAndReview';
import PageNotFound from '../Common/UiComponents/404/404'
import ManageNotificationsIndex from '../Containers/Admin/ManageNotificationsIndex';
import SearchResulttMain from '../Containers/LandingPage/SearchResulttMain';
import VendorSettingsMain from '../Containers/Authentication/Settings/VendorSettingsMain';
import VendorSettingPasswordMain from '../Containers/Authentication/Settings/VendorSettingPasswordMain';
import VendorSettingLanguageMain from '../Containers/Authentication/Settings/VendorSettingLanguageMain';
import ForgotPassword from '../Components/Authentication/Login/ForgotPassword';
import CodeVerification from '../Components/Authentication/Login/CodeVerification';
import SeeAllHyperlink from '../Containers/LandingPage/SeeAllHyperlink';
import SignupAsUserIndex from '../Containers/User/Auth';
import UserIndex from '../Containers/User/UserPages';
import FavouriteVendorMain from '../Containers/LandingPage/FavouriteVendorMain';
import FavouriteVendorDetail from '../Common/Layout/FavouriteVendor/FavouriteVendorDetail';
import NotificationIndex from '../Containers/Notification';
import NotificationDetailsPage from '../Components/Notification/NotificationDetailsPage'
// import VendorMessageMain from '../Containers/VendorDetailPage/VendorMessageMain';
import UserDetailsPageAdmin from '../Components/Admin/TotalUsers/UserDetailsPageAdmin';
// import SeeAllServiceOffer from '../Components/LandingPage/SeeAllServiceOffer';
import SeeAllServiceIndex from '../Containers/LandingPage/SeeAllServiceIndex';
// import UserMainPages from '../Components/User/UserPages';
import ServiceDetails from '../Common/Layout/ProductDetailPage/ServiceDetails';
import RenderGoogleMap from '../Components/LandingPage/RenderGoogleMap';
import AdminCMSIndex from '../Containers/Admin/AdminCMSIndex';
import SeeAllCategory from '../Components/LandingPage/SeeAllCategory';
import Faq from '../Components/Faq';
import PrivPol from '../Components/PrivacyPolicy';
import TermsAndConditions from '../Components/TermsAndConditions';
import PlansIndex from '../Containers/Admin/PlansIndex.jsx';
// import GoogleMapTwo from '../Common/UiComponents/GoogleMapTwo/GoogleMapTwo';
// import GoogleMapIndex from '../Containers/LandingPage/GoogleMapIndex';
import PaymentHistory from '../Containers/PaymentHistory/PaymentHistory.jsx';
import TransactionIndex from '../Containers/Admin/TransactionIndex.jsx';
import SupportPage from '../Components/Admin/Support/index.jsx';
import ApproveMedia from '../Components/Admin/ApproveMedia/index.jsx';
import Aboutus from '../Components/Aboutus/index.jsx';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/'>
            {/* Home page is given the index */}
            <Route index element={<HomeIndex />} />
            <Route path='offerdetails' element={<SeeAll />} />
            <Route path='allHyperlinks' element={<SeeAllHyperlink />} />
            <Route path='vendordetails' element={<VendorDetailsindex />} />
            <Route path='productdetails' element={<ProductDetailsPage />} />
            <Route path='allServiceOffer' element={<SeeAllServiceIndex />} />
            <Route path='searchresult/:id' element={<SearchResulttMain />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='forgotPassword' element={<ForgotPassword />} />
            <Route path='verifyCode' element={<CodeVerification />} />
            <Route path='signup' element={<SignupPage />}>

                {/* <Route path='signup/subscriptionPlans' element={<SubscriptionPlan />}/> */}
            </Route>
            <Route path='/details/:id' element={<OfferDetailsAndReview />} />
            <Route path='signup/subscriptionPlans' element={<SubscriptionPlan />} />

            {/* Routing for payment history */}
            <Route path='/paymentHistory' element={<PaymentHistory />} />

            {/* Routing for admin  */}
            <Route path='admin' element={<AdminMain />}>

                {/*For dasboard of admin  */}
                <Route index element={<DashboardIndex />} />
                <Route path='dashboard' element={<DashboardIndex />} />
                <Route path='approvemedia' element={<ApproveMedia/>}/>
                <Route path='support' element={<SupportPage />} />

                {/* For settings in admin  */}
                <Route path='settings' element={<SettingsIndex />}>
                    {/* For sub-admin in settings of admin  */}
                    <Route index element={<SettingSubAdminIndex />} />
                    <Route path='subAdmin' element={<SettingSubAdminIndex />} />
                    {/* For add hyperlink in settings of admin  */}
                    <Route path='addHyperlink' element={<SettingsHyperlinkIndex />} />
                    <Route path='notification' element={<ManageNotificationsIndex />} />
                    <Route path='addAnnouncement' element={<AnnouncementIndex />} />
                </Route>

                {/* For subscription in admin  */}
                <Route path='subscription' element={<SubscriptionIndex />} />
                <Route path='transaction' element={<TransactionIndex />} />
                <Route path='subscriptionplans' element={<PlansIndex/>} />

                {/* For CMS page in Admin  */}
                <Route path='cmspages' element={<AdminCMSIndex />} />

                {/* For total users in admin  */}
                <Route path='totalUsers' element={<TotalUsersIndex />}>
                </Route>
                <Route path='/admin/totalUsers/:id' element={<UserDetailsPageAdmin />} />

                {/* For total vendors in admin  */}
                <Route path='totalVendors' element={<TotalVendorIndex />} />

                {/* For showing the details page of particular vendor  */}
                <Route path='/admin/totalVendors/:id' element={<VendorDetailPageAdmin />} />

                {/* For total categories in admin  */}
                <Route path='categories' element={<AdminCategory />} />
            </Route>

            {/* Routing for vendor  */}
            <Route path='vendor'>
                <Route index element={<VendorDescription />} />
                <Route path='services' element={<VendorDescription />} />
                <Route path='allrequirements' element={<VendorDescription />} />
                <Route path='myrequirements' element={<VendorDescription />} />
                <Route path='message' element={<VendorDescription />} />
                <Route path='subscription' element={<VendorDescription />} />
                <Route path='products' element={<VendorDescription />} />
            </Route>

            <Route path='faq' element={<Faq />} />
            <Route path='privacypolicy' element={<PrivPol />} />
            <Route path='termsandconditions' element={<TermsAndConditions />} />

            <Route path='aboutus' element={<Aboutus />} />

            <Route path='vendorsettings' element={<VendorSettingsMain />} >
                <Route index element={<VendorSettingPasswordMain />} />
                <Route path='changepassword' element={<VendorSettingPasswordMain />} />
                <Route path='languagechange' element={<VendorSettingLanguageMain />} />
            </Route>
            <Route path='favouritevendors' element={<FavouriteVendorMain />} />
            <Route path='favouritevendor/:id' element={<FavouriteVendorDetail />} />
            <Route path='allnotification' element={<NotificationIndex />} />
            <Route path='notificationDetails' element={<NotificationDetailsPage />} />
            <Route path='*' element={<PageNotFound />} />

            <Route path='usersignup' element={<SignupAsUserIndex />} />

            <Route path='user' element={<UserIndex />} >
                <Route index element={<UserIndex />} />
                <Route path='message' element={<UserIndex />} />
                <Route path='FavoriteVendors' element={<UserIndex />} />
                {/* <Route path='editUserProfile' element={<UserMainPages />} /> */}
                <Route path='favoritevendors' element={<UserIndex />} />
            </Route>
            <Route path='servicedetails/:id' element={<ServiceDetails />} />
            <Route path='maps' element={<RenderGoogleMap />} />
            {/* <Route path='maps' element={<GoogleMapIndex />} /> */}
            {/* <Route path='maps' element={<GoogleMapTwo />} /> */}
            <Route path='seeAllCategory' element={<SeeAllCategory />} />

        </Route>
    )
)

export default router