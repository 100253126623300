import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextFieldInput from '../../../Common/FormFields/TextFieldInput';
import searchicon from '../../../Images/profileDashboardIcons/searchicon.svg';
import attachmentsection from '../../../Images/profileDashboardIcons/attachment.svg'
import defaultserviceIcon from '../../../Images/vendors/defaultprofile.svg';
import moment from 'moment';
import _ from 'lodash';
// import { ForUser } from '../../../Containers/User/UserPages/Hooks';
import { InputAdornment } from '@mui/material';

const ChatList = (props) => {
    const dispatch = useDispatch();
    // const { getSupportUser } = ForUser();
    const userId = localStorage.getItem('userId');

    return (
        <div>
            {/* Search Bar */}
            <div className="p-[5%] border border-gray-0.5">
                <TextFieldInput
                    onlyValue
                    textnewclass="flex-1 w-full text-sm tableSearchBar"
                    placeholder="Search"
                    id="outlined-basic"
                    variant="outlined"
                    startAdornment={
                        <InputAdornment position="start">
                            <img src={searchicon} alt="search" />
                        </InputAdornment>
                    }
                    onChange={props.handleSearchChat}
                />
            </div>

            {/* User Support Details */}
            {props.supportData?.id !== Number(userId) &&
                <div className="px-[5%] pt-2">
                    {props.supportData && (
                        <div
                            className={`border-2 ${props.supportData?.chatDetail?.message[0]?.senderId !== Number(userId) ? props.supportData?.chatDetail?.message[0]?.readStatus ? 'border-grey-400' : 'border-lime-500' : 'border-grey-400'}  rounded-md p-[3%] cursor-pointer`}
                            onClick={() => props.handleclick(props.supportData?.chatDetail ? props.supportData?.chatDetail : {
                                name: props.supportData?.name,
                                user: { email: props.supportData?.email },
                                userId: props.supportData?.id,
                            })}
                        >
                            <div className='font-semibold'>{props.supportData.name}</div>
                            {/* <div>{data.email}</div> */}
                            <div className={`${props.supportData?.chatDetail?.message[0]?.senderId !== Number(userId) ? props.supportData?.chatDetail?.message[0]?.readStatus ? 'text-gray-700' : 'text-lime-700' : 'text-gray-700'}`}>{props.supportData?.chatDetail?.message[0]?.message ? props.supportData?.chatDetail?.message[0]?.message : props.supportData?.email}</div>
                        </div>
                    )}
                </div>


            }

            {/* Chat List */}
            <div className={`overflow-y-auto h-[65vh] px-[5%] pt-2`}>
                {props.chatArr?.length > 0 &&
                    _.orderBy(props.chatArr, 'updatedAt', 'desc')?.map((chatItem, idx) => 
                    {
                       const chatListItem =  chatItem?.participants?.find(item => {
                            return item?.id !== Number(userId)
                        })
                        return     (
                        <div
                            id={chatItem?.id}
                            key={idx}
                            className={`flex flex-col justify-between p-[3%] items-start w-full cursor-pointer border-Grey-400 border-b rounded-sm border-l-primeryColour-4 ${props.clicked === chatItem?.id && 'border-[0.5px] border-Grey-400 bg-gray-100'
                                }`}
                            onClick={() => props.handleclick(chatItem)}
                        >
                            <div className={`flex justify-between w-[100%]`}>
                                <div className={`flex items-start w-[55%]`}>
                                    <img
                                        crossOrigin="anonymous"
                                        src={
                                           (chatListItem?.business[0]?.logo?.key || chatListItem?.picture?.key) ? `${process.env.REACT_APP_DG_BASE_URL}/uploads/download/file?key=${chatListItem?.business[0]?.logo?.key || chatListItem?.picture?.key}&height=40&width=40` : defaultserviceIcon
                                        }
                                        alt="profileicon"
                                        className={`rounded-full border-2`}
                                        height={40}
                                        width={40}
                                    />
                                    <div className={`flex flex-col pl-2 w-[70%]`}>
                                        <p className={`text-sm text-deepgray-5 font-[Roboto-Medium] mb-1 pr-2 truncate`}>
                                            {chatListItem?.business?.[0]?.name || chatListItem?.name}
                                        </p>
                                        {chatItem?.message?.[0]?.message ? <p className={`text-xs text-text-60 font-[Roboto-Regular] mt-1 truncate`}>
                                            {chatItem?.message?.[0]?.message}
                                        </p> : <div className={`flex items-center gap-1`}>
                                            <img src={attachmentsection} alt={`attachment_icon`} className={`w-3 h-3 mt-[2px]`} />
                                        <p className={`text-xs text-text-60 font-[Roboto-Regular] mt-1 truncate`}>
                                            {chatItem?.message?.[0]?.file?.[0]?.mimetype?.includes('pdf') ? 'PDF' : 
                                            chatItem?.message?.[0]?.file?.[0]?.mimetype?.includes('image') ? 'Image' :
                                            chatItem?.message?.[0]?.file?.[0]?.mimetype?.includes('video') ? 'Video' : 'File'
                                            }
                                        </p>
                                        </div>
                                       }
                                    </div>
                                </div>
                                <div className={`flex items-right`}>
                                    <p className={`font-[Roboto-Regular] text-xs text-[#434343]/[0.6]`}>
                                        {moment(chatItem?.message[0]?.sentAt).startOf('second').fromNow()}
                                    </p>
                                    {chatItem?.unRead !== 0 && (
                                        <p
                                            className={`rounded-full bg-red-600 text-white w-[22px] h-[22px] flex justify-center items-center text-xs ml-2`}
                                        >
                                            {chatItem?.unRead}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    )}
            </div>
        </div>
    );
};

export default ChatList;
