import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonField from '../../../Common/FormFields/ButtonField'
import moment from 'moment'
import SubscriptionPlan from "../../Authentication/Signup/SubscriptionPlan"
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'

const Subscription = (props) => {
    // console.log(props, "props**")
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [moreAddOns, setMoreAddOns] = useState(false)
    const [upgradePlan, setUpgradePlan] = useState(false)
    const leftSubsDays = moment(props.subscriptionData?.expireIn).diff(moment(), 'days') < 8 ? moment(props.subscriptionData?.expireIn).diff(moment(), 'days') : -1;

    const handleMoreAddOns = () => {
        setMoreAddOns(!moreAddOns)
    }
    return (
        <div className={`bg-white mt-3 p-5 h-auto overflow-auto`}>

            <div className={`bg-white  border border-[#E8E8E8] flex justify-between w-full p-5`}>
                {!upgradePlan && props.subscriptionData?.expireIn &&
                    <>
                        <div>
                            <p className={`text-[#413B3B] font-[Roboto-Regular] text-base`}>{t("activeSubscriptionPlan")}: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.name}</span> </p>
                            {props?.subscriptionData?.status !== 'pending' &&
                                <p className={`text-[#413B3B] font-[Roboto-Medium] text-base`}>Expires on: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{moment(props.subscriptionData?.expireIn).format("YYYY-MM-DD")}</span> </p>
                            }
                        </div>

                        <div className={`flex`}>
                            {!moment().isAfter(props.subscriptionData?.expireIn) && leftSubsDays > -1 ?
                                <p className={`${leftSubsDays < 4 ? "bg-[#df6002] text-[#fff]" : "bg-[#e1d505e8] text-[#738616]"} tracking-wide text-sm font-[Roboto-Medium] mr-6 font-semibold px-4 text-center h-10 flex items-center justify-center rounded-full`}>{leftSubsDays}  days left</p>
                                : leftSubsDays === 0 && <p className={`bg-[#28216e] text-[#ff8769] tracking-wide text-sm font-[Roboto-Medium] mr-6 font-semibold px-4 text-center h-10 flex items-center justify-center rounded-full cursor-pointer`} onClick={() => setUpgradePlan(true)} >Upgrade plan</p>}
                            <p className={`${props.subscriptionData?.status === "active" ? "bg-[#73c741cc]" : props.subscriptionData?.status === "pendig" ? 'bg-[#faf43acc]' : "bg-[#f93838b5]"} text-white text-sm uppercase font-[Roboto-Medium] px-4 text-center h-10 flex items-center justify-center rounded-full`}>{props.subscriptionData?.status === "expire" ? "Expired" : props.subscriptionData?.status === 'pending' ? props?.subscriptionData?.payment?.status : props.subscriptionData?.status}</p>
                        </div>
                    </>}
            </div>
            {props?.subscriptionData?.payment?.status === 'success' &&
                !upgradePlan && props.subscriptionData?.serviceOffered > 0 ?
                <div className={`mt-6 border border-[#E8E8E8] w-full p-5`}>
                    <p className='text-[##1F1F1F] font-[Roboto-Bold] text-base'>Subscription Details:</p>
                    <div className='border-b border-gray-200'>
                    <Grid container gap={0}>
                        <Grid item xs={12} sm={6} md={4}>
                            <p className='text-[#4d4d4db5] text-base pl-2'>Photo Limit: <span className={`!text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.photoLimit}</span></p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <p className='text-[#4d4d4db5] text-base'>Video Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.videoLimit}</span></p>
                        </Grid>
                    </Grid>
                    </div>
                    <div className='p-2'>
                        <Grid container gap={0}>
                            <Grid item xs={12} sm={6} md={4}>
                                <p className='text-[#4d4d4db5] text-base'>Service Post Limit: <span className={`!text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.serviceOffered}</span></p>
                                <p className='text-[#4d4d4db5] text-base'>Service Image Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.serviceOffered?.photoLimit} each post.</span></p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <p className='text-[#4d4d4db5] text-base'>Requirement Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.requirements} / Month</span></p>
                                <p className='text-[#4d4d4db5] text-base'>Requirement Image Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.monthlyPostRequirements?.photoLimit} each post.</span></p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <p className='text-[#4d4d4db5] text-base'>Offer Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.offers} / Month</span></p>
                                <p className='text-[#4d4d4db5] text-base'>Offer Image Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{props.subscriptionData?.plans?.monthlyPostOffers?.photoLimit} each post.</span></p>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                : null
            }
            {(props?.subscriptionData?.status !== 'active' || (!upgradePlan && props.subscriptionData?.expireIn)) &&
                <SubscriptionPlan page={"verdorSubscription"} />
            }

            {
                props?.subscriptionData?.status === 'active' && (!upgradePlan && props.subscriptionData?.expireIn && !moment().isAfter(props.subscriptionData?.expireIn)) ?
                    <div className={`mt-6 border border-[#E8E8E8] w-full p-5`}>
                        {!moreAddOns && props.subscriptionData?.addOns?.length > 0 ?
                            <>
                                <p className='text-[##1F1F1F] font-[Roboto-Bold] text-base'>Subscription AddOns Plans:</p>
                                {props.subscriptionData?.addOns?.map(addOns => {
                                    return (
                                        <div className='p-2 flex flex-wrap justify-between gap-6 border border-[#E8E8E8] mt-2'>
                                            <p className={`text-[#413B3B] font-[Roboto-Regular] text-base`}>AddOns Plan: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{addOns.plans?.name}</span> </p>
                                            {addOns?.plans?.serviceOffered ?
                                                <p className='text-[#4d4d4db5] text-base'>Service Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{addOns?.plans?.serviceOffered?.count}</span></p>
                                                : addOns?.plans?.monthlyPostOffers ?
                                                    <p className='text-[#4d4d4db5] text-base'>Offer Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{addOns?.plans?.monthlyPostOffers?.count}</span></p>
                                                    : addOns?.plans?.monthlyPostRequirements ?
                                                        <p className='text-[#4d4d4db5] text-base'>Requirement Limit: <span className={`text-[##1F1F1F] font-[Roboto-Bold] text-base`}>{addOns?.plans?.monthlyPostRequirements?.count}</span></p>
                                                        : null
                                            }
                                            <p className='text-[#4d4d4db5] text-base'>Validity: Based on subscription plan</p>
                                        </div>
                                    )
                                })}
                                {props.subscriptionData?.addOns?.length === 30 ? null :
                                    <div className='flex flex-column items-center justify-center'>
                                        <ButtonField
                                            buttonextracls={`bg-blue-0.5 mt-5 text-blue-5 p-3 mr-4 `}
                                            buttonName="Add more addOns"
                                            buttonnamecls={`pl-2  text-sm font-[Roboto-Medium]`}
                                            onClick={handleMoreAddOns}
                                        />
                                    </div>
                                }
                            </>
                            :
                            <>
                                <SubscriptionPlan page={"verdorSubscriptionAddons"} />
                                {props.subscriptionData?.addOns?.length > 0 ?
                                    <div className='flex flex-column items-center justify-center'>
                                        <ButtonField
                                            buttonextracls={`bg-blue-0.5 mt-5 text-blue-5 p-3 mr-4 `}
                                            buttonName="Go to active addOns"
                                            buttonnamecls={`pl-2  text-sm font-[Roboto-Medium]`}
                                            onClick={handleMoreAddOns}
                                        />
                                    </div>
                                    : null}
                            </>
                        }

                    </div>
                    : null
            }
        </div >
    )
}

export default Subscription