import React, { useEffect } from 'react'
import AddAnnouncement from '../../Components/Admin/Settings/AddAnnouncement';
import { ForAdmin } from './Hooks';
import { useSelector } from 'react-redux';
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar';
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';

const AnnouncementIndex = () => {
    const { subAdminCreate,
        getAllUsers,
        showSubAdminForm,
        setShowSubAdminForm,
        toggleSubAdmin,
        subAdminUser,
        goBackToTable,
        getAllSubAdmin,
        editSubAdminForm,
        editSubAdminApiCall,
        editSubAdminFormData,
        deleteSubAdminApiCall,
        subAdminCreateMsg,
        adminSnackbar,
        messageClose,
        loading,
        showannouncementForm,
        announcementCreate,
        toggleAnnounce,
        getAllAnnouncement,
        deleteAnnouncement,
        goBackToAnnounceTable
    } = ForAdmin()

    const announcedData = useSelector((state) => state.setting.announcement)

   


    //api call here 
    useEffect(()=>{
        getAllAnnouncement()
    },[])

    const navigate = useNavigate()

    // For refresh token when actual token expires 
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
    const { isExpired } = useJwt(token);
    const checkTokenExpiration = () => {
        if (isExpired) {
            navigate('/login');
        }
    }
    return (
        <div onClick={() => checkTokenExpiration()}>
            <AddAnnouncement
                announcementCreate={announcementCreate}
                showannouncementForm={showannouncementForm}
                setShowSubAdminForm={setShowSubAdminForm}
                getAllUsers={getAllUsers}
                toggleAnnounce={toggleAnnounce}
                subAdminUser={subAdminUser}
                goBackToTable={goBackToAnnounceTable}
                announcedData={announcedData}
                editSubAdminApiCall={editSubAdminApiCall}
                editSubAdminForm={editSubAdminForm}
                editSubAdminFormData={editSubAdminFormData}
                deleteAnnouncement={deleteAnnouncement}
                subAdminCreateMsg={subAdminCreateMsg}
                loading={loading}
            />
            <SnackBarComponent
                messageOpen={adminSnackbar.open}
                messageClose={messageClose}
                notificationText={adminSnackbar.message}
                subText={adminSnackbar.subText}
                alertType={adminSnackbar.alertType}
                borderClass={adminSnackbar.borderClass}
            />

        </div>
    )
}

export default AnnouncementIndex