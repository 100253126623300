import { Support } from '@mui/icons-material'
import React from 'react'
import Chatbox from '../../VendorDetailsPage/Message/Chatbox'
import { ForVendor } from '../../../Containers/VendorDetailPage/Hooks'
import { ForNotification } from '../../../Containers/Notification/Hooks'
import { ForUploadMediaHooks } from '../../../Containers/UploadMedia/Hooks'
import { useSelector } from 'react-redux'

const SupportPage = () => {
  const { getChatsApiCAll, postChatApiCall, getChatbyChatIdApiCAll, getChatDetailsWithUserApiCAll, downloadPdfAttachments, downloadImage, chatMessageReadApi, handleSearchChat, chatSearchString } = ForVendor()
      const { showNotification, getNotification, handleshow, detailsPage, allnotification, readNotificationApi } = ForNotification()
      const { multipleMediaApiCall } = ForUploadMediaHooks()
      React.useEffect(() => {
          getNotification()
          getChatsApiCAll()
      }, [chatSearchString])
  
      const chatArr = useSelector((state) => state.vendor.chats)
      const singleChatDetail = useSelector((state) => state.vendor.chatById)
      const singleChatWith = useSelector((state) => state.vendor.chatWithByUserId)
  return (
    <>
      <Chatbox
                getChatbyChatIdApiCAll={getChatbyChatIdApiCAll}
                postChatApiCall={postChatApiCall}
                getChatDetailsWithUserApiCAll={getChatDetailsWithUserApiCAll}
                chatArr={chatArr}
                singleChatDetail={singleChatDetail}
                singleChatWith={singleChatWith}
                multipleMediaApiCall={multipleMediaApiCall}
                getChatsApiCAll={getChatsApiCAll}
                downloadPdfAttachments={downloadPdfAttachments}
                downloadImage={downloadImage}
                chatMessageReadApi={chatMessageReadApi}
                handleSearchChat={handleSearchChat}
            />
    </>
  )
}

export default SupportPage
