import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CommonCard from '../../Common/Layout/Card/CommonCard'
import img1 from '../../Images/Vendor/offerimg1.png'
import img2 from '../../Images/Vendor/offerimg2.png'
import img3 from '../../Images/Vendor/offerimg3.png'
import contactvendorimg from '../../Images/Vendor/contactvendorimage.svg'
import trackicon from '../../Images/Vendor/trackicon.svg'
import text from "../../Common/Languages/en_US.json"
import MainHeader from '../../Common/Layout/MainHheader/MainHeader'
import LandingHeader from '../../Common/Layout/LandingHeader/LandingHeader'
import Footer from '../../Common/Layout/Footer/Footer'
import { ForLanding } from '../../Containers/LandingPage/Hooks'
import { useNavigate } from 'react-router-dom'
import SnackBarComponent from '../../Common/Layout/Snackbar/SnackBar'
import { getChatWithbyUserId } from '../../Containers/VendorDetailPage/reducer/VendorReducer';
import { ForSignup } from '../../Containers/Authentication/Signup/Hook'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress } from '@mui/material'


const SeeAllPageForVendor = (props) => {
  const vendorDetails = useSelector((state) => state.vendorAccount.businessDetails)
  const { createFavVendorApiCall, getFavVendorApiCall, notification, messageClose, deleteFavVendorApiCall, chatBtnLoading } = ForLanding()
  const { increasePageNumber, hasMore, GetAllBusinessAddress, loading } = ForSignup()

  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const role = localStorage.getItem('role')
  const navigate = useNavigate()
  const singleChatWith = useSelector((state) => state.vendor.chatWithByUserId)
  const dispatch = useDispatch()


  useEffect(() => {
    GetAllBusinessAddress()
  }, [])

  useEffect(() => {
    if(token && userId) {
      getFavVendorApiCall(userId)
    }
  }, [userId])
  const favVendorData = useSelector((state) => state.landingData.favVendors)

  const itemContents = [
    {
      image: img1
    },
    {
      image: img2
    },
    {
      image: img3
    },
    {
      image: img3
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const chatCheck = (item) => {
    if (token !== null) {
      props.chatCheck(item?.userId).then((res) => {
        if (res.id) {
          navigate('/vendor/message', { state: res })
        } else {
          navigate('/vendor/message', { state: item })
        }
      }).catch((err) => { console.log(err, "chatData 3")});
    } else {
      navigate('/login')
    }
  }
  return (
    <div>
      <div className='sticky top-0 z-50'>
        <MainHeader />
        <LandingHeader
          searchBar={true}
          goTomessage={true}
          goToNotification={true}
          border={true}
          profile={true} />
      </div>

      <div className={`p-[4%] bg-white-5`}>
        <p onClick={props.backtoPage} className={`bg-primeryColour-5 font-[Roboto-Regular] rounded text-white w-20 py-1 text-center cursor-pointer`}>
          {"<-"} Back
        </p>
        <div className={`pb-[3%]`}>
          <p className={`text-center text-3xl font-[Roboto-Regular] font-normal text-deepgray-5 pb-2 lg:pb-0 cursor-pointer`}>{text.vendorsnearyou}</p>
        </div>

        {loading === true ?
          <div className='flex h-[65vh] justify-center items-center'>
            <CircularProgress size={20} color='success' />
            <p className='pl-3'>Loading...</p>
          </div>
          :
          <InfiniteScroll
            dataLength={vendorDetails?.length} //This is important field to render the next data
            next={increasePageNumber}
            hasMore={hasMore}
            style={{overflow:'hidden'}}
            loader={
              <div className='flex justify-center items-center'>
                <CircularProgress size={20} color='success' />
                <p className='pl-3'>Loading...</p>
              </div>
            }
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <p className='text-red-5'>No data left</p>
          //   </p>
          // }
          >
            <div className={`grid gap-2 md:gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-rows-1`}>
              {vendorDetails?.map((item, idx) =>{
              return(
              <div key={idx}>
                  <CommonCard
                    carousel={item?.thumbnail && item?.thumbnail?.length > 0 ? true : false}
                    noContentImg={item?.thumbnail && item?.thumbnail?.length === 0 && true}
                    carouselimg={item?.thumbnail && item?.thumbnail?.length > 0 ? item?.thumbnail : itemContents}
                    onHandelOpeModal={() => props.onHandelOpeModal(item?.user?.id)}
                    itemname={item.user?.name ? item.user?.name : "--"}
                    carouselHeight="150px"
                    noContentCls={`h-[150px]`}
                    extracls={`min-h-[30rem]`}
                    Shareicon={true}
                    height={false}
                    rating={false}
                    profile={true}
                    profileicon={item?.logo?.key !== null && item?.logo?.key}
                    itememail={true}
                    email={item?.user?.email}
                    phone={true}
                    phonetext={item.user?.phoneNo ? item.user?.phoneNo : "--"}
                    location={true}
                    locationtext={(item?.lat !== null && item?.long !== null) && item?.location ? item?.location : "--"}
                    distance={(item?.lat === null && item?.long === null) ? false : true}
                    // distancetext={item.distance ? item.distance : "--"}
                    itemtext={true}
                    text1={item?.name}
                    hoverbutton={item?.userId !== parseInt(userId) ? true : false}
                    hoverbuttonTrack={(item?.lat === null && item?.long === null) ? false : true}
                    buttonName2={"Contact Vendor"}
                    buttonimg={contactvendorimg}
                    buttonName3={"Track"}
                    buttonimg2={trackicon}
                    showLikeIcon={item?.userId !== parseInt(userId) && true}
                    onChange={() => {
                      if (token === null || role === 'admin') {
                        navigate('/login')
                      } else {
                        createFavVendorApiCall(item?.id, userId)
                      }
                    }}
                    favValue={favVendorData?.some((fav) => fav?.business?.user?.id === item?.userId) && 1}
                    // onMessageClick={() => chatCheck(item)}
                    // chatBtnLoading={chatBtnLoading}
                    item={item}
                    description={item?.description}
                  />
                </div>)
              }
              )}

            </div>
          </InfiniteScroll>
        }

      </div>
      <Footer />
      <SnackBarComponent
        messageOpen={notification.open}
        messageClose={messageClose}
        notificationText={notification.message}
        subText={notification.subText}
        alertType={notification.alertType}
        borderClass={notification.borderClass}
      />
    </div>
  )
}

export default SeeAllPageForVendor