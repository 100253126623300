import io from 'socket.io-client';
import getLocalStorageData from "./GetLocalStoregeData"

export default function socketConnection(topic, getFun, getFun2) {
    try {
        let socket = null;
        let userId = getLocalStorageData("userId");// Get user data from local storage

        if (userId) { // User id checking is available or not
            // Socket connection 
            socket = io.connect(process.env.REACT_APP_SOCKET_URL,
                {
                    query: {
                        userId: userId
                    },
                    withCredentials: true,
                    extraHeaders: {
                        "my-custom-header": "abcd"
                    }
                })

            socket.on(topic, (data) => {
                if (data?.data?.chatId) {
                    getFun(data?.data?.chatId)
                    getFun2()
                }
                return data;

            })
        } else {
            console.log("user id not found", userId)
        }

        //Socket disconnect function
        // return function cleanup() {
        //     if (socket !== null) {
        //         socket.disconnect();
        //     }
        // };
    }
    catch (error) {
        console.log(error, "<-- Socket connection error")
    }
}