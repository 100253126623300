import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


 const MuiTimePicker = (props) => {

    const {t} = useTranslation()
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}>
            <TimePicker
                label={t(props.label)}
                value={props.value}
                onChange={props.onChange}
                renderInput={(params) => <TextField {...params} error={false}  defaultValue={props.defaultValue}/>}
            />
        </LocalizationProvider>
    );
}

 MuiTimePicker.propTypes = {
    label: PropTypes.string,
    onchange: PropTypes.func,
    renderInput: PropTypes.func,
    value: PropTypes.string
 }

export default MuiTimePicker