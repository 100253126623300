import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    userDetailsInUsers: [],
    myReqUser: [],
    myRequirementByUserId: [],
    mySupportUser: [],
    bidsByUserReqId: [],
    loading: false,
    error: false,
};

export const userDetailsInSlice = createSlice({
    name: 'userDetailsInUsers',
    initialState,
    reducers: {
        getuserDetails: (state, action) => {
            state.userDetailsInUsers = action.payload;
            state.loading = false;
            state.error = false;
        },
        getMyReqUser: (state, action) => {
            state.myReqUser = action.payload;
            state.loading = false;
            state.error = false;
        },
        getReqByUserId: (state, action) => {
            state.myRequirementByUserId = action.payload;
            state.loading = false;
            state.error = false;
        },
        getBidByUserReqId: (state, action) => {
            state.bidsByReqId = action.payload;
            state.loading = false;
            state.error = false;
        },


    }
},
)

export const { getuserDetails, getMyReqUser, getReqByUserId, getBidByUserReqId } = userDetailsInSlice.actions;
export default userDetailsInSlice.reducer;